import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
  createAtrigamDatabaseUniverseEnvironment,
} from '@atrigam/atrigam-types';

import { AtrigamStorageUniverseDataFieldPath } from '../types/storage.types';

interface Options {
  environment: AtrigamEnvironment;
  fieldName: string;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  workItemId: AtrigamWorkItemId;
}

export const createStorageUniverseDataFieldPath = ({
  environment,
  fieldName,
  objectName,
  universe,
  workItemId,
}: Options): AtrigamStorageUniverseDataFieldPath => {
  const databaseUniverseEnvironment = createAtrigamDatabaseUniverseEnvironment({
    universe,
    environment,
  });

  return `${databaseUniverseEnvironment}/${objectName}/${workItemId}/${fieldName}`;
};
