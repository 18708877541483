import { AtrigamFlowExtensionTypes } from '../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfig } from '../extension-config-base.types';

import { AtrigamFlowExtensionConfigCalculatedValue2_2_0 } from './2.0/calculatedValue2.2.0.extensions.config';
import { AtrigamFlowExtensionCalculatedValue2 } from './calculatedValue2.extensions.types';

export const AtrigamFlowExtensionConfigCalculatedValue2: AtrigamFlowExtensionConfig<AtrigamFlowExtensionCalculatedValue2> =
  {
    extensionType: AtrigamFlowExtensionTypes.CalculatedValue2,
    name: 'Calculation',
    acronym: '∛x',
    materialUiIcon: 'calculate_outline',
    color: '#74bbe8',
    versions: [AtrigamFlowExtensionConfigCalculatedValue2_2_0],
  };
