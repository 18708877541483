import { AtrigamMissingIndex } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  UpdateData,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamMissingIndexListPath } from '../paths/createAtrigamMissingIndexListPath';
import { createAtrigamMissingIndexPath } from '../paths/createAtrigamMissingIndexPath';

type Options = Omit<AtrigamMissingIndex, 'timestamp'>;

export const createMissingIndicesMutation = async (missingIndex: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamMissingIndexListPath();
  const collection_ = collection(database, path);

  const query_ = query(collection_, where('createIndexUri', '==', missingIndex.createIndexUri));
  const documents = await getDocs(query_);

  if (documents.size > 0) {
    await Promise.all(
      documents.docs.map(async (snapshot) => {
        const snapshotData = snapshot.data() as AtrigamMissingIndex;

        if (!snapshotData.requestCount) {
          snapshotData.requestCount = 0;
        }

        snapshotData.requestCount++;

        const indexPath = createAtrigamMissingIndexPath({ id: snapshot.id });
        const indexReference = doc(database, indexPath);
        await updateDoc(
          indexReference,
          mapUndefinedToNullValue(snapshotData) as UpdateData<AtrigamMissingIndex>,
        );
      }),
    );

    return;
  }

  const reference = doc(collection_);

  const timestamp = getNowFirestoreTimestamp();
  const data: AtrigamMissingIndex = {
    ...missingIndex,
    lastUser: missingIndex.firstUser,
    lastUserUID: missingIndex.firstUserUID,
    whereClause: missingIndex.whereClause,
    timestamp,
  };

  await setDoc(reference, mapUndefinedToNullValue(data));
  return data;
};
