import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  UID,
} from '@atrigam/atrigam-types';
import { getDoc } from 'firebase/firestore';

import { getUserSubscriptionFlowNodeQueryReference } from '../queryReferences/getUserSubscriptionFlowNode.query.reference';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const getUserSubscriptionFlowNodeQuery = async (options: Options) => {
  const reference = getUserSubscriptionFlowNodeQueryReference(options);
  const snapshot = await getDoc(reference);
  const document = snapshot.data();

  return document;
};
