// Atrigam Domain: Subscription

import { FirestoreTimestamp } from '../utilities/date.types';
import { WithId } from '../utilities/utility.types';

import { Currency } from './common.types';
import { AtrigamObjectName } from './objectModel.types';
import { AtrigamTaskFlowName } from './taskFlow.types';
import {
  AtrigamAreaName,
  AtrigamDatabaseUniverse,
  AtrigamEnvironment,
  AtrigamRole,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from './universe.types';
import { AtrigamPhoneNumber, Email, UID } from './user.types';
import { AtrigamChatMessage, AtrigamWorkItemId } from './workItem.types';

export enum AtrigamSubscriptionState {
  Accepted = 'accepted',
  Archived = 'archived',
  Pending = 'pending',
}

/** USER SUBSCRIPTION */

export interface AtrigamUserSubscription {
  area: AtrigamAreaName;
  changedFields?: string[];
  chatMessageCount?: number;
  chatMessageSeen?: number;
  displayCurrency?: Currency;
  environment: AtrigamEnvironment;
  fieldNotUpdateNotifications?: AtrigamFieldNotUpdateNotifications;
  fieldUpdateNotifications?: AtrigamFieldUpdateNotifications;
  invitedAt: FirestoreTimestamp;
  invitedBy: UID;
  inviter?: string;
  lastChatMessageSeen?: AtrigamChatMessage;
  lastUpdatedAtByMe?: FirestoreTimestamp;
  lastViewed?: FirestoreTimestamp;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  roles: AtrigamRole[];
  subscriptionState: AtrigamSubscriptionState;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  unarchivable?: boolean;
  universe: AtrigamUniverseName;
  universeDB: AtrigamDatabaseUniverse;
  universeNodeName: AtrigamUniverseNodeName;
  ununsubscribable?: boolean;
  updatedAt: FirestoreTimestamp;
  workItemChangedSinceLastViewed?: boolean;
  workItemUpdatedAt: FirestoreTimestamp;

  roleInvites: AtrigamSubscriptionRoleInvites;
}

export type AtrigamFieldNotUpdateNotifications = Record<string, FirestoreTimestamp>;

export type AtrigamFieldUpdateNotifications = Record<string, boolean>;

export interface AtrigamFirestoreUniversesEnvironmentObjectsFlowSubscriptionsNodeRoleUserInvitee {
  firstName?: string;
  lastName?: string;
  nameAndCompany?: string;
}

export type AtrigamSubscriptionRoleInvites = Record<AtrigamRole, AtrigamSubscriptionRoleInvite>;

export interface AtrigamSubscriptionRoleInvite {
  role: AtrigamRole;
  invitedAt: FirestoreTimestamp;
  invitedBy: UID;
}

/** OBJECT SUBSCRIPTION */

export enum AtrigamObjectSubscriptionType {
  AtrigamUser = 'atrigamUser',
  PendingUser = 'pendingUser',
}

export interface AtrigamObjectSubscriptionWithPendingUser extends AtrigamUserSubscription {
  type: AtrigamObjectSubscriptionType.PendingUser;
  invitee: AtrigamPendingObjectSubscriptionInvitee;
}

export interface AtrigamPendingObjectSubscriptionInvitee {
  firstName?: string;
  lastName?: string;
  nameAndCompany: string;
  email?: Email | null;
  phoneNumber?: AtrigamPhoneNumber | null;
}

export interface AtrigamObjectSubscriptionWithAtrigamUser extends AtrigamUserSubscription {
  type: AtrigamObjectSubscriptionType.AtrigamUser;
}

export type AtrigamObjectSubscription = WithId<
  AtrigamObjectSubscriptionWithPendingUser | AtrigamObjectSubscriptionWithAtrigamUser
>;
