// Atrigam Domain: WorkItem

import { FirestoreTimestamp, ISODateTime } from '../utilities/date.types';
import { Brand } from '../utilities/utility.types';

import { URI } from './common.types';
import { UID } from './user.types';

/** @deprecated use AtrigamWorkItemId instead */
export type AtrigamNode = Brand<string, 'Node'>;
export type AtrigamWorkItemId = Brand<string, 'WorkItemId'>;

export enum AtrigamWorkItemOrigin {
  DataImport = 'dataImport',
  Client = 'client',
}

// WorkItem

export type AtrigamWorkItem = AtrigamWorkItemBase & Record<string, unknown>;

export interface AtrigamWorkItemBase {
  id: AtrigamWorkItemId;
  createdAt: ISODateTime;
  createdBy: UID;
  updatedAt: FirestoreTimestamp;
  updatedBy: UID;
  origin?: AtrigamWorkItemOrigin;
}

// used for changes in the workItem
export interface ChangeSet {
  fieldName: string;
  fieldValue: unknown;
}

// Chat Message

export interface AtrigamChatMessage {
  _id: string;
  createdAt: ISODateTime;
  text: string;
  user: AtrigamChatMessageUser;
}

export interface AtrigamChatMessageUser {
  _id: UID;
  avatar?: URI;
  name?: string;
}
