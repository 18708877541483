import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';
import { watchWorkItem } from '@atrigam/server-functions-eu-client';
import { runInAction } from 'mobx';

import { cloneObject } from '../../../../../helpers/cloneObject';
import { getChangedFieldNames } from '../../../../../helpers/workItemUtils/getChangedFieldNames';
import { getPartialWorkItemByFieldNames } from '../../../../../helpers/workItemUtils/getPartialWorkItemByFieldNames';
import { IS_TEST } from '../../../../../mode';
import { Registry } from '../../../../../services/Registry/Registry';
import { InteractionsStore } from '../Interactions.store';

interface Options {
  environment: AtrigamEnvironment;
  interactionsStore: InteractionsStore;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
}

export const watchForWorkItemUpdates = ({
  environment,
  interactionsStore,
  node,
  objectName,
  universe,
}: Options) => {
  if (interactionsStore.lastStoreWorkItem !== undefined && !IS_TEST) {
    throw new Error(
      'cannot restart storeWorkItem, this is for the transition from createWorkitem to editWorkItem',
    );
  }

  interactionsStore.lastStoreWorkItem = cloneObject(interactionsStore.workItem);

  // watch workItem
  const disposer = watchWorkItem({
    environment,
    node,
    objectName,
    universe,
    onUpdate: (workItem) => {
      // console.log('----> watchFormWorkItemUpdated', workItem);

      if (!workItem) {
        return;
      }

      /**
       * 1. create diff of server update
       *
       * check which fields on the server have changed maybe there is no
       * difference for us (just the updatedAt f.e.)
       */

      const serverDiffFieldNames = getChangedFieldNames({
        before: interactionsStore.lastStoreWorkItem,
        after: workItem,
      });

      // store the last update
      interactionsStore.lastStoreWorkItem = cloneObject(workItem);

      // no update in fieldNames, ignore
      if (serverDiffFieldNames.length === 0) {
        return;
      }

      const serverDiff = getPartialWorkItemByFieldNames({
        fieldNames: serverDiffFieldNames,
        workItem,
      });

      /**
       * 2. diff to current state
       *
       * from the server diff, which fields differ to the current state maybe
       * it is our own change and we already have it in our current state, no
       * need to update then.
       */

      const changedFieldNames = getChangedFieldNames({
        before: interactionsStore.workItem,
        after: serverDiff,
      });

      const diff = getPartialWorkItemByFieldNames({
        fieldNames: changedFieldNames,
        workItem: serverDiff,
      });

      // no update for current workitem, ignore
      if (changedFieldNames.length === 0) {
        return;
      }

      /**
       * 3. is this our own update?
       *
       * Let's see if this is our own update we managed to create the
       * current state differs, but maybe it is because we already updated
       * the current state with newer data, in that case lets look if the
       * update is from us.
       */

      const { uid } = Registry.get('userStore');
      if (workItem.updatedBy === uid) {
        //
        const found = interactionsStore.savedChanges.find((change) => {
          if (change.updatedAt?.seconds !== workItem.updatedAt?.seconds) {
            return false;
          }

          if (change.updatedAt?.nanoseconds !== workItem.updatedAt?.nanoseconds) {
            return false;
          }

          if (change.updatedBy !== workItem.updatedBy) {
            return false;
          }

          return true;
        });

        if (found) {
          runInAction(() => (found.status = 'merged'));
          return;
        }
      }

      /**
       * 4. Update
       *
       * ok ok, we need to update the clients workitem
       */

      // console.log('* UPDATE', {
      //   serverDiffFieldNames,
      //   serverDiff,
      //   changedFieldNames,
      //   diff,
      // });
      runInAction(() => {
        Object.entries(diff).forEach(([key, value]) => {
          interactionsStore.workItem[key] = value;
        });
      });
    },
  });

  interactionsStore.disposers.push(disposer);
};
