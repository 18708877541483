import { AtrigamEnvironment, isAtrigamEnvironment } from '@atrigam/atrigam-types';

import { preloadModels } from '../../helpers/routeHelper/preloadModels';
import { FlowsOverviewPageAsync } from '../../pages/FlowsOverview/FlowsOverview.page.async';
import { Registry } from '../../services/Registry/Registry';
import { RouteScope } from '../../services/Router/Router.types';
import { createRedirect } from '../../services/Router/helpers/createRedirect';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { Parameters, pattern } from './flowsOverview.path';

export const FlowsOverviewRoute = createRoute<Parameters>({
  title: () => translate('routes.flowsOverview.title'),
  components: {
    main: FlowsOverviewPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,

  getBreadcrumbs: () => {
    return [
      {
        label: translate(
          'layouts.DefaultLayout.Navigation.navigationItems.FlowOverviewNavigationItem.Overview',
        ),
      },
    ];
  },

  canEnter: async ({ parameters: { environment } }) => {
    const modelsStore = Registry.get('modelsStore');

    if (!isAtrigamEnvironment(environment)) {
      return false;
    }

    await preloadModels();

    // check if user has any flows in production
    // needed for modeler users testing flows
    if (
      environment === AtrigamEnvironment.Production &&
      !modelsStore.hasTaskFlowsInEnvironment(AtrigamEnvironment.Production) &&
      modelsStore.hasTaskFlowsInEnvironment(AtrigamEnvironment.Testing)
    ) {
      return createRedirect({
        name: 'FlowsOverviewRoute',
        params: {
          environment: AtrigamEnvironment.Testing,
        },
      });
    }

    // check if user has any flows in staging
    if (
      environment === AtrigamEnvironment.Staging &&
      !modelsStore.hasTaskFlowsInEnvironment(AtrigamEnvironment.Staging) &&
      modelsStore.hasTaskFlowsInEnvironment(AtrigamEnvironment.Testing)
    ) {
      return createRedirect({
        name: 'FlowsOverviewRoute',
        params: {
          environment: AtrigamEnvironment.Testing,
        },
      });
    }

    // if environment differs set it correctly
    if (modelsStore.environment !== environment) {
      modelsStore.updateEnvironment(environment);
    }

    return true;
  },

  onBeforeEnter: () => {
    const modelsStore = Registry.get('modelsStore');
    modelsStore.startWatchingSubscriptions();
  },

  onAfterLeave: ({ name }) => {
    // don't stop if we are still on the overview route
    if (name === 'FlowsOverviewRoute') {
      return;
    }

    const modelsStore = Registry.get('modelsStore');
    modelsStore.stopWatchingSubscriptions();
  },
});
