import { UnhandledCaseError } from '../utilities/UnhandledCaseError';

import { AtrigamDataType } from './atrigam-datatypes';
import { parseDataTypeArray } from './dataTypes/Array/parseDataType.array';
import { parseDataTypeBoolean } from './dataTypes/Boolean/parseDataType.boolean';
import { parseDataTypeDate } from './dataTypes/Date/parseDataType.date';
import { parseDataTypeDateTime } from './dataTypes/DateTime/parseDataType.datetime';
import { parseDataTypeEmail } from './dataTypes/Email/parseDataType.email';
import { parseDataTypeMoney } from './dataTypes/Money/parseDataType.money';
import { parseDataTypeNumber } from './dataTypes/Number/parseDataType.number';
import { parseDataTypePhone } from './dataTypes/Phone/parseDataType.phone';
import { parseDataTypeString } from './dataTypes/String/parseDataType.string';
import { ParseDataTypeOptions, ParseDataTypeResult } from './parseDataType.types';

// export const parseDataType: ParseDataTypeFunction<AtrigamDataType> = (options) => {
export const parseDataType = <D extends AtrigamDataType>(
  options: ParseDataTypeOptions<D>,
): ParseDataTypeResult<D> => {
  const dataType = options.dataType;

  switch (dataType) {
    case AtrigamDataType.Array: {
      return parseDataTypeArray(options) as ParseDataTypeResult<D>;
    }

    case AtrigamDataType.Boolean: {
      return parseDataTypeBoolean(options) as ParseDataTypeResult<D>;
    }

    case AtrigamDataType.Date: {
      return parseDataTypeDate(options) as ParseDataTypeResult<D>;
    }

    case AtrigamDataType.DateTime: {
      return parseDataTypeDateTime(options) as ParseDataTypeResult<D>;
    }

    case AtrigamDataType.Email: {
      return parseDataTypeEmail(options) as ParseDataTypeResult<D>;
    }

    case AtrigamDataType.Integer:
    case AtrigamDataType.Number: {
      return parseDataTypeNumber(options) as ParseDataTypeResult<D>;
    }

    case AtrigamDataType.Money: {
      return parseDataTypeMoney(options) as ParseDataTypeResult<D>;
    }

    case AtrigamDataType.Phone: {
      return parseDataTypePhone(options) as ParseDataTypeResult<D>;
    }

    case AtrigamDataType.String:
    case AtrigamDataType.Text: {
      return parseDataTypeString(options) as ParseDataTypeResult<D>;
    }

    // Unsupported types
    case AtrigamDataType.FileList:
    case AtrigamDataType.FixedDuration:
    case AtrigamDataType.Image:
    case AtrigamDataType.ImageList:
    case AtrigamDataType.Location:
    case AtrigamDataType.Object:
    case AtrigamDataType.OpenDuration:
    case AtrigamDataType.Signature: {
      throw new Error(`${dataType} is currently not supported`);
    }

    default: {
      throw new UnhandledCaseError(dataType);
    }
  }
};
