import { Notifications } from '@mui/icons-material';
import React from 'react';

import { reactive } from '../../../../../../helpers/reactive';
import { NavigationItem } from '../../../../../../primitive/NavigationItem/NavigationItem';
import { PushNotificationPlaygroundPath } from '../../../../../../routes/pushNotificationPlayground/pushNotificationPlayground.path';

const PushNotificationPlaygroundNavigationItemComponent: React.FC = () => {
  return (
    <NavigationItem
      to={PushNotificationPlaygroundPath.getLink()}
      activeRoutes={[PushNotificationPlaygroundPath]}
      icon={<Notifications color="info" />}
      label="Notifications"
      testId="navigation-item-notification-playground"
    />
  );
};

export const PushNotificationPlaygroundNavigationItem = reactive(
  PushNotificationPlaygroundNavigationItemComponent,
);
