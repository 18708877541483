import { AtrigamInteractionMoneyFX } from '../../taskFlow.interactions.types';

export const isAtrigamInteractionMoneyFX = (data: unknown): data is AtrigamInteractionMoneyFX => {
  if (typeof data !== 'object') {
    return false;
  }

  if (data === null) {
    return false;
  }

  const castedData = data as AtrigamInteractionMoneyFX;
  if (
    !castedData.currency ||
    !castedData.rawValue ||
    !castedData.systemCurrency ||
    !castedData.value ||
    !castedData.valueInSystemCurrency
  ) {
    return false;
  }

  return true;
};
