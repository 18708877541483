import {
  AtrigamEnvironment,
  AtrigamWorkItemId,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamWorkItem,
} from '@atrigam/atrigam-types';

export interface GetWorkItemQueryOptions {
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
}

export type GetWorkItemQueryFunction = (
  options: GetWorkItemQueryOptions,
) => Promise<AtrigamWorkItem | undefined>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface AggregatedValue_1_0_ProcessorOptions {
  getWorkItemQuery: GetWorkItemQueryFunction;
}

export enum AggregationMethod {
  Count = 'count',
  Evaluate = 'evaluate',
  Max = 'max',
  Mean = 'mean',
  Min = 'min',
  Sum = 'sum',
}
