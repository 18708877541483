import { Currency } from '../../../../../domain/common.types';
import { formatNumber } from '../../../Number/helpers/formatNumber';

import { NUMBER_SEPARATOR } from './getMoneyNumberRegex';

interface Options {
  value: number;
  fractions?: number;
  currency: Currency;
}

export const formatMoneyValue = ({ value, fractions, currency }: Options) => {
  // remove number separators as the mobile app also removes them
  const reNumberSeparator = new RegExp(`\\${NUMBER_SEPARATOR}`, 'g');
  const formatted = formatNumber({
    value,
    language: 'de',
    options: {
      minimumFractionDigits: fractions,
    },
  }).replace(reNumberSeparator, '');

  return `${currency} ${formatted}`;
};
