import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import {
  AtrigamFlowExtensionBase,
  AtrigamFlowExtensionConfigBase,
  AtrigamModelsModelFlowExtensionsExtensionConfigConditions,
} from '../../extension-base.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface AtrigamFlowExtensionSetValue_2_0 extends AtrigamFlowExtensionBase {
  config: AtrigamFlowExtensionSetValueConfig;
  ext: AtrigamFlowExtensionTypes.SetValue;
}

export interface AtrigamFlowExtensionSetValueConfig extends AtrigamFlowExtensionConfigBase {
  conditions?: AtrigamModelsModelFlowExtensionsExtensionConfigConditions;
  type:
    | AtrigamDataType.Money
    | AtrigamDataType.Number
    | AtrigamDataType.String
    | AtrigamDataType.Date
    | AtrigamDataType.DateTime;
  unit?: string;
  values: AtrigamFlowExtensionSetValueConfigValues;
}

export type AtrigamFlowExtensionSetValueConfigValues = Record<string, string>;

export const ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NOW = 'NOW';
export const ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NULL = 'NULL';
