import { isAtrigamLanguage } from '../../../helpers/isAtrigamLanguage';
import { Registry } from '../../Registry/Registry';
import { TranslationService } from '../Translation.service';

import { collectTranslations } from './collectTranslations';

export const STORAGE_TRANSLATION_LANGUAGE = 'User.language';

export const persistLanguage = (translationService: TranslationService) => {
  const disposer = Registry.get('storage').persist({
    name: STORAGE_TRANSLATION_LANGUAGE,
    data: () => translationService.language,
    hydrate: (language) => {
      if (!isAtrigamLanguage(language)) {
        return;
      }

      // only update uid if it has changed
      if (language !== translationService.language) {
        const translations = collectTranslations(language);
        const timezone = Registry.get('translations').timezone;

        translationService.updateTranslations({ language, timezone, translations });
      }
    },
  });

  // collect reaction so a store clean up with dispose it
  translationService.disposers.push(disposer);
};
