import { AtrigamRestrictionActiveState } from '../../domain';

interface Options {
  restrictionState: boolean;
  isRestrictionResolved: boolean;
}

export const getResolvedActiveState = ({ restrictionState, isRestrictionResolved }: Options) => {
  if (!restrictionState) {
    return isRestrictionResolved
      ? AtrigamRestrictionActiveState.Inactive
      : AtrigamRestrictionActiveState.Hidden;
  }
  return isRestrictionResolved
    ? AtrigamRestrictionActiveState.Active
    : AtrigamRestrictionActiveState.Hidden;
};
