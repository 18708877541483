import {
  AtrigamSubscriptionState,
  AtrigamWorkItem,
  createNewSystemISODateTime,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import { getNowFirestoreTimestamp } from '@atrigam/server-functions-eu-client';

import { logger } from '../../helpers/logger';
import { preloadModels } from '../../helpers/routeHelper/preloadModels';
import { WorkItemBreadcrumbsAsync } from '../../pages/WorkItem/WorkItem.breadcrumbs.async';
import { WorkItemNavigationAsync } from '../../pages/WorkItem/WorkItem.navigation.async';
import { WorkItemPageAsync } from '../../pages/WorkItem/WorkItem.page.async';
import { workItemPageStoreFactory } from '../../pages/WorkItem/stores/WorkItem.page.store.factory';
import { Registry } from '../../services/Registry/Registry';
import { RouteScope } from '../../services/Router/Router.types';
import { createRedirect } from '../../services/Router/helpers/createRedirect';
import { createRoute } from '../../services/Router/helpers/createRoute';
import { canUserCreateWorkItem } from '../../stores/ModelsStore/helpers/canUserCreateWorkItem';
import { FlowsOverviewPath } from '../flowsOverview/flowsOverview.path';
import {
  Parameters as SubscriptionsParameter,
  SubscriptionsPath,
} from '../subscriptions/subscriptions.path';

import { Parameters, pattern } from './createWorkItem.path';

export const CreateWorkItemRoute = createRoute<Parameters>({
  title: () => translate('routes.createWorkItem.title'),
  components: {
    breadcrumbs: WorkItemBreadcrumbsAsync,
    main: WorkItemPageAsync,
    navigation: WorkItemNavigationAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,

  getBreadcrumbs: ({ parameters }) => {
    const { taskFlowModel } = Registry.get('workItemPageStore');

    const { environment, universeAreaTaskFlow } = parameters as unknown as Parameters;

    return [
      {
        label: translate(
          'layouts.DefaultLayout.Navigation.navigationItems.FlowOverviewNavigationItem.Overview',
        ),
        link: FlowsOverviewPath.getLink({ params: { environment } }),
      },
      {
        label: taskFlowModel.label ?? 'Flow',
        link: SubscriptionsPath.getLink({
          params: { environment, universeAreaTaskFlow },
          query: {
            state: AtrigamSubscriptionState.Accepted,
          },
        }),
      },
      {
        label: translate('routes.createWorkItem.newWorkItem'),
      },
    ];
  },

  canEnter: async ({ parameters: { universeAreaTaskFlow, environment } }) => {
    const modelsStore = Registry.get('modelsStore');
    const { user } = Registry.get('userStore');

    // if environment differs set it correctly
    if (modelsStore.environment !== environment) {
      modelsStore.updateEnvironment(environment);
    }

    await preloadModels();

    const flowModel = modelsStore.getTaskFlowModel({ universeAreaTaskFlow });

    if (!user || !flowModel || !canUserCreateWorkItem({ environment, flowModel })) {
      logger.warn(
        `* user not allowed to create a work item for ${universeAreaTaskFlow}! Redirecting to Subscriptions page`,
      );
      return createRedirect<SubscriptionsParameter, undefined>({
        name: 'SubscriptionsRoute',
        params: {
          environment,
          universeAreaTaskFlow,
        },
      });
    }

    return true;
  },

  onBeforeEnter: ({ parameters: { universeAreaTaskFlow, environment } }) => {
    const { uid } = Registry.get('userStore');
    const modelsStore = Registry.get('modelsStore');
    const taskFlowModel = modelsStore.getTaskFlowModel({ universeAreaTaskFlow });
    const workItemModel = modelsStore.getWorkItemModel({ universeAreaTaskFlow });

    throwIfNullable('taskFlowModel cannot be undefined', taskFlowModel);
    throwIfNullable('workItemModel cannot be undefined', workItemModel);

    const createdAt = createNewSystemISODateTime();
    const updatedAt = getNowFirestoreTimestamp();
    const workItem: Partial<AtrigamWorkItem> = {
      createdAt,
      createdBy: uid,
      updatedAt,
      updatedBy: uid,
    };

    // create store
    workItemPageStoreFactory.reset();
    workItemPageStoreFactory.set({
      environment,
      taskFlowModel,
      workItemModel,
      workItem,
    });

    // clear store if it already instantiated
    // if we are already on the page using the store, it will automatically be recreated.
    if (Registry.hasInstance('workItemPageStore')) {
      Registry.clear('workItemPageStore');
    }

    Registry.get('workItemPageStore');
  },

  onAfterLeave: ({ name }) => {
    // don't remove if we are still on the subscriptions route
    if (name === 'EditWorkItemRoute' || name === 'CreateWorkItemRoute') {
      return;
    }

    workItemPageStoreFactory.reset();
    Registry.clear('workItemPageStore');
  },
});
