import { CurrencySymbol } from '../common.types';

export const reCurrencySymbol =
  /[\\$\u00A2-\u00A5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]/;

export const findCurrencySymbolInString = (value: string) => {
  const match = value.match(reCurrencySymbol);

  if (!match) {
    return;
  }
  return match[0] as CurrencySymbol;
};
