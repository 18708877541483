interface Options {
  pattern: string;
}

export const extractFieldsFromPattern = ({ pattern }: Options) => {
  if (!pattern) return;
  const matches = pattern.match(/\[(.*?)\]/g);

  if (!matches) {
    return;
  }

  return matches.map((match) => match.replace('[', '').replace(']', ''));
};
