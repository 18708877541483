import {
  AtrigamEnvironment,
  AtrigamWorkItemId,
  AtrigamSubscriptionState,
  AtrigamUniverseAreaTaskFlow,
} from '@atrigam/atrigam-types';

import { RouteParameters, RouteQuery } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export interface Parameters extends RouteParameters {
  environment: AtrigamEnvironment;
  universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow;
}

export interface Query extends RouteQuery {
  state?: AtrigamSubscriptionState;
  search?: string;
  subscriptionId?: AtrigamWorkItemId;
}

export const pattern = '/:environment/:universeAreaTaskFlow';

export const SubscriptionsPath = createRoutePath<Parameters, Query>({ pattern });
