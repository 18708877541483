/* eslint-disable unicorn/no-useless-promise-resolve-reject */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ATRIGAM_PROFILE_ENVIRONMENT,
  ATRIGAM_PROFILE_UNIVERSE_AREA_FLOW,
} from '@atrigam/atrigam-types';
import { updateUserLastSeenOnlineMutation } from '@atrigam/server-functions-eu-client';
import { when } from 'mobx';
import { Router as Router5, Middleware as Router5Middleware } from 'router5';

import { EditWorkItemRoute } from '../../../routes/editWorkItem/editWorkItem.route';
import { HomeRoute } from '../../../routes/home/home.route';
import { LoginRoute } from '../../../routes/login/login.route';
import { Registry } from '../../Registry/Registry';
import { SentryCategories } from '../../Sentry/Sentry.types';
import { sentry } from '../../Sentry/helpers/initializeSentry';
import { isEmailVerificationOverdue } from '../../../stores/UserStore/helpers/isEmailVerificationOverdue';
import {
  AnyRedirectRoute,
  AnyRoute,
  LifecycleData,
  LifecycleRedirectData,
  RouteScope,
  RouteTypes,
  RouterOptions,
} from '../Router.types';

import { createRedirect } from './createRedirect';
import { extractParametersAndQuery } from './extractParametersAndQuery';
import { isRedirectRoute } from './isRedirectRoute';
import { isRouterRedirect } from './isRouterRedirect';
import { isUserProfileRoute } from './isUserProfileRoute';

type Unsubscribe = () => void;

export const createRouterMiddleware =
  <
    Routes extends Record<string, AnyRoute | AnyRedirectRoute>,
    RouteName extends Extract<keyof Routes, string>,
  >(
    options: RouterOptions<Routes, RouteName>,
  ) =>
  (router5: Router5): Router5Middleware =>
  async (toState, fromState) => {
    const { routes, fallbackRoute, getTryNavigationWhenPausedCallback, onFinishRouting } = options;

    // fromState is empty on initial navigation
    const FromRoute = fromState ? routes[fromState.name] : undefined;
    const ToRoute = routes[toState.name];

    // can leave old route?
    const tryNavigationWhenPaused = getTryNavigationWhenPausedCallback();
    if (tryNavigationWhenPaused !== undefined) {
      let resolve: (value: boolean) => void = () => null;

      // create a new promise and save its resolver
      const isPausedNavigationResumed = new Promise<boolean>((resolvePromise) => {
        resolve = resolvePromise;
      });

      // call callback with promise resolver controls
      tryNavigationWhenPaused({
        resume: () => resolve(true),
        cancel: () => resolve(false),
      });

      // await promise execution and reject tried navigation if user cancelled
      if (!(await isPausedNavigationResumed)) {
        return Promise.reject({ reason: 'CANCELLED' });
      }
    }

    const { parameters, query } = extractParametersAndQuery({
      routeName: ToRoute.name,
      router5,
      state: toState,
    });

    const userStore = Registry.get('userStore');

    // wait if userStore is not initialized yet
    if (!userStore.isInitialized) {
      await when(() => userStore.isInitialized);
    }

    // update user seen online
    if (userStore.user) {
      void updateUserLastSeenOnlineMutation({ uid: userStore.uid });
    }

    // check if user is atrigam user and has access to this route
    if (ToRoute.scope === RouteScope.Internal && !userStore.isPlatformAdmin) {
      return Promise.reject({ redirect: { name: LoginRoute.name } });
    }

    // check if user is authenticated and has access to this route
    if (ToRoute.scope === RouteScope.LoggedIn && !userStore.isAuthenticated) {
      return Promise.reject({
        redirect: { name: LoginRoute.name, params: { origin: toState.path } },
      });
    }

    // check if user is logged in and tries to see logout stuff
    if (ToRoute.scope === RouteScope.LoggedOut && userStore.isAuthenticated) {
      return Promise.reject({ redirect: { name: HomeRoute.name } });
    }

    // 1. Handle RedirectRoutes
    if (isRedirectRoute(ToRoute)) {
      const redirectData: LifecycleRedirectData<any, any> = {
        name: ToRoute.name,
        pattern: ToRoute.path,
        parameters,
        query,
      };

      const redirect = await ToRoute.onRedirect(redirectData);
      return Promise.reject({ redirect });
    }

    // Handle normal Routes
    const data: LifecycleData<any, any> = {
      name: ToRoute.name,
      pattern: ToRoute.path,
      meta: ToRoute.meta,
      scope: ToRoute.scope,
      hasScopeData: ToRoute.hasScopeData,
      layout: ToRoute.layout,
      parameters,
      query,
    };

    // check if logged in user has terms not accepted and needs to be redirected
    if (
      ToRoute.scope === RouteScope.LoggedIn &&
      !userStore.userOrFail.termsAndConditionsAccepted &&
      !isUserProfileRoute(data)
    ) {
      return Promise.reject({
        redirect: createRedirect({
          name: EditWorkItemRoute.name,
          params: {
            environment: ATRIGAM_PROFILE_ENVIRONMENT,
            universeAreaTaskFlow: ATRIGAM_PROFILE_UNIVERSE_AREA_FLOW,
            node: userStore.userOrFail.profileNode!,
          },
        }),
      });
    }

    // check if logged in user has email not verified and is overdue
    if (
      ToRoute.scope === RouteScope.LoggedIn &&
      userStore.userOrFail.termsAndConditionsAccepted &&
      isEmailVerificationOverdue(userStore.userOrFail) &&
      data.name !== 'VerifyEmailRoute'
    ) {
      return Promise.reject({
        redirect: createRedirect({
          name: 'VerifyEmailRoute',
          params: {},
        }),
      });
    }

    // add breadcrumb for debugging purposes
    sentry.addBreadcrumb({
      category: SentryCategories.Router,
      message: `Navigating to ${ToRoute.name}`,
      data,
      level: 'info',
    });

    // 2. load new scope data so it can be used within `canEnter`
    // await loadRouteScopeData({ scope: data.scope, clientId: data.parameters.clientId });

    // 3. can enter new route?
    // we don't have new data yet, we need to check everything by hand or with
    // the data available
    if (ToRoute.canEnter) {
      const canEnter = await ToRoute.canEnter(data);

      if (canEnter === false) {
        // default redirect to fallback
        return Promise.reject({ redirect: { name: fallbackRoute } });
      } else if (isRouterRedirect(canEnter)) {
        // redirect to redirect we got from canEnter
        return Promise.reject({ redirect: canEnter });
      }
    }

    // 4. prepare stores if needed
    // prepareStores({ scope: data.scope, clientId: data.parameters.clientId });

    // 5. preload components for new route after we know we are showing it
    ToRoute.preload();

    // 6. onBeforeEnter
    if (ToRoute.onBeforeEnter) {
      await ToRoute.onBeforeEnter(data);
    }

    // 7. syncDataFromURL
    if (ToRoute.syncDataFromURL) {
      ToRoute.syncDataFromURL({ parameters, query });
    }

    // 9. onAfterLeave
    const onAfterLeave =
      FromRoute && FromRoute.type === RouteTypes.Route ? FromRoute?.onAfterLeave : undefined;
    if (onAfterLeave) {
      // we only want to call `onAfterLeave()` after the previous route has been left
      // and the next route has been navigated to.
      //
      // To achieve this we quickly subscribe to the router and listen for the next route change
      // we then execute the lifecycle event and immediately unsubscribe again
      const unsubscribe = router5.subscribe(() => {
        try {
          onAfterLeave(data);
        } finally {
          // always clean up
          unsubscribe();
        }
      }) as Unsubscribe;
    }

    // call router service finish so it can handle cases where the route change was happening without
    // navigation handling inTransition.
    if (onFinishRouting) {
      onFinishRouting({ hasFromRoute: FromRoute !== undefined });
    }

    // only if everything has passed we mark this route transition as successful
    return true;
  };
