import { AtrigamEnvironment } from '@atrigam/atrigam-types';

import { createMobxHydrator } from '../../../helpers/mobx/createMobxHydrator';
import { sentry } from '../../../services/Sentry/helpers/initializeSentry';
import { ModelsStore } from '../Models.store';

export const persistModelsStore = async (modelsStore: ModelsStore) => {
  const hydrate = createMobxHydrator({ jsonify: false }); // we want to use objects
  const { persistedVersion, environment } = await hydrate('ModelsStore', modelsStore);

  // if persistance version differs, clear store so data will be refetched automatically
  if (persistedVersion !== ModelsStore.PERSISTANCE_VERSION) {
    modelsStore.reset();
  }

  // repair broken environment
  if (
    ![
      AtrigamEnvironment.Production,
      AtrigamEnvironment.Staging,
      AtrigamEnvironment.Testing,
    ].includes(environment)
  ) {
    modelsStore.updateEnvironment(AtrigamEnvironment.Production);
  }

  sentry.setAtrigamEnvironment(modelsStore.environment);
};
