import { UnhandledCaseError } from '@atrigam/atrigam-types';

import {
  AllAtrigamFlowExtensionTypes,
  AtrigamFlowExtensionTypes,
} from '../../atrigam-extensions.types';
import {
  AggregatedValue_1_0_Processor,
  AggregatedValue_1_0_ProcessorOptions,
  AutoId_2_1_Processor,
  AutoId_2_1_ProcessorOptions,
  CalculatedValue2_2_0_Processor,
  CalculatedValue2_2_0_ProcessorOptions,
  MeasureTime_1_0_ClientProcessor,
  SetValue_2_0_Processor,
  SetValue_2_0_ProcessorOptions,
} from '../../extensionTypes';
import {
  AtrigamExtensionsRunner,
  AtrigamExtensionsRunnerOptions,
} from '../AtrigamExtensionsRunner';
import { AtrigamFlowExtensionProcessorType } from '../atrigam-processor.types';

interface Options {
  extensionProcessor: Omit<AtrigamExtensionsRunnerOptions, 'supportedProcessorType'>;
  extensions: {
    aggregatedValue: AggregatedValue_1_0_ProcessorOptions;
    autoId: AutoId_2_1_ProcessorOptions;
    calculatedValue2: CalculatedValue2_2_0_ProcessorOptions;
    setValue: SetValue_2_0_ProcessorOptions;
  };
}

export const createAtrigamExtensionsRunnerForClients = (options: Options) => {
  const extensionProcessor = new AtrigamExtensionsRunner({
    ...options.extensionProcessor,
    supportedProcessorType: AtrigamFlowExtensionProcessorType.ClientOnly,
  });

  AllAtrigamFlowExtensionTypes.forEach((type) => {
    switch (type) {
      case AtrigamFlowExtensionTypes.AggregatedValue: {
        const aggregatedValue = new AggregatedValue_1_0_Processor(
          options.extensions.aggregatedValue,
        );
        extensionProcessor.addProcessor(aggregatedValue);
        break;
      }

      case AtrigamFlowExtensionTypes.AutoId: {
        const autoId = new AutoId_2_1_Processor(options.extensions.autoId);
        extensionProcessor.addProcessor(autoId);
        break;
      }

      case AtrigamFlowExtensionTypes.AutomatedInvites: {
        // no automated invites for clients
        extensionProcessor.ignoreExtensionType(AtrigamFlowExtensionTypes.AutomatedInvites);
        break;
      }

      case AtrigamFlowExtensionTypes.CalculatedValue2: {
        const calculatedValue = new CalculatedValue2_2_0_Processor(
          options.extensions.calculatedValue2,
        );
        extensionProcessor.addProcessor(calculatedValue);
        break;
      }

      case AtrigamFlowExtensionTypes.MeasureTime: {
        const measureTime = new MeasureTime_1_0_ClientProcessor();
        extensionProcessor.addProcessor(measureTime);
        break;
      }

      case AtrigamFlowExtensionTypes.SetValue: {
        const setValue = new SetValue_2_0_Processor(options.extensions.setValue);
        extensionProcessor.addProcessor(setValue);
        break;
      }

      default: {
        throw new UnhandledCaseError(type);
      }
    }
  });

  return extensionProcessor;
};
