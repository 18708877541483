import { AtrigamConditionsSet, AtrigamWorkItem } from '../domain';
import { AtrigamUniverseKpi } from '../firestore';

import { validateConditionOrSet } from './validateConditionOrSet';

interface Options {
  conditions: AtrigamConditionsSet;
  universeKpisMap?: Record<AtrigamUniverseKpi['id'], AtrigamUniverseKpi['result']>;
  workItem?: Omit<AtrigamWorkItem, 'id'>;
  beforeWorkItem?: Omit<AtrigamWorkItem, 'id'>;
}

export const validateConditionSet = ({
  conditions,
  universeKpisMap,
  workItem,
  beforeWorkItem,
}: Options) => {
  return Object.values(conditions).some((orSet) =>
    validateConditionOrSet({ orSet, universeKpisMap, workItem, beforeWorkItem }),
  );
};
