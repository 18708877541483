import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
  ISODateTime,
} from '@atrigam/atrigam-types';
import { getDocs } from 'firebase/firestore';

import { getChatMessagesListQueryReference } from '../queryReferences/getChatMessage.list.query.reference';

export interface GetChatMessagesListQueryOptions {
  environment: AtrigamEnvironment;
  limit: number;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  startAfter?: ISODateTime;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getChatMessagesListQuery = async (options: GetChatMessagesListQueryOptions) => {
  const query_ = getChatMessagesListQueryReference(options);

  const collection_ = await getDocs(query_);
  return collection_.docs.map((snapshot) => snapshot.data());
};
