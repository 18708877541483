import { AtrigamEnvironment, AtrigamObjectName, AtrigamUniverseName } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionSettingNames } from '../../../../atrigam-extensions.types';
import {
  AtrigamFlowExtensionAutoId_2_1,
  AtrigamFlowExtensionAutoId_2_1_ExtensionSetting,
  AtrigamFlowExtensionAutoId_2_1_ExtensionSettingField,
} from '../autoId.2.1.extensions.types';
import { AutoId_2_1_ProcessorOptions } from '../autoId.2.1.processor.types';

interface Options extends AutoId_2_1_ProcessorOptions {
  environment: AtrigamEnvironment;
  extension: AtrigamFlowExtensionAutoId_2_1;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
}

export const getNextAutoID = async ({
  environment,
  extension,
  objectName,
  universe,
  getExtensionSettingsQuery,
  updateExtensionSettingsMutation,
}: Options) => {
  const data = await getExtensionSettingsQuery({
    environment,
    extensionName: AtrigamFlowExtensionSettingNames.autoID,
    objectName,
    universe,
  });

  const settings = data ?? ({} as AtrigamFlowExtensionAutoId_2_1_ExtensionSetting);
  const fieldName = extension.config.field;

  if (!settings[fieldName]) {
    settings[fieldName] = {} as AtrigamFlowExtensionAutoId_2_1_ExtensionSettingField;
  }

  const autoId = settings[fieldName].current
    ? settings[fieldName].current + 1
    : extension.config.startAt
    ? Number.parseInt(extension.config.startAt, 10)
    : 1;

  settings[fieldName].current = autoId;

  await updateExtensionSettingsMutation({
    environment,
    extensionName: AtrigamFlowExtensionSettingNames.autoID,
    data: settings,
    objectName,
    universe,
  });

  return autoId;
};
