import { Face as FaceIcon } from '@mui/icons-material';
import { Chip, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { getName } from '../../../../../../helpers/getName';
import { reactive } from '../../../../../../helpers/reactive';
import { UnimpersonatePath } from '../../../../../../routes/unimpersonate/unimpersonate.path';
import { Registry } from '../../../../../../services/Registry/Registry';

const ImpersonatingComponent: React.FC = () => {
  const theme = useTheme();
  const isSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const { isImpersonating, impersonator } = Registry.get('userStore');

  const stopImpersonating = React.useCallback(() => {
    void Registry.get('router').goTo({
      url: UnimpersonatePath.getLink(),
      replaceCurrentURL: true,
    });
  }, []);

  const name = React.useMemo(() => getName(impersonator ?? {}), [impersonator]);

  if (!isImpersonating) {
    return null;
  }

  return (
    <Chip
      icon={<FaceIcon />}
      style={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}
      label={isSmaller ? 'Impersonating' : `Impersonated by ${name ?? 'unknown'}`}
      clickable
      color="secondary"
      onDelete={stopImpersonating}
    />
  );
};

export const Impersonating = reactive(ImpersonatingComponent);
