import { UnhandledCaseError } from '@atrigam/atrigam-types';
import {
  DATABASE_URL_ATRIGAM_EU_MODELS,
  FirebaseWatcherServiceOptions,
  watcherServiceEu,
  watcherServiceModels,
} from '@atrigam/server-functions-eu-client';
import { SeverityLevel } from '@sentry/react';

import { IS_DEV } from '../../mode';
import { Registry } from '../Registry/Registry';
import { SentryCategories } from '../Sentry/Sentry.types';
import { sentry } from '../Sentry/helpers/initializeSentry';

import { initializeAtrigamEu } from './initializeAtrigamEu';

// paths that are allowed to have duplicate subscription paths
const ALLOWED_DUPLICATE_PATHS = ['/universes/atrigam-atrigam-production/objects/user/data/'];

export const initializeFirebase = () => {
  const euApp = initializeAtrigamEu();
  watcherServiceEu.initialize({
    app: euApp,
    allowedDuplicatePaths: ALLOWED_DUPLICATE_PATHS,
    isDev: IS_DEV,
    logError,
  });

  watcherServiceModels.initialize({
    app: euApp,
    databaseUrl: DATABASE_URL_ATRIGAM_EU_MODELS,
    allowedDuplicatePaths: [],
    isDev: IS_DEV,
    logEvent,
    logError,
  });
};

const logError: FirebaseWatcherServiceOptions['logError'] = ({ error, context }) => {
  // ignore errors while login/logout
  if (Registry.hasInstance('userStore') && Registry.get('userStore').isChangingLoginState) {
    return;
  }

  sentry.log({ error, context });
};

const logEvent: FirebaseWatcherServiceOptions['logEvent'] = ({ message, type, level }) => {
  sentry.addBreadcrumb({
    category: SentryCategories.Firebase,
    message: message.slice(0, 200),
    data: { type },
    level: mapLevelToSeverity(level),
  });
};

const mapLevelToSeverity = (
  level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug' | 'critical',
): SeverityLevel | undefined => {
  switch (level) {
    case 'critical': {
      return 'fatal';
    }

    case 'warning':
    case 'log':
    case 'info':
    case 'fatal':
    case 'debug':
    case 'error': {
      return level;
    }

    case undefined: {
      return;
    }

    default: {
      throw new UnhandledCaseError(level);
    }
  }
};
