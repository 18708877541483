import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUserSubscriptionsTaskFlowPath,
  UID,
  createAtrigamUniverseAreaTaskFlow,
} from '@atrigam/atrigam-types';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const createAtrigamUserSubscriptionsTaskFlowPath = ({
  area,
  environment,
  taskFlow,
  uid,
  universe,
}: Options): AtrigamUserSubscriptionsTaskFlowPath => {
  const universeAreaTaskFlow = createAtrigamUniverseAreaTaskFlow({ universe, area, taskFlow });

  return `/userSubscriptions/${uid}/${environment}/${universeAreaTaskFlow}`;
};
