import { AtrigamRole } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionAutomatedInvitesConfigInvitesEmailWithRole } from '../automatedInvites.1.0.extensions.types';

export const extractEmailWithRole = (
  emailWithRole: AtrigamFlowExtensionAutomatedInvitesConfigInvitesEmailWithRole,
) => {
  if (!emailWithRole.includes('(')) {
    return;
  }

  const email = emailWithRole.split('(')[0].trim();
  const role = emailWithRole.split('(')[1].replace(')', '').trim() as AtrigamRole;

  return { email, role };
};
