import { AvailableExtensionVersionProcessPreviewFunction } from '../../../extension-config-base.types';
import { AtrigamFlowExtensionAutoId_2_1 } from '../autoId.2.1.extensions.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const processPreviewAutoId_2_1: AvailableExtensionVersionProcessPreviewFunction<
  AtrigamFlowExtensionAutoId_2_1
> = (extension) => {
  let patterns: string[] = [];

  if (extension.config.patterns) {
    patterns = Object.values(extension.config.patterns)
      .slice(0, 3)
      .map((pattern) => {
        let autoID = String(
          extension.config.startAt ? Number.parseInt(extension.config.startAt, 10) : 1,
        );

        if (extension.config.zerofill === true && extension.config.zerofillLength) {
          autoID = autoID
            .toString()
            .padStart(Number.parseInt(extension.config.zerofillLength, 10), '0');
        }

        autoID = pattern.replace('[auto-id]', autoID);

        const dateObject = new Date();
        const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0'); // months from 1-12
        const day = dateObject.getUTCDate().toString().padStart(2, '0');
        const year = dateObject.getUTCFullYear();

        autoID = autoID
          .replace('[year]', String(year))
          .replace('[month]', month)
          .replace('[day]', day);
        autoID = autoID.replace('[', '').replace(']', '');

        return autoID;
      });
  }

  if (patterns.length === 0) {
    return '?';
  }

  return patterns.join('/');
};
