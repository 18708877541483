import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import {
  AtrigamWorkItem,
  createAtrigamUniverseAreaTaskFlow,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import {
  getNowFirestoreTimestamp,
  updateWorkItemMutation,
} from '@atrigam/server-functions-eu-client';
import { runInAction } from 'mobx';

import { Registry } from '../../../../../services/Registry/Registry';
import { EditWorkItemPath } from '../../../../../routes/editWorkItem/editWorkItem.path';
import { withReaction } from '../../../../../helpers/withReaction';
import { cloneObject } from '../../../../../helpers/cloneObject';
import { InteractionsStore, UserChange } from '../Interactions.store';

import { processExtensions } from './processExtensions';
import { createWorkItemHelper } from './createWorkItemHelper';
import { loadSubscribers } from './loadSubscribers';

let timeout: number | undefined;
let lastSavedWorkItem: Omit<AtrigamWorkItem, 'id'> | undefined;

export const watchForChangesToBeSaved = (interactionsStore: InteractionsStore) => {
  lastSavedWorkItem = cloneObject(interactionsStore.workItem);

  const disposer = withReaction({
    name: 'InteractionsStore.watchForChangesToBeSaved',
    data: () => ({
      isSaving: interactionsStore.isSaving,
      changes: interactionsStore.changes,
      changesCount: interactionsStore.changes.length,
    }),
    onChange: ({ isSaving, changes }) => {
      // already saving
      if (isSaving) {
        return;
      }

      const changesToBeSaved = changes.filter((change) => change.status === 'added');
      if (changesToBeSaved.length === 0) {
        return;
      }
      window.clearTimeout(timeout);

      timeout = window.setTimeout(() => {
        void saveUpdates(changes);
      }, 1500);
    },
  });

  interactionsStore.disposers.push(disposer);
};

const saveUpdates = async (changes: UserChange[]) => {
  const workItemPageStore = Registry.get('workItemPageStore');
  const interactionsStore = workItemPageStore.interactionsStore;

  const { uid } = Registry.get('userStore');

  interactionsStore.setIsSaving(true);
  runInAction(() => (interactionsStore.changedInteractions = new Set()));

  const updatedAt = getNowFirestoreTimestamp();

  // const interactionTypes = [];
  const mergedChanges = changes.reduce(
    (merged, change) => {
      runInAction(() => (change.status = 'saving'));

      return { ...merged, ...change.workItem };
    },
    { updatedAt, updatedBy: uid } as Partial<AtrigamWorkItem>,
  );

  const interactionType = changes[0].interactionType;

  const {
    area,
    taskFlow,
    universe,
    objectName,
    environment,
    node: workItemId,
  } = workItemPageStore.basicData;

  if (workItemPageStore.taskFlowModel.extensions) {
    const extensionChanges = await processExtensions({
      beforeWorkItem: lastSavedWorkItem,
      environment,
      extensions: workItemPageStore.taskFlowModel.extensions,
      objectName,
      universe,
      universeKpisMap: workItemPageStore.taskFlowModel.universeKpisMap,
      workItem: interactionsStore.workItem, // to update the workItem and user see the update !problem
    });

    extensionChanges.forEach((change) => {
      // update server data package
      mergedChanges[change.fieldName] = change.fieldValue;

      // update workitem as well for direct visualization
      runInAction(() => (interactionsStore.workItem[change.fieldName] = change.fieldValue));
    });
  }

  let node = workItemId;

  // make sure the mergedChanges are already in the saved changes for any listener
  runInAction(() => interactionsStore.savedChanges.push(mergedChanges));

  if (interactionsStore.isNewWorkItem) {
    const interactionRoles = interactionsStore.createRoles;
    runInAction(() => (interactionsStore.createRoles = []));

    // take first change and use it to create the workitem for now
    const { objectSubscriptionList, userSubscription, workItemData } = await createWorkItemHelper({
      area,
      environment,
      flowModel: workItemPageStore.taskFlowModel,
      flowName: taskFlow,
      interactionRoles,
      interactionType,
      objectName,
      reservedNode: interactionsStore.reservedNode,
      universe,
      uid,
      workItem: mergedChanges,
    });

    node = workItemData.id;

    // setup store to edit
    workItemPageStore.setSubscriptions({
      objectSubscriptionList,
      userSubscription,
      node,
    });
    runInAction(() => (interactionsStore.workItem.id = node));
    interactionsStore.watchWorkItemStoreItem(node);
    void loadSubscribers();
    workItemPageStore.chatStore.enableChatWatcher();

    // update navigation
    const universeAreaTaskFlow = createAtrigamUniverseAreaTaskFlow({ universe, area, taskFlow });
    Registry.get('router').updateRouteWithoutNavigation({
      url: EditWorkItemPath.getLink({
        params: {
          environment,
          node,
          universeAreaTaskFlow,
        },
        query: {},
      }),
    });
  } else {
    throwIfNullable('Node cannot be undefined', node);

    // console.log('* watchForChanges: updateWorkItem', JSON.parse(JSON.stringify(mergedChanges)));
    // update
    await updateWorkItemMutation({
      environment,
      node,
      universe,
      objectName,
      uid,
      updatedWorkItem: mergedChanges,
    });

    // track
    void track({
      event: AtrigamAnalyticEvents.INTERACTION_SaveValue,
      screen: AtrigamAnalyticScreens.Interaction,
      area,
      env: environment,
      flow: taskFlow,
      interactionType,
      object: objectName,
      universe,
      workitemId: node,
    });
  }

  runInAction(() => {
    // set all changes to saved
    for (const change of changes) {
      change.status = 'saved';
    }

    // cleanup afterwards
    interactionsStore.changes = interactionsStore.changes.filter(
      (change) => change.status !== 'saved',
    );
  });

  lastSavedWorkItem = cloneObject(interactionsStore.workItem);
  interactionsStore.setIsSaving(false);
};
