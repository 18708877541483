import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfigVersion } from '../../extension-config-base.types';
import { AtrigamFlowExtensionAutoId } from '../autoId.extensions.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AtrigamFlowExtensionConfigAutoId_2_0: AtrigamFlowExtensionConfigVersion<AtrigamFlowExtensionAutoId> =
  {
    extensionType: AtrigamFlowExtensionTypes.AutoId,
    version: '2.0',
    description:
      'Generate an ID according to predefined patterns upon creation of a new work item.',
    deprecated: true,
    example: 'INV-202005-0008',
    settings: {
      field: {
        __fieldType: 'defaultField',
        required: true,
      },
      description: {
        __fieldType: 'description',
        required: false,
      },
      zerofill: {
        __fieldType: 'additionalField',
        name: 'zerofill',
        type: AtrigamDataType.String,
        required: false,
      },
      length: {
        __fieldType: 'additionalField',
        name: 'zerofillLength',
        type: AtrigamDataType.Number,
        required: false,
      },
    },
    upgrade: [
      {
        from: '1.0',
        settingsMap: {
          zerofill: 'zerofill',
          zerofillLength: 'length',
        },
      },
    ],
  };
