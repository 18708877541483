import { AtrigamUserNotification, UID } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { createAtrigamUserNotificationPath } from '../paths/createAtrigamUserNotificationPath';

interface Options {
  uid: UID;
  id: string;
  unread: boolean;
}

export const updateUserNotificationReadMutation = async ({ uid, id, unread }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUserNotificationPath({ uid, id });
  const document = doc(database, path);

  const snapshot = await getDoc(document);
  if (!snapshot.exists()) {
    return;
  }

  const updatedAt = getNowFirestoreTimestamp();
  const update: Partial<AtrigamUserNotification> = { unread, updatedAt };
  await updateDoc(document, update);
};
