import { AnyDate } from '../date.types';

import { getDateTime } from './getDateTime';

// checks if firstDate is after secondDate
export const isAfter = (firstDate: AnyDate, secondDate: AnyDate) => {
  const firstDateTime = getDateTime(firstDate);
  const secondDateTime = getDateTime(secondDate);

  return firstDateTime.diff(secondDateTime).milliseconds > 0;
};
