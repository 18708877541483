import { withReaction } from '../../../helpers/withReaction';
import { Registry } from '../../Registry/Registry';
import { ExchangeRateService } from '../ExchangeRate.service';

export const watchUserStore = (service: ExchangeRateService) => {
  const userStore = Registry.get('userStore');

  const disposer = withReaction({
    name: 'ExchangeRateService.watchUserStore',
    data: () => ({
      user: userStore.user,
      userInitialized: userStore.isInitialized,
    }),
    onChange: ({ user, userInitialized }) => {
      if (!userInitialized) {
        return;
      }

      if (!user?.uid) {
        void service.disableService();
        return;
      }

      service.enableService();
    },
  });

  service.disposers.push(disposer);
};
