import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { getDocs } from 'firebase/firestore';

import {
  GetAllWorkItemsQueryFilter,
  getAllWorkItemListQueryReference,
} from '../queryReferences/getAllWorkItem.list.query.reference';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  filters?: GetAllWorkItemsQueryFilter[];
}

export const getAllWorkItemListQuery = async (options: Options) => {
  const query_ = getAllWorkItemListQueryReference(options);

  const collection_ = await getDocs(query_);
  return collection_.docs.map((document) => document.data());
};
