import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseId,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItem,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { DocumentData, DocumentReference, doc, getFirestore } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamWorkItemPath } from '../paths/createAtrigamWorkItemPath';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName | AtrigamUniverseId;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  node: AtrigamWorkItemId;
}

export const getWorkItemQueryReference = ({ universe, environment, objectName, node }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));
  const path = createAtrigamWorkItemPath({ environment, node, objectName, universe });
  return doc(database, path) as DocumentReference<AtrigamWorkItem, DocumentData>;
};
