import {
  AnyDate,
  getDateTime,
  isDate,
  isDateTime,
  isFirestoreTimestamp,
} from '@atrigam/atrigam-types';

import { getFirestoreTimestampFromDate } from './getFirestoreTimestampFromDate';
import { getFirestoreTimestampFromDateTime } from './getFirestoreTimestampFromDateTime';

export const getFirestoreTimestamp = (input: AnyDate) => {
  if (isDate(input)) {
    return getFirestoreTimestampFromDate(input);
  }

  if (isDateTime(input)) {
    return getFirestoreTimestampFromDateTime(input);
  }

  if (isFirestoreTimestamp(input)) {
    return input;
  }

  return getFirestoreTimestampFromDateTime(getDateTime(input));
};
