import { AtrigamDataType } from '../../atrigam-datatypes';
import { DATATYPE_FORMAT_NOT_AVAILABLE } from '../../dataTypes.constants';
import { FormatDataTypeFunction } from '../../formatDataType.types';

import { formatNumber } from './helpers/formatNumber';
import { formatNumberAbbreviated } from './helpers/formatNumberAbbreviated';

// default to german is by design
export const formatDataTypeNumber: FormatDataTypeFunction<
  AtrigamDataType.Integer | AtrigamDataType.Number
> = ({ value: value_, options: { numberFormat = 'abbreviated', numberLanguage = 'de' } }) => {
  let value: number | undefined;
  const language = numberLanguage;
  if (typeof value_ === 'number') {
    value = value_;
  }
  if (typeof value_ === 'string') {
    value = Number.parseFloat(value_);
  }

  if (!value && value !== 0) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  if (value > -1000 && value < 1000) {
    if (Math.round(value) !== value) {
      return formatNumber({ value: Math.round((value + Number.EPSILON) * 100) / 100, language });
    }

    return formatNumber({ value, language });
  }

  if (numberFormat === 'rawNumbers') {
    return String(value);
  }

  const abbreviatedNumber = formatNumberAbbreviated({ value, language });
  const rawNumber = formatNumber({ value, language });

  if (numberFormat === 'abbreviated') {
    return `~ ${abbreviatedNumber}`;
  }

  return `~ ${abbreviatedNumber} (${rawNumber})`;
};
