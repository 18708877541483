import { CountryCode, isValidPhoneNumber as libraryIsValidPhoneNumber } from 'libphonenumber-js';

import { AtrigamPhoneNumber } from '../domain/user.types';

export const isValidPhoneNumber = (
  value: AtrigamPhoneNumber | string,
  countryCode?: CountryCode,
): value is AtrigamPhoneNumber => {
  try {
    return libraryIsValidPhoneNumber(value, countryCode);
  } catch {
    return false;
  }
};
