import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import {
  AtrigamAppStatus,
  AtrigamApplicationVersion,
  AtrigamFirestorePlatformStatusDocument,
  throwIfNullable,
  type FirestoreTimestamp,
} from '@atrigam/atrigam-types';
import { watchApplicationVersion, watchPlatformStatus } from '@atrigam/server-functions-eu-client';
import { action, computed, makeObservable, observable } from 'mobx';
import { registerSW } from 'virtual:pwa-register';

import { sentry } from '../../services/Sentry/helpers/initializeSentry';
import { IS_TEST } from '../../mode';
import { logger } from '../../helpers/logger';
import { getGlobalState } from '../../helpers/getGlobalState';

import { isSemverGreater } from './helpers/isSemverGreater';

export class AppStore {
  @observable
  appStatus?: AtrigamAppStatus;

  @observable
  appVersion: string;

  @observable
  estimatedDowntimeEnd?: FirestoreTimestamp;

  @observable
  status?: string;

  @observable
  isAnUpdateAvailable?: boolean = false;

  @observable
  termsAndConditions = '';

  @observable
  newVersionAvailable?: string;

  @observable
  private _updateServiceWorker?: (reloadPage?: boolean | undefined) => Promise<void>;

  constructor() {
    makeObservable(this);

    const { appVersion } = getGlobalState();
    this.appVersion = appVersion;

    watchPlatformStatus({ onUpdate: this.setPlatformStatus });

    // still watch for updates via firestore to make sure all browsers update
    watchApplicationVersion({ onUpdate: this.setApplicationVersion, app: 'webclient' });

    if (!IS_TEST) {
      this._updateServiceWorker = registerSW({
        onNeedRefresh: () => {
          this.setUpdateAvailable('serviceWorker');
        },
        onRegisteredSW: (_, registration) => {
          registration &&
            setInterval(() => {
              // console.log('Checking for sw update');
              void registration.update();
            }, 20_000 /* checks every 20s if there is an update available */);
        },
        onRegisterError(error) {
          sentry.log({
            error: error as Error,
            context: {
              appVersion,
            },
          });
        },
      });
    }
  }

  @computed
  get isLocked() {
    return this.appStatus && this.appStatus === AtrigamAppStatus.Locked;
  }

  @action
  setPlatformStatus = (platform?: AtrigamFirestorePlatformStatusDocument) => {
    throwIfNullable('platform status is not available', platform);

    this.appStatus = platform.appStatus;
    this.estimatedDowntimeEnd = platform.estimatedDowntimeEnd;
    this.status = platform.status;
  };

  @action
  setApplicationVersion = (appVersion?: AtrigamApplicationVersion) => {
    if (appVersion && isSemverGreater(this.appVersion, appVersion.version)) {
      this.newVersionAvailable = appVersion.version;
      logger.log('Firestore has a newer version, starting timeout', {
        current: this.appVersion,
        new: appVersion.version,
      });

      // wait for 60 seconds to enable the flag if it has not updated automatically
      window.setTimeout(() => {
        if (!this.isAnUpdateAvailable) {
          logger.log('* force update available via firestore');

          void track({
            screen: AtrigamAnalyticScreens.App,
            event: AtrigamAnalyticEvents.APP_NewVersionOnFirestore,
            newVersion: appVersion.version,
          });

          this.setUpdateAvailable('firestore');
          return;
        }

        logger.log('* no forcing necessary');
      }, 60_000);
    }
  };

  @action
  setUpdateAvailable = (origin: 'serviceWorker' | 'firestore' | 'manual' = 'manual') => {
    this.isAnUpdateAvailable = true;

    void track({
      screen: AtrigamAnalyticScreens.App,
      event: AtrigamAnalyticEvents.APP_NewRelease,
      origin,
    });
  };

  @action
  setTermsAndConditions = (termsAndConditions: string) => {
    this.termsAndConditions = termsAndConditions;
  };

  @action
  setLocked = (locked: boolean) => {
    this.appStatus = locked ? AtrigamAppStatus.Locked : AtrigamAppStatus.Open;
  };

  @action
  updateApplication = async (
    origin: 'userClicked' | 'routerNavigation' | 'notificationTimeout',
  ) => {
    if (this._updateServiceWorker) {
      void track({
        screen: AtrigamAnalyticScreens.App,
        event: AtrigamAnalyticEvents.APP_UpdatingApp,
        origin,
      });

      await this._updateServiceWorker(true);

      // workaround for bug
      // https://github.com/vite-pwa/vite-plugin-pwa/issues/601
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    this.isAnUpdateAvailable = false;
  };
}
