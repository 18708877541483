import { CardContent } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../themes/Themes.types';

export const CardContentWrapper = styled(CardContent)<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => css`
    padding: ${theme.spacing(2)};
  `,
);
