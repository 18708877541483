import {
  AtrigamEnvironment,
  AtrigamWorkItemId,
  AtrigamObjectSubscription,
  AtrigamUserSubscription,
  AtrigamWorkItem,
  throwIfNullable,
} from '@atrigam/atrigam-types';

import { TaskFlowModelEntity } from '../../../stores/ModelsStore/entities/TaskFlowModel.entity';
import { WorkItemModelEntity } from '../../../stores/ModelsStore/entities/WorkItemModel.entity';

import { WorkItemPageStore } from './WorkItem.page.store';

interface SetOptionsCreateWorkItem {
  environment: AtrigamEnvironment;
  taskFlowModel: TaskFlowModelEntity;
  workItemModel: WorkItemModelEntity;
  workItem: Partial<AtrigamWorkItem>;
}

interface SetOptionsEditWorkItem extends SetOptionsCreateWorkItem {
  node: AtrigamWorkItemId;
  objectSubscriptionList: AtrigamObjectSubscription[];
  userSubscription: AtrigamUserSubscription;
}

type SetOptions = SetOptionsEditWorkItem | SetOptionsCreateWorkItem;

const createWorkItemPageStoreFactory = () => {
  let environment: AtrigamEnvironment | undefined;
  let taskFlowModel: TaskFlowModelEntity | undefined;
  let workItemModel: WorkItemModelEntity | undefined;
  let node: AtrigamWorkItemId | undefined;
  let objectSubscriptionList: AtrigamObjectSubscription[] | undefined;
  let userSubscription: AtrigamUserSubscription | undefined;
  let workItem: Partial<AtrigamWorkItem> | undefined;

  const reset = () => {
    environment = undefined;
    taskFlowModel = undefined;
    workItemModel = undefined;
    node = undefined;
    objectSubscriptionList = undefined;
    userSubscription = undefined;
    workItem = undefined;
  };

  return {
    set: (options: SetOptions) => {
      reset();

      environment = options.environment;
      taskFlowModel = options.taskFlowModel;
      workItemModel = options.workItemModel;
      workItem = options.workItem;

      if (isSetOptionsEditWorkItem(options)) {
        node = options.node;
        objectSubscriptionList = options.objectSubscriptionList;
        userSubscription = options.userSubscription;
      }
    },

    create: () => {
      throwIfNullable('environment cannot be undefined', environment);
      throwIfNullable('taskFlow cannot be undefined', taskFlowModel);
      throwIfNullable('workItemModel cannot be undefined', workItemModel);
      throwIfNullable('workItem cannot be undefined', workItem);

      return new WorkItemPageStore({
        environment,
        taskFlow: taskFlowModel,
        workItemModel,
        node,
        objectSubscriptionList,
        userSubscription,
        workItem: workItem as AtrigamWorkItem,
      });
    },

    reset,
  };
};

export const workItemPageStoreFactory = createWorkItemPageStoreFactory();

const isSetOptionsEditWorkItem = (options: SetOptions): options is SetOptionsEditWorkItem =>
  (options as SetOptionsEditWorkItem).node !== undefined;
