import styled from 'styled-components';

import { ThemedStyledComponent } from '../../../../../themes/Themes.types';

export const LockScreenWrapper = styled.div<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => `
  max-width: 100%;

  ${theme.breakpoints.up('sm')} {
    width: 400px;
  }
`,
);
