import {
  ChangeSet,
  parseDataType,
  ISODateTime,
  AtrigamDataType,
  getDateISO,
  getNowDateTime,
  getISO,
  ParseDataTypeResult,
  createMoneyFxFieldName,
  validateConditionSet,
} from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import {
  AtrigamFlowExtensionProcessor,
  AtrigamFlowExtensionProcessorType,
  ExtensionProcessFunction,
} from '../../../extensionsRunner/atrigam-processor.types';

import {
  AtrigamFlowExtensionSetValue_2_0,
  ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NOW,
  ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NULL,
} from './set-value.2.0.extensions.types';
import { SetValue_2_0_ProcessorOptions } from './setValue.2.0.processor.types';
// eslint-disable-next-line @typescript-eslint/naming-convention
export class SetValue_2_0_Processor
  implements AtrigamFlowExtensionProcessor<AtrigamFlowExtensionSetValue_2_0>
{
  type = AtrigamFlowExtensionTypes.SetValue;
  version = '2.0';
  processorType = AtrigamFlowExtensionProcessorType.All;

  private _getFxRates: SetValue_2_0_ProcessorOptions['getFxRates'];

  constructor(options: SetValue_2_0_ProcessorOptions) {
    this._getFxRates = options.getFxRates;
  }

  supportsVersion: (version: string) => boolean = (version) => version === this.version;

  process: ExtensionProcessFunction<AtrigamFlowExtensionSetValue_2_0> = ({
    beforeWorkItem,
    extension,
    universeKpisMap,
    workItem,
  }) => {
    //
    if (!extension.config?.values) {
      return [];
    }

    const values = extension.config.values;

    const changes: ChangeSet[] = [];

    const dataType = extension.config.type;
    let resolvedValue: string | undefined;

    Object.entries(values).forEach(([key, value]) => {
      let resolved = true;

      if (extension.config.conditions?.[key]) {
        resolved = validateConditionSet({
          conditions: extension.config.conditions[key].conditions,
          universeKpisMap,
          workItem,
          beforeWorkItem,
        });
      }

      // not resolved
      if (!resolved) {
        return;
      }

      resolvedValue = value;
    });

    if (!resolvedValue) {
      return changes;
    }

    const parsed = parseDataType({
      value: resolvedValue,
      dataType,
      fxRates: this._getFxRates(),
    });

    let parsedValue: string | number | ISODateTime | undefined | null = parsed?.value;

    // handle NOW()
    if (
      dataType === AtrigamDataType.Date &&
      resolvedValue === ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NOW
    ) {
      parsedValue = getDateISO(getNowDateTime());
    }

    if (
      dataType === AtrigamDataType.DateTime &&
      resolvedValue === ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NOW
    ) {
      parsedValue = getISO(getNowDateTime());
    }

    if (
      [AtrigamDataType.Date, AtrigamDataType.DateTime] &&
      resolvedValue === ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NULL
    ) {
      parsedValue = null;
    }

    // could not parse or parseValue is the same as the field
    if (parsedValue === undefined || parsedValue === workItem[extension.config.field]) {
      return changes;
    }

    const changeSet = {
      fieldName: extension.config.field,
      fieldValue: parsedValue,
    };

    if (workItem[changeSet.fieldName] !== changeSet.fieldValue) {
      // update workItem directly so other extensions can handle it
      workItem[changeSet.fieldName] = changeSet.fieldValue;
      // add change set for update mutation
      changes.push(changeSet);
    }

    // handle moneyField
    if (dataType === AtrigamDataType.Money) {
      const parseResult = parsed as ParseDataTypeResult<AtrigamDataType.Money>;

      if (parseResult?.fxValue) {
        const moneyChange = {
          fieldName: createMoneyFxFieldName(extension.config.field),
          fieldValue: parseResult.fxValue,
        };

        // todo check if value has changed
        changes.push(moneyChange);
        workItem[moneyChange.fieldName] = moneyChange.fieldValue;
      }
    }

    return changes;
  };
}
