import { getApp } from 'firebase/app';
import { FunctionsError, getFunctions, httpsCallable } from 'firebase/functions';

import { ServerFunctionOnCallOptions } from './clientSDK.types';

interface Options {
  appName: string;
  functionName: string;
  region?: string;
}

export const createOnCallFunctionClientSDK = <
  P = Record<string, unknown>,
  R = unknown,
  E = unknown,
>({
  appName,
  functionName,
  region = 'europe-west3',
}: Options) => {
  return async ({ onException, ...data }: ServerFunctionOnCallOptions<P, E>) => {
    const app = getApp(appName);
    const functions = getFunctions(app, region);
    const callable = httpsCallable<Omit<ServerFunctionOnCallOptions<P, E>, 'onException'>, R>(
      functions,
      functionName,
    );

    try {
      const result = await callable(data);
      return result.data;
    } catch (error) {
      if (onException) {
        if (typeof error === 'object' && (error as FunctionsError).details) {
          onException((error as FunctionsError).details as E);
          return;
        }

        onException(error);
        return;
      }

      // rethrow if not handled by user
      throw error;
    }
  };
};
