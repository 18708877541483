// Atrigam Domain: Conditions

import { AtrigamObjectName } from './objectModel.types';

export enum AtrigamValidationTypes {
  ObjectDataExists = 'objectDataExists',
  ObjectDataDoesNotExist = 'objectDataDoesNotExist',
  ValueHasChanged = 'valueHasChanged',
  ValueIsExactly = 'valueIsExactly',
  ValueIsLowerThan = 'valueIsLowerThan',
  ValueIsGreaterThan = 'valueIsGreaterThan',
  ValueIsLowerThanOrEqual = 'valueIsLowerThanOrEqual',
  ValueIsGreaterThanOrEqual = 'valueIsGreaterThanOrEqual',
  ValueIsNot = 'valueIsNot',
  ValueIsOneOf = 'valueIsOneOf',
  ValueIsNotOneOf = 'valueIsNotOneOf',
  IsNotTrue = 'isNotTrue',
  IsTrue = 'isTrue',
}

export type AtrigamConditionsSet = Record<string, AtrigamConditionsSetOrSet>;

export type AtrigamConditionsSetOrSet = Record<
  string,
  AtrigamConditionsSetOrAndSet | AtrigamConditionsSetOrEmptySet
>;

export interface AtrigamConditionsSetOrEmptySet {
  empty: true;
}

export type AtrigamConditionsSetOrAndSet = Record<string, AtrigamConditionsSetCondition>;

export interface AtrigamConditionsSetCondition {
  data: string;
  object: AtrigamObjectName;
  validation: AtrigamValidationTypes;
  value?: string | number;
}
