import { throwIfNullable } from '@atrigam/atrigam-types';

import {
  Route,
  RouteOptions,
  RouteParameters,
  RouteQuery,
  RouteScopeData,
  RouteTypes,
} from '../Router.types';

import { getRouteComponents } from './getRouteComponents';
import { getRouteLayout } from './getRouteLayout';
import { getRouteScopeData } from './getRouteScopeData';
import { preloadComponentsForRoute } from './preloadComponentsForRoute';

interface CreateRoute {
  // no params, no query
  (options: RouteOptions<undefined, undefined>): Route<undefined, undefined>;

  // only params
  <Parameters extends RouteParameters>(
    options: RouteOptions<Parameters, undefined>,
  ): Route<Parameters, undefined>;

  // only query
  <Query extends RouteQuery>(options: RouteOptions<undefined, Query>): Route<undefined, Query>;

  // params + query
  <Parameters extends RouteParameters, Query extends RouteQuery>(
    options: RouteOptions<Parameters, Query>,
  ): Route<Parameters, Query>;
}

export const createRoute: CreateRoute = <
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
>(
  options: RouteOptions<Parameters, Query>,
): Route<Parameters, Query> => {
  let routeName: string | undefined;

  const {
    pattern,
    scope,
    title,
    meta,
    canEnter,
    onBeforeEnter,
    onAfterLeave,
    syncDataFromURL,
    preloadableComponents,
    getBreadcrumbs,
  } = options;

  const layout = getRouteLayout({ scope });
  const availableScopeData = getRouteScopeData({ scope });
  const components = getRouteComponents({ layout, routeComponents: options.components });

  return {
    type: RouteTypes.Route,
    path: pattern,
    scope,
    title,
    meta,
    canEnter,
    onBeforeEnter,
    onAfterLeave,
    syncDataFromURL,
    getBreadcrumbs,
    layout,
    components,
    hasScopeData: (scopeData: RouteScopeData) => {
      return availableScopeData.includes(scopeData);
    },

    preload: () => {
      preloadComponentsForRoute({ components, preloadableComponents });
    },

    get name() {
      throwIfNullable('Router.createRoute::Route.name', routeName);
      return routeName;
    },

    // this will be called to set the route name
    // @see createRouter.ts
    setName: (name: string) => {
      routeName = name;
    },
  };
};
