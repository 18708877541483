import { AtrigamDataType } from '../../atrigam-datatypes';
import { DATATYPE_FORMAT_NOT_AVAILABLE } from '../../dataTypes.constants';
import { FormatDataTypeFunction } from '../../formatDataType.types';

export const formatDataTypeString: FormatDataTypeFunction<
  AtrigamDataType.String | AtrigamDataType.Text
> = ({ value }) => {
  // is a string, return it
  if (typeof value === 'string') {
    if (value.length === 0) {
      return DATATYPE_FORMAT_NOT_AVAILABLE;
    }

    return value;
  }

  // handle undefined and null
  if (value === undefined || value === null) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  if (typeof value === 'object' || typeof value === 'function') {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  return String(value);
};
