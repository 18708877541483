import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseId,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';
import { getDoc } from 'firebase/firestore';

import { getWorkItemQueryReference } from '../queryReferences/getWorkItem.query.reference';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName | AtrigamUniverseId;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  node: AtrigamWorkItemId;
}

export const getWorkItemQuery = async (options: Options) => {
  const reference = getWorkItemQueryReference(options);
  const snapshot = await getDoc(reference);
  return snapshot.data();
};
