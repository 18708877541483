import { AtrigamFlowExtensionAggregatedValue } from './extensionTypes/AggregatedValue/aggregatedValue.extensions.types';
import {
  AtrigamFlowExtensionAutoId,
  AtrigamFlowExtensionAutoId_ExtensionSetting,
} from './extensionTypes/AutoId/autoId.extensions.types';
import { AtrigamFlowExtensionAutomatedInvites } from './extensionTypes/AutomatedInvites/automatedInvites.extensions.types';
import { AtrigamFlowExtensionCalculatedValue2 } from './extensionTypes/CalculatedValue2/calculatedValue2.extensions.types';
import { AtrigamFlowExtensionMeasureTime } from './extensionTypes/MeasureTime/measureTime.extensions.types';
import { AtrigamFlowExtensionSetValue } from './extensionTypes/SetValue/set-value.extensions.types';

export enum AtrigamFlowExtensionTypes {
  AggregatedValue = 'aggregated-value',
  AutoId = 'autoId',
  AutomatedInvites = 'automated-invites',
  CalculatedValue2 = 'calculated-value-2',
  MeasureTime = 'measure-time',
  SetValue = 'set-value',
}

export const AllAtrigamFlowExtensionTypes = [
  AtrigamFlowExtensionTypes.AggregatedValue,
  AtrigamFlowExtensionTypes.AutoId,
  AtrigamFlowExtensionTypes.AutomatedInvites,
  AtrigamFlowExtensionTypes.CalculatedValue2,
  AtrigamFlowExtensionTypes.MeasureTime,
  AtrigamFlowExtensionTypes.SetValue,
];

export type AtrigamFlowExtension =
  | AtrigamFlowExtensionAggregatedValue
  | AtrigamFlowExtensionAutoId
  | AtrigamFlowExtensionAutomatedInvites
  | AtrigamFlowExtensionCalculatedValue2
  | AtrigamFlowExtensionMeasureTime
  | AtrigamFlowExtensionSetValue;

// ExtensionSettings
export enum AtrigamFlowExtensionSettingNames {
  autoID = 'autoID',
}

export interface AtrigamFlowExtensionSettings {
  [AtrigamFlowExtensionSettingNames.autoID]: AtrigamFlowExtensionAutoId_ExtensionSetting;
}

export type AtrigamFlowExtensionSettingsData = AtrigamFlowExtensionAutoId_ExtensionSetting;
