import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUserSubscriptionListPath,
  UID,
  createAtrigamUniverseAreaTaskFlow,
} from '@atrigam/atrigam-types';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const createAtrigamUserSubscriptionListPath = ({
  area,
  environment,
  taskFlow,
  uid,
  universe,
}: Options): AtrigamUserSubscriptionListPath => {
  const universeAreaTaskFlow = createAtrigamUniverseAreaTaskFlow({ universe, area, taskFlow });
  return `/userSubscriptions/${uid}/${environment}/${universeAreaTaskFlow}/data`;
};
