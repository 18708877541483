import { AtrigamFlowExtensionTypes } from '../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfig } from '../extension-config-base.types';

import { AtrigamFlowExtensionConfigMeasureTime_1_0 } from './1.0/MeasureTime.1.0.extensions.config';
import { AtrigamFlowExtensionMeasureTime } from './measureTime.extensions.types';

export const AtrigamFlowExtensionConfigMeasureTime: AtrigamFlowExtensionConfig<AtrigamFlowExtensionMeasureTime> =
  {
    extensionType: AtrigamFlowExtensionTypes.MeasureTime,
    name: 'Measure Time',
    acronym: 'MT',
    materialUiIcon: 'timer',
    atrigamTeamMembersOnly: true,
    color: '#F58023',
    versions: [AtrigamFlowExtensionConfigMeasureTime_1_0],
  };
