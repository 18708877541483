import {
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamUniverseRegistrationClientRoleListPath,
  createAtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const createAtrigamUniverseRegistrationClientRoleListPath = ({
  universe,
}: Options): AtrigamUniverseRegistrationClientRoleListPath => {
  const universeNodeName = createAtrigamUniverseNodeName(universe);

  return `/universeRegistration/${universeNodeName}/clientRoles`;
};
