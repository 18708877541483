import { AtrigamFlowExtensionTypes } from '../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfig } from '../extension-config-base.types';

import { AtrigamFlowExtensionConfigAutoId_1_0 } from './1.0/autoId.1.0.extensions.config';
import { AtrigamFlowExtensionConfigAutoId_2_0 } from './2.0/autoId.2.0.extensions.config';
import { AtrigamFlowExtensionConfigAutoId_2_1 } from './2.1/autoId.2.1.extensions.config';
import { AtrigamFlowExtensionAutoId } from './autoId.extensions.types';

export const AtrigamFlowExtensionConfigAutoId: AtrigamFlowExtensionConfig<AtrigamFlowExtensionAutoId> =
  {
    extensionType: AtrigamFlowExtensionTypes.AutoId,
    name: 'Auto-ID',
    acronym: 'ID',
    color: '#ffd8be',
    versions: [
      AtrigamFlowExtensionConfigAutoId_2_1,
      AtrigamFlowExtensionConfigAutoId_2_0,
      AtrigamFlowExtensionConfigAutoId_1_0,
    ],
  };
