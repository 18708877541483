import {
  AtrigamFlowExtensionSettingNames,
  AtrigamFlowExtensionSettingsData,
} from '@atrigam/atrigam-extensions';
import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamFlowExtensionSettingsPath } from '../paths/createAtrigamFlowExtensionSettingsPath';

interface Options<T extends AtrigamFlowExtensionSettingsData> {
  data: T;
  environment: AtrigamEnvironment;
  extensionName: AtrigamFlowExtensionSettingNames;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const updateExtensionSettingsMutation = async <T extends AtrigamFlowExtensionSettingsData>({
  data,
  environment,
  extensionName,
  objectName,
  universe,
}: Options<T>) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamFlowExtensionSettingsPath({
    environment,
    extensionName,
    objectName,
    universe,
  });

  const reference = doc(database, path);
  await setDoc(reference, data, { merge: true });
};
