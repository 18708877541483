import { UnhandledCaseError } from '../../../../utilities/UnhandledCaseError';

interface Options {
  value: number;
  language?: 'de' | 'en';
  options?: Intl.NumberFormatOptions;
}

export const formatNumber = ({ value, language = 'en', options }: Options) => {
  switch (language) {
    case 'de': {
      return new Intl.NumberFormat('de-DE', options).format(value);
    }

    case 'en': {
      return new Intl.NumberFormat('en-US', options).format(value);
    }

    default: {
      throw new UnhandledCaseError(language);
    }
  }
};
