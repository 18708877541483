import { AtrigamDataType } from '../atrigam-datatypes';

export const determineAtrigamDataType = (value: unknown) => {
  if (value === undefined || value === null) {
    return;
  }

  if (typeof value === 'boolean') {
    return AtrigamDataType.Boolean;
  }

  if (typeof value === 'number') {
    return AtrigamDataType.Number;
  }

  if (typeof value === 'string') {
    return AtrigamDataType.Text;
  }

  if (typeof value === 'object') {
    return AtrigamDataType.Object;
  }

  return;
};
