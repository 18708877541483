export const getObjectAsStringOrValue = (value: unknown) => {
  if (
    typeof value === 'object' &&
    value !== null &&
    Object.prototype.hasOwnProperty.call(value, 'toString')
  ) {
    // we check first if there is a toString method handling the object so it is fine
    // to call it
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return value.toString();
  }

  return value;
};
