import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItem,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamWorkItemPath } from '../paths/createAtrigamWorkItemPath';

interface Options {
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  updatedWorkItem: Partial<AtrigamWorkItem>;
  uid: UID;
}

export const updateWorkItemMutation = async ({
  environment,
  node,
  objectName,
  universe,
  updatedWorkItem,
  uid,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamWorkItemPath({ environment, node, objectName, universe });
  const document = doc(database, path);

  const snapshot = await getDoc(document);
  const data = snapshot.data() as AtrigamWorkItem | undefined;
  if (!data) {
    throw new Error(`Cannot update work item because i cannot find it at '${path}'`);
  }

  const updatedAt = getNowFirestoreTimestamp();
  const update: Partial<AtrigamWorkItem> = {
    updatedAt,
    updatedBy: uid,
    ...updatedWorkItem,
  };

  await updateDoc(document, mapUndefinedToNullValue(update));

  return { ...data, ...update } as AtrigamWorkItem;
};
