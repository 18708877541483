import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import {
  AtrigamObjectSubscription,
  AtrigamSubscriptionState,
  AtrigamUserSubscription,
  AtrigamWorkItem,
  extractAtrigamUniverseAreaTaskFlow,
  isAtrigamUserProfileFlow,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import {
  getObjectSubscriptionListQuery,
  getUserSubscriptionQuery,
  getWorkItemQuery,
  updateSubscriptionLastViewed,
} from '@atrigam/server-functions-eu-client';

import { cloneObject } from '../../helpers/cloneObject';
import { logger } from '../../helpers/logger';
import { preloadModels } from '../../helpers/routeHelper/preloadModels';
import { WorkItemBreadcrumbsAsync } from '../../pages/WorkItem/WorkItem.breadcrumbs.async';
import { WorkItemNavigationAsync } from '../../pages/WorkItem/WorkItem.navigation.async';
import { WorkItemPageAsync } from '../../pages/WorkItem/WorkItem.page.async';
import { loadSubscribers } from '../../pages/WorkItem/stores/Interactions/helpers/loadSubscribers';
import { workItemPageStoreFactory } from '../../pages/WorkItem/stores/WorkItem.page.store.factory';
import { getTitle } from '../../patterns/WorkItemHeader/helpers/getTitle';
import { Registry } from '../../services/Registry/Registry';
import { RouteBreadcrumb, RouteScope } from '../../services/Router/Router.types';
import { createRedirect } from '../../services/Router/helpers/createRedirect';
import { createRoute } from '../../services/Router/helpers/createRoute';
import { FlowsOverviewPath } from '../flowsOverview/flowsOverview.path';
import {
  Parameters as SubscriptionsParameter,
  SubscriptionsPath,
} from '../subscriptions/subscriptions.path';

import { Parameters, Query, pattern } from './editWorkItem.path';

let userSubscription: AtrigamUserSubscription | undefined;
let objectSubscriptionList: AtrigamObjectSubscription[] = [];
let workItemData: AtrigamWorkItem | undefined;

export const EditWorkItemRoute = createRoute<Parameters, Query>({
  title: () => {
    const {
      taskFlowModel,
      workItemModel,
      userSubscription: subscription,
      interactionsStore: { workItem },
    } = Registry.get('workItemPageStore');

    const { summaryFormat, label } = taskFlowModel;
    const displayCurrency = subscription?.displayCurrency;

    const parts: string[] = [];

    if (workItem.id) {
      const title = getTitle({
        template: summaryFormat.title,
        workItemModel,
        workItem,
        displayCurrency,
      });
      parts.push(title);
    }

    if (label) {
      parts.push(label);
    }

    parts.push(translate('routes.editWorkItem.title'));

    return parts.join(' ');
  },
  components: {
    breadcrumbs: WorkItemBreadcrumbsAsync,
    main: WorkItemPageAsync,
    navigation: WorkItemNavigationAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,

  getBreadcrumbs: ({ parameters }) => {
    const { taskFlowModel, node } = Registry.get('workItemPageStore');
    const subscriptionsStore = Registry.get('subscriptionsStore');
    const subscription = node ? subscriptionsStore.getSubscription({ node }) : undefined;

    const subscriptionState =
      subscription?.userSubscription.subscriptionState ?? AtrigamSubscriptionState.Accepted;

    const { environment, universeAreaTaskFlow } = parameters as unknown as Parameters;

    const parts: RouteBreadcrumb[] = [
      {
        label: translate(
          'layouts.DefaultLayout.Navigation.navigationItems.FlowOverviewNavigationItem.Overview',
        ),
        link: FlowsOverviewPath.getLink({ params: { environment } }),
      },
    ];

    if (!isAtrigamUserProfileFlow(universeAreaTaskFlow)) {
      parts.push({
        label: taskFlowModel.label ?? 'Flow',
        link: SubscriptionsPath.getLink({
          params: { environment, universeAreaTaskFlow },
          query: {
            state: subscriptionState,
            search:
              subscriptionsStore.searchString.length > 0
                ? subscriptionsStore.searchString
                : undefined,
            subscriptionId: node,
          },
        }),
      });
    }

    parts.push({
      label: taskFlowModel.label ?? 'WorkItem',
    });

    return parts;
  },

  canEnter: async ({ parameters: { universeAreaTaskFlow, environment, node } }) => {
    const modelsStore = Registry.get('modelsStore');
    const { uid } = Registry.get('userStore');
    const subscriptionsStore = Registry.get('subscriptionsStore');

    // if environment differs set it correctly
    if (modelsStore.environment !== environment) {
      modelsStore.updateEnvironment(environment);
    }

    await preloadModels();

    // prepare redirect
    const subscriptionsRedirect = createRedirect<SubscriptionsParameter, undefined>({
      name: 'SubscriptionsRoute',
      params: {
        environment,
        universeAreaTaskFlow,
      },
    });

    const { area, taskFlow, universe } = extractAtrigamUniverseAreaTaskFlow(universeAreaTaskFlow);

    // use subscription from store to speed things up
    const subscriptionEntity = subscriptionsStore.getSubscription({ node });
    if (subscriptionEntity) {
      userSubscription = subscriptionEntity.userSubscription;
    } else {
      // check if user subscription can be found
      userSubscription = await getUserSubscriptionQuery({
        environment,
        universe,
        area,
        taskFlow,
        node,
        uid,
      });
    }

    if (!userSubscription) {
      logger.warn(
        `* userSubscription cannot be found for ${universeAreaTaskFlow}! Redirecting to Subscriptions page`,
      );
      return subscriptionsRedirect;
    }

    // make sure we have object subscriptions
    objectSubscriptionList = await getObjectSubscriptionListQuery({
      universe: userSubscription.universe,
      environment,
      objectName: userSubscription.objectName,
      node,
    });

    if (!objectSubscriptionList || objectSubscriptionList.length === 0) {
      logger.warn(
        `* objectSubscriptionList cannot be found for ${universeAreaTaskFlow}! Redirecting to Subscriptions page`,
      );
      return subscriptionsRedirect;
    }

    if (subscriptionEntity?.workItem) {
      workItemData = cloneObject(subscriptionEntity.workItem);
    } else {
      // check if workItem is there
      workItemData = await getWorkItemQuery({
        universe: userSubscription.universe,
        environment,
        objectName: userSubscription.objectName,
        node,
      });
    }

    if (!workItemData) {
      logger.warn(
        `* WorkItem cannot be found for ${universeAreaTaskFlow}! Redirecting to Subscriptions page`,
      );
      return subscriptionsRedirect;
    }

    return true;
  },

  onBeforeEnter: async ({ parameters: { node, universeAreaTaskFlow, environment } }) => {
    const modelsStore = Registry.get('modelsStore');
    const userStore = Registry.get('userStore');
    const subscriptionsStore = Registry.get('subscriptionsStore');

    const taskFlowModel = modelsStore.getTaskFlowModel({
      universeAreaTaskFlow,
    });
    const workItemModel = modelsStore.getWorkItemModel({
      universeAreaTaskFlow,
    });

    throwIfNullable('userSubscription cannot be undefined', userSubscription);
    throwIfNullable('objectSubscriptionList cannot be undefined', objectSubscriptionList);
    throwIfNullable('workItem cannot be undefined', workItemData);
    throwIfNullable('taskFlowModel cannot be undefined', taskFlowModel);
    throwIfNullable('taskFlowModel.area cannot be undefined', taskFlowModel.area);
    throwIfNullable('taskFlowModel.taskFlow cannot be undefined', taskFlowModel.taskFlow);
    throwIfNullable('taskFlowModel.universe cannot be undefined', taskFlowModel.universe);
    throwIfNullable('taskFlowModel.objectName cannot be undefined', taskFlowModel.objectName);
    throwIfNullable('workItemModel cannot be undefined', workItemModel);

    void track({
      event: AtrigamAnalyticEvents.INTERACTION_OpenWorkItem,
      screen: AtrigamAnalyticScreens.Interaction,
      area: taskFlowModel.area,
      env: modelsStore.environment,
      flow: taskFlowModel.taskFlow,
      flowVersion: taskFlowModel.version,
      object: taskFlowModel.objectName,
      universe: taskFlowModel.universe,
      universeAreaFlow: universeAreaTaskFlow,
      workitemId: node,
    });

    // track when user opens an archived subscription
    if (userSubscription.subscriptionState === AtrigamSubscriptionState.Archived) {
      void track({
        event: AtrigamAnalyticEvents.SUBSCRIPTIONS_OpenArchived,
        screen: AtrigamAnalyticScreens.Subscriptions,
        area: taskFlowModel.area,
        env: modelsStore.environment,
        flow: taskFlowModel.taskFlow,
        flowVersion: taskFlowModel.version,
        object: taskFlowModel.objectName,
        universe: taskFlowModel.universe,
        universeAreaFlow: universeAreaTaskFlow,
        workitemId: node,
      });
    }

    // update last viewed timestamps
    await updateSubscriptionLastViewed({
      area: taskFlowModel.area,
      environment,
      node,
      objectName: taskFlowModel.objectName,
      taskFlow: taskFlowModel.taskFlow,
      uid: userStore.uid,
      universe: taskFlowModel.universe,
    });

    // prepare subscriptions store
    subscriptionsStore.syncWithInteraction({ userSubscription, workItem: workItemData });

    // prepare factory
    workItemPageStoreFactory.reset();
    workItemPageStoreFactory.set({
      environment,
      taskFlowModel,
      workItemModel,
      node,
      objectSubscriptionList,
      userSubscription,
      workItem: workItemData,
    });

    // clear store if it already instantiated
    // if we are already on the page using the store, it will automatically be recreated.
    if (Registry.hasInstance('workItemPageStore')) {
      Registry.clear('workItemPageStore');
    }

    // create store
    Registry.get('workItemPageStore');

    // load subscribers
    void loadSubscribers();
  },

  onAfterLeave: ({ name }) => {
    // don't remove if we are still on the subscriptions route
    if (name === 'EditWorkItemRoute') {
      return;
    }

    workItemPageStoreFactory.reset();
    Registry.clear('workItemPageStore');
  },
});
