import { difference, isNil } from 'rambda';

import { isAtrigamInteractionCodeScan } from '../../domain';
import { getDateTimeFromISO, ISODateTime } from '../../utilities';

interface Options {
  currentValue: unknown;
  originalValue: unknown;
}

export const validateConditionValueHasChanged = ({ currentValue, originalValue }: Options) => {
  if (currentValue === originalValue) {
    return false;
  }

  if (isNil(currentValue) && isNil(originalValue)) {
    return false;
  }

  // handle objects
  if (typeof currentValue === 'object' && currentValue !== null) {
    if (typeof originalValue !== 'object' || originalValue === null) {
      return true;
    }

    // handle code scan
    if (
      (isAtrigamInteractionCodeScan(currentValue) &&
        !isAtrigamInteractionCodeScan(originalValue)) ||
      (!isAtrigamInteractionCodeScan(currentValue) && isAtrigamInteractionCodeScan(originalValue))
    ) {
      return true;
    }

    if (isAtrigamInteractionCodeScan(currentValue) && isAtrigamInteractionCodeScan(originalValue)) {
      return currentValue.value === originalValue.value ? false : true;
    }

    // handle multiselect
    const currentValues = Object.values(currentValue);
    const originalValues = Object.values(originalValue);

    return difference(currentValues, originalValues).length === 0 &&
      currentValues.length === originalValues.length
      ? false
      : true;
  }

  // handle dates
  if (
    typeof currentValue === 'string' &&
    currentValue.length === 25 &&
    typeof originalValue === 'string' &&
    originalValue.length === 25
  ) {
    const currentDateTime = getDateTimeFromISO(currentValue as ISODateTime);
    if (currentDateTime.isValid) {
      // handle datetime
      const originalDateTime = getDateTimeFromISO(originalValue as ISODateTime);
      if (originalDateTime.isValid) {
        return originalDateTime.equals(currentDateTime) ? false : true;
      }
    }
  }

  return true;
};
