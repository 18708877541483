import type { AtrigamRole } from '@atrigam/atrigam-types';
import { action, makeObservable, observable } from 'mobx';

interface Options {
  roles: AtrigamRole[];
}

export class UserRoleChooserEntity {
  @observable
  roles: AtrigamRole[];

  @observable
  chosenRole?: AtrigamRole;

  constructor({ roles }: Options) {
    makeObservable(this);

    this.roles = roles;
  }

  @action
  chooseRole = (role: AtrigamRole) => {
    if (!this.roles.includes(role)) {
      throw new Error(`Role ${role} is not one of the defined roles!`);
    }

    this.chosenRole = role;
  };
}
