import GavelIcon from '@mui/icons-material/Gavel';
import LogoutIcon from '@mui/icons-material/Logout';
import PolicyIcon from '@mui/icons-material/Policy';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';

import { reactive } from '../../../../../../helpers/reactive';
import { Typography } from '../../../../../../primitive/Typography/Typography.style';
import { LogoutPath } from '../../../../../../routes/logout/logout.path';
import { PrivacyPolicyPath } from '../../../../../../routes/privacyPolicy/PrivacyPolicy.path';
import { TermsAndConditionsPath } from '../../../../../../routes/termsAndConditions/termsAndConditions.path';
import { UserProfilePath } from '../../../../../../routes/userProfile/userProfile.path';
import { Registry } from '../../../../../../services/Registry/Registry';
import { Impersonating } from '../Impersonating/Impersonating';

import { ProfileCompleteness } from './features/ProfileCompleteness/ProfileCompleteness';
import { SettingsMenu } from './features/SettingsMenu/SettingsMenu';
import { AvatarWrapper } from './styles/AvatarWrapper.style';
import { MenuWrapper } from './styles/MenuWrapper.style';

const UserAvatarComponent: React.FC = () => {
  const [profileMenu, setProfileMenu] = React.useState<HTMLButtonElement | null>(null);

  const isSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { user, avatarUri, userName, userInitials, isImpersonating } = Registry.get('userStore');

  const openMenu = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setProfileMenu(event.currentTarget);
    },
    [setProfileMenu],
  );

  const closeMenu = React.useCallback(() => {
    setProfileMenu(null);
  }, [setProfileMenu]);

  const handleSignOut = React.useCallback(() => {
    closeMenu();
    const router = Registry.get('router');
    void router.goTo({
      url: LogoutPath.getLink({}),
      transition: true,
    });
  }, [closeMenu]);

  const goToUserProfile = React.useCallback(() => {
    const router = Registry.get('router');
    void router.goTo({
      url: UserProfilePath.getLink({}),
      transition: true,
    });
  }, []);

  if (!user) {
    return null;
  }

  return (
    <>
      <Box flex={1} justifyContent="flex-end" display="flex">
        <IconButton
          aria-haspopup="true"
          color="inherit"
          aria-controls="profile-menu"
          onClick={openMenu}
          id="avatar"
          size="large"
        >
          <AvatarWrapper alt={userName} src={avatarUri} $size="small">
            {userInitials}
          </AvatarWrapper>
        </IconButton>
      </Box>

      <MenuWrapper
        id="profile-menu"
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        onClose={closeMenu}
        disableAutoFocusItem
      >
        <MenuItem onClick={goToUserProfile} aria-label="User Profile" id="user-menu-profile">
          <ListItemAvatar>
            <Avatar alt={userName} src={avatarUri}>
              {userInitials}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={userName} secondary={user.email} />
        </MenuItem>

        <ProfileCompleteness />

        {isSmaller && isImpersonating && (
          <Box component="li" paddingBottom={1}>
            <Impersonating />
          </Box>
        )}

        {isSmaller && <Divider component="li" />}
        {isSmaller && <SettingsMenu />}

        <Divider component="li" />

        <MenuItem component="a" href={PrivacyPolicyPath.getLink()} target="_blank">
          <ListItemIcon>
            <PolicyIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body1" id="privacyPolicy">
            {translate('layouts.DefaultLayout.Navigation.UserAvatar.privacyPolicy')}
          </Typography>
        </MenuItem>

        <MenuItem component="a" href={TermsAndConditionsPath.getLink()} target="_blank">
          <ListItemIcon>
            <GavelIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body1" id="termsAndConditions">
            {translate('layouts.DefaultLayout.Navigation.UserAvatar.termsAndConditions')}
          </Typography>
        </MenuItem>

        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body1" id="signout">
            {translate('layouts.DefaultLayout.Navigation.UserAvatar.logout')}
          </Typography>
        </MenuItem>
      </MenuWrapper>
    </>
  );
};

export const UserAvatar = reactive(UserAvatarComponent);
