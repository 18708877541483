import styled from 'styled-components';

import { ThemedStyledComponent } from '../../../../../../../themes/Themes.types';

export const MobileBackButtonWrapper = styled.div<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => `
  ${theme.breakpoints.up('lg')} {
    display: none;
  };
`,
);
