import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfigVersion } from '../../extension-config-base.types';
import { processPreviewSetValue } from '../helpers/processPreviewSetValue';

import {
  AtrigamFlowExtensionSetValue_2_0,
  ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NOW,
  ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NULL,
} from './set-value.2.0.extensions.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AtrigamFlowExtensionConfigSetValue_2_0: AtrigamFlowExtensionConfigVersion<AtrigamFlowExtensionSetValue_2_0> =
  {
    extensionType: AtrigamFlowExtensionTypes.SetValue,
    version: '2.0',
    description:
      'Define a field and set a value on your work items when specific conditions are met.',
    example: 'Hourly Rate = 80|90|110',
    settings: {
      field: {
        __fieldType: 'defaultField',
        required: true,
      },
      description: {
        __fieldType: 'description',
        required: false,
      },
      values: {
        __fieldType: 'conditionsObject',
        name: 'values',
        conditions: true,
        required: true,
        validateValue: ({ extension, value }) => {
          if (
            (extension.config.type === AtrigamDataType.Number ||
              extension.config.type === AtrigamDataType.Money) &&
            !/^[+-]?\d+(\.\d+)?$/.test(value)
          ) {
            return 'Only numbers are allowed';
          }

          if (
            (extension.config.type === AtrigamDataType.Date ||
              extension.config.type === AtrigamDataType.DateTime) &&
            ![
              ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NOW,
              ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NULL,
            ].includes(value)
          ) {
            return `Only "${ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NOW}" or "${ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NULL}" are allowed`;
          }

          return;
        },
      },
      type: {
        __fieldType: 'dataTypeSelectField',
        name: 'type',
        unitTarget: 'unit',
        options: [
          AtrigamDataType.Money,
          AtrigamDataType.Number,
          AtrigamDataType.String,
          AtrigamDataType.Date,
          AtrigamDataType.DateTime,
        ],
        required: true,
        // set condition values to NOW when switching the dataType
        processValue: ({ extension }) => {
          if (![AtrigamDataType.Date, AtrigamDataType.DateTime].includes(extension.config.type)) {
            return;
          }

          Object.entries(extension.config.values).forEach(([key, value]) => {
            if (value.trim().length === 0) {
              extension.config.values[key] = ATRIGAM_FLOW_EXTENSION_SET_VALUE_CONFIG_NOW;
            }
          });
        },
      },
    },
    processPreview: processPreviewSetValue,
  };
