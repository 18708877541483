import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamObjectSubscriptionType,
  AtrigamRole,
  AtrigamSubscriptionState,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUserSubscription,
  AtrigamWorkItem,
  AtrigamWorkItemId,
  UID,
  isAtrigamUserProfilePath,
} from '@atrigam/atrigam-types';

import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { createObjectSubscriptionForUserMutation } from '../mutations/createObjectSubscriptionForUser.mutation';
import { createUserSubscriptionMutation } from '../mutations/createUserSubscription.mutation';
import { createWorkItemMutation } from '../mutations/createWorkItem.mutation';
import { updateUserUpdatedAtMutation } from '../mutations/updateUserUpdatedAt.mutation';

interface Options {
  area: AtrigamAreaName;
  fieldUpdateNotifications?: Record<string, boolean>;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  objectName: AtrigamObjectName;
  reservedNode?: AtrigamWorkItemId;
  role: AtrigamRole;
  universe: AtrigamUniverseName;
  uid: UID;
  workItem: Partial<AtrigamWorkItem>;
}

export const createWorkItem = async ({
  area,
  fieldUpdateNotifications,
  environment,
  taskFlow,
  objectName,
  reservedNode,
  role,
  universe,
  uid,
  workItem,
}: Options) => {
  // create work item
  const createdWorkItem = await createWorkItemMutation({
    environment,
    objectName,
    reservedNode,
    universe,
    uid,
    workItem,
  });

  const subscriptionData: Omit<
    AtrigamUserSubscription,
    'universeDB' | 'universeNodeName' | 'roleInvites' | 'invitedAt' | 'updatedAt'
  > = {
    area,
    environment,
    fieldUpdateNotifications,
    taskFlow,
    invitedBy: uid,
    node: createdWorkItem.id,
    objectName,
    roles: [role],
    uid,
    subscriptionState: AtrigamSubscriptionState.Accepted, // we want to dive in directly
    universe,
    lastViewed: undefined,
    lastUpdatedAtByMe: getNowFirestoreTimestamp(),
    workItemUpdatedAt: createdWorkItem.updatedAt,
  };

  // create old object subscription
  await createObjectSubscriptionForUserMutation({
    ...subscriptionData,
    type: AtrigamObjectSubscriptionType.AtrigamUser,
  });
  await createUserSubscriptionMutation(subscriptionData);

  // if this is the user profile, update atrigam user updatedAt
  if (isAtrigamUserProfilePath({ environment, objectName, universe })) {
    await updateUserUpdatedAtMutation({ uid });
  }

  return createdWorkItem;
};
