import {
  create,
  evaluateDependencies,
  addDependencies,
  divideDependencies,
  multiplyDependencies,
  subtractDependencies,
} from 'mathjs';

const mathjs = create({
  evaluateDependencies,
  addDependencies,
  divideDependencies,
  multiplyDependencies,
  subtractDependencies,
});

export const evaluateMathExpression = (expresion: string) => {
  try {
    return mathjs.evaluate(expresion) as number | undefined;
  } catch {}
  return;
};
