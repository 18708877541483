import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import styled, { css } from 'styled-components';

import { reactive } from '../../../../helpers/reactive';
import { ThemedStyledComponent } from '../../../../themes/Themes.types';

const StyledMaterialDesignContent = styled(MaterialDesignContent)<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => css`
    &.notistack-MuiContent-success {
      background-color: ${theme.palette.success.main};
      color: ${theme.palette.success.contrastText};

      button {
        color: ${theme.palette.success.contrastText};
      }
    }

    &.notistack-MuiContent-error {
      background-color: ${theme.palette.error.main};
      color: ${theme.palette.error.contrastText};

      button {
        color: ${theme.palette.error.contrastText};
      }
    }

    &.notistack-MuiContent-warning {
      background-color: ${theme.palette.warning.main};
      color: ${theme.palette.warning.contrastText};

      button {
        color: ${theme.palette.warning.contrastText};
      }
    }

    &.notistack-MuiContent-info {
      background-color: ${theme.palette.info.main};
      color: ${theme.palette.info.contrastText};

      button {
        color: ${theme.palette.info.contrastText};
      }
    }
  `,
);

const ThemedSnackbarProviderComponent: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <SnackbarProvider
      Components={{
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        default: StyledMaterialDesignContent,
      }}
      maxSnack={4}
    >
      {children}
    </SnackbarProvider>
  );
};

export const ThemedSnackbarProvider = reactive(ThemedSnackbarProviderComponent);
