import { AvailableExtensionVersionProcessPreviewFunction } from '../../extension-config-base.types';
import { AtrigamFlowExtensionAutomatedInvitesConfigInvitesEmailWithRole } from '../1.0/automatedInvites.1.0.extensions.types';
import { extractEmailWithRole } from '../1.0/helpers/extractEmailWithRole';
import { AtrigamFlowExtensionAutomatedInvites } from '../automatedInvites.extensions.types';

export const processPreviewAutomaticInvites: AvailableExtensionVersionProcessPreviewFunction<
  AtrigamFlowExtensionAutomatedInvites
> = (extension) => {
  let preview = '';

  if (extension.config.invites) {
    const invites = Object.values(extension.config.invites).join(',').split(',');
    const emails = invites
      .map((emailWithRole) =>
        extractEmailWithRole(
          emailWithRole as AtrigamFlowExtensionAutomatedInvitesConfigInvitesEmailWithRole,
        ),
      )
      .filter((extracted) => extracted !== undefined)
      .map((extracted) => extracted!.email);

    if (emails.length > 0) {
      const distinctInvites = [...new Set(emails)];
      const number = distinctInvites.length - 1;
      if (number > 0) {
        preview = `${distinctInvites[0]} (+${number})`;
      } else {
        preview = distinctInvites[0];
      }
    }
  }

  if (!preview || preview === '') {
    preview = '?';
  }

  return preview;
};
