import { AtrigamModelsModelFlowInsightsRole, type AtrigamRole } from '@atrigam/atrigam-types';
import { computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { InsightsRoleBlockEntity } from './InsightsRoleBlock.entity';

interface Data {
  role: AtrigamRole;
  roleData: AtrigamModelsModelFlowInsightsRole;
}

export class InsightsRoleEntity {
  @persist('map', InsightsRoleBlockEntity)
  @observable
  blocks = observable.map<string, InsightsRoleBlockEntity>();

  @persist('map')
  @observable
  filters = observable.map<string, string>();

  @persist
  @observable
  role?: AtrigamRole;

  constructor(data?: Data) {
    makeObservable(this);

    if (data) {
      this.blocks.clear();

      if (data.roleData.blocks) {
        Object.entries(data.roleData.blocks).forEach(([key, block]) => {
          this.blocks.set(
            key,
            new InsightsRoleBlockEntity({ blockKey: key, blockData: block, role: data.role }),
          );
        });
      }

      this.filters.clear();
      if (data.roleData.filters) {
        Object.entries(data.roleData.filters).forEach(([key, field]) => {
          this.filters.set(key, field);
        });
      }

      this.role = data.role;
    }
  }

  @computed
  get hasBlocks() {
    return this.blocks.size > 0;
  }

  @computed
  get hasFilters() {
    return this.filters.size > 0;
  }
}
