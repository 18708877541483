import { AtrigamNotificationType, UnhandledCaseError } from '@atrigam/atrigam-types';

import { IS_DEV } from '../../../../../../../../../../../mode';

export const getLabel = (type: AtrigamNotificationType | null) => {
  if (!type) {
    return translate(
      'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.noType',
    );
  }

  switch (type) {
    case AtrigamNotificationType.ChatMessage: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.chatMessage',
      );
    }

    case AtrigamNotificationType.Digest: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.digest',
      );
    }

    case AtrigamNotificationType.EmailSuppression: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.emailSuppression',
      );
    }

    case AtrigamNotificationType.FieldNotUpdated: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.fieldNotUpdated',
      );
    }

    case AtrigamNotificationType.FieldUpdate: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.fieldUpdate',
      );
    }

    case AtrigamNotificationType.FlowDeployment: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.flowDeployment',
      );
    }

    case AtrigamNotificationType.Invite: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.invite',
      );
    }

    case AtrigamNotificationType.InviteStillPending: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.inviteStillPending',
      );
    }

    case AtrigamNotificationType.ModelError: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.modelError',
      );
    }

    case AtrigamNotificationType.RoleAssignment: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.roleAssignment',
      );
    }

    case AtrigamNotificationType.TaskFlowHandOver: {
      return translate(
        'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsFilter.type.taskFlowHandOver',
      );
    }

    default: {
      if (IS_DEV) {
        throw new UnhandledCaseError(type);
      }
      return type;
    }
  }
};
