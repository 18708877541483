import { FirebaseApp } from 'firebase/app';
import { DataSnapshot, enableLogging, getDatabase, onValue, ref } from 'firebase/database';

import { BaseWatcherService, BaseWatcherServiceOptions } from './base.watcher.service';

interface FirebaseDataSnapshot<T> extends Omit<DataSnapshot, 'val'> {
  val: () => T | undefined;
}

type LogEventFunction = (options: {
  message: string;
  type: string;
  level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug' | 'critical';
}) => void;

export interface FirebaseWatcherServiceOptions extends BaseWatcherServiceOptions {
  app: FirebaseApp;
  databaseUrl?: string;
  logEvent?: LogEventFunction;
}

export class FirebaseWatcherService extends BaseWatcherService {
  readonly app: FirebaseApp;

  private databaseUrl?: string;
  private logEvent?: LogEventFunction;

  constructor(options: FirebaseWatcherServiceOptions) {
    super({
      allowedDuplicatePaths: options.allowedDuplicatePaths,
      isDev: options.isDev,
      logError: options.logError,
    });

    this.databaseUrl = options.databaseUrl;

    // Log Database Events on sentry
    if (options.logEvent) {
      this.logEvent = options.logEvent;
      enableLogging(this.logDatabaseEvents);
    }

    this.app = options.app;
  }

  /**
   * subscribe to changes of a reference
   */
  subscribePath = <T>({
    path,
    onChange,
    onError,
    key,
  }: {
    path: string;
    onChange: (data: FirebaseDataSnapshot<T>, key: string) => void;
    onError?: (error: Error) => boolean;
    key?: string;
  }) => {
    this._checkIfPathIsDuplicate(path);

    const subscribeKey = key ?? `${this.app.name}:${path}:${Date.now()}`;

    const reference = ref(getDatabase(this.app, this.databaseUrl), path);

    const handleChange = (data: FirebaseDataSnapshot<T>) => onChange(data, subscribeKey);

    const unsubscribe = onValue(reference, handleChange, (error) => {
      if (onError && !onError(error)) {
        return;
      }

      this.logError({ error, context: { path } });

      throw error;
    });

    this.watchers.set(subscribeKey, { unsubscribe, path });

    return () => {
      this.unsubscribe(subscribeKey);
    };
  };

  private logDatabaseEvents = (message: string) => {
    if (!this.logEvent) {
      return;
    }

    if (message.includes('event')) {
      this.logEvent({ message, type: 'event' });
      return;
    }

    if (message.includes('Listen')) {
      this.logEvent({ message, type: 'Listen' });
      return;
    }

    if (message.includes('Unlisten')) {
      this.logEvent({ message, type: 'Unlisten', level: 'debug' });
      return;
    }

    if (message.includes('from server')) {
      // filter only ok responses
      if (message.includes('ok')) {
        return;
      }
      this.logEvent({ message, type: 'from server', level: 'debug' });
      return;
    }
  };
}
