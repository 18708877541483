import { AvatarTypeMap } from '@mui/material/Avatar';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import styled, { css } from 'styled-components';

import { PrimaryAvatar } from '../../../../../../../primitive/PrimaryAvatar/PrimaryAvatar.style';

interface Properties {
  $size?: 'small' | 'medium';
}

export const AvatarWrapper = styled(PrimaryAvatar)<
  // eslint-disable-next-line @typescript-eslint/ban-types
  OverridableComponent<AvatarTypeMap<{}, 'div'>> & Properties
>`
  ${({ $size = 'medium' }) => {
    if ($size === 'medium') {
      return css`
        width: 40px;
        height: 40px;
      `;
    }

    return css`
      width: 28px;
      height: 28px;
    `;
  }}
`;
