import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import { AtrigamUser } from '@atrigam/atrigam-types';
import { setUserVerifyEmailLaterMutation } from '@atrigam/server-functions-eu-client';
import React from 'react';

interface Options {
  user: AtrigamUser | undefined;
}

export const useVerifyLater = ({ user }: Options) => {
  const [isVerifyingLater, setIsVerifyingLater] = React.useState(false);

  const onVerifyLater = React.useCallback(async () => {
    if (!user?.profileNode || !user?.uid) {
      return;
    }

    setIsVerifyingLater(true);

    await setUserVerifyEmailLaterMutation({ uid: user.uid });
    void track({
      screen: AtrigamAnalyticScreens.VerifyEmail,
      event: AtrigamAnalyticEvents.VERIFY_EMAIL_VerifyLater,
    });

    setIsVerifyingLater(false);
  }, [user?.uid, user?.profileNode]);

  return { isVerifyingLater, onVerifyLater };
};
