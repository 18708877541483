import { AtrigamModelsModelFlow, AtrigamRole } from '@atrigam/atrigam-types';
import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { isAtrigamModelsModelFlowRecordDetailRoleInteraction } from '../../../helpers/isAtrigamModelsModelFlowRecordDetailRoleInteraction';

import { RecordDetailRoleEntity } from './RecordDetailRole.entity';
import { RecordDetailRoleInteractionEntity } from './RecordDetailRoleInteraction.entity';

export class RecordDetailEntity {
  @persist('map', RecordDetailRoleEntity)
  @observable
  roles = observable.map<string, RecordDetailRoleEntity>();

  @persist('map', RecordDetailRoleInteractionEntity)
  @observable
  default = observable.map<string, RecordDetailRoleInteractionEntity>();

  constructor(flowData?: AtrigamModelsModelFlow) {
    makeObservable(this);

    if (flowData) {
      this.roles.clear();
      if (flowData.recordDetail) {
        Object.entries(flowData.recordDetail).forEach(([role, roleData]) => {
          this.roles.set(role, new RecordDetailRoleEntity(roleData));
        });
      }

      this.default.clear();
      if (flowData.summary) {
        Object.entries(flowData.summary).forEach(([key, interaction]) => {
          if (key === 'format' || key === 'colorScheme') {
            return;
          }

          if (!isAtrigamModelsModelFlowRecordDetailRoleInteraction(interaction)) {
            return;
          }

          this.default.set(interaction.data, new RecordDetailRoleInteractionEntity(interaction));
        });
      }
    }
  }

  @action
  getRecordDetailForRoles = ({ roles }: { roles: AtrigamRole[] }) => {
    // check if any role is present in recordDetail, if none are present show default
    if (!roles.some((role) => this.roles.has(role))) {
      return [...this.default.values()];
    }

    // merge all roles together
    const merged = roles.reduce(
      (mergedObject, role) => {
        if (this.roles.has(role)) {
          this.roles.get(role)?.interactions.forEach((interaction) => {
            if (interaction.fieldName) {
              mergedObject[interaction.fieldName] = interaction;
            }
          });
        }

        return mergedObject;
      },
      {} as Record<string, RecordDetailRoleInteractionEntity>,
    );
    return Object.values(merged);
  };
}
