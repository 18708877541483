import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamSubscriptionState,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  ISODateTime,
  UID,
} from '@atrigam/atrigam-types';
import { getDocs } from 'firebase/firestore';

import { getUserSubscriptionListQueryReference } from '../queryReferences/getUserSubscription.list.query.reference';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  limit?: number;
  startAfter?: ISODateTime;
  subscriptionState?: AtrigamSubscriptionState;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const getUserSubscriptionListQuery = async (options: Options) => {
  const query_ = getUserSubscriptionListQueryReference(options);

  const collection_ = await getDocs(query_);
  return collection_.docs.map((snapshot) => snapshot.data());
};
