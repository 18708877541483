import {
  AtrigamChatMessage,
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';

import { createAtrigamChatMessageListPath } from '../firestore/paths/createAtrigamChatMessageListPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  environment: AtrigamEnvironment;
  limit: number;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
}

export const watchChatMessages: WatcherFunction<AtrigamChatMessage[], Options> = ({
  universe,
  environment,
  objectName,
  node,
  limit,
  onUpdate,
  onError,
}) => {
  const firestoreService = watcherServiceEu.get();
  const path = createAtrigamChatMessageListPath({ environment, node, objectName, universe });

  return firestoreService.subscribeToCollection<AtrigamChatMessage>({
    path,
    limit,
    orderBy: {
      fieldPath: 'createdAt',
      direction: 'desc',
    },
    onSnapshot: (collectionSnapshot, key) => {
      if (collectionSnapshot.size === 0) {
        void onUpdate([], key);
        return;
      }

      const messages = collectionSnapshot.docs.map((snapshot) => snapshot.data());
      void onUpdate(messages, key);
    },
    onError,
  });
};
