import { AnyDateString } from '../../../utilities/date.types';
import { getDateTime } from '../../../utilities/dateUtilities/getDateTime';
import { getRelativeDateTime } from '../../../utilities/dateUtilities/getRelativeDateTime';
import { AtrigamDataType } from '../../atrigam-datatypes';
import { DATATYPE_FORMAT_NOT_AVAILABLE } from '../../dataTypes.constants';
import { FormatDataTypeFunction } from '../../formatDataType.types';

export const formatDataTypeDateTime: FormatDataTypeFunction<AtrigamDataType.DateTime> = (
  options,
) => {
  if (options.options.relativeDateTime) {
    return formatRelative(options);
  } else {
    return formatDefault(options);
  }
};

const formatDefault: FormatDataTypeFunction<AtrigamDataType.DateTime> = ({
  value,
  options: { dateTimeFormat = 'dd.MM.yyyy HH:mm' },
}) => {
  if (value === DATATYPE_FORMAT_NOT_AVAILABLE || typeof value !== 'string') {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  const dateTime = getDateTime(value as AnyDateString);
  if (!dateTime.isValid) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  return dateTime.toFormat(dateTimeFormat);
};

const formatRelative: FormatDataTypeFunction<AtrigamDataType.DateTime> = ({ value }) => {
  if (value === DATATYPE_FORMAT_NOT_AVAILABLE || typeof value !== 'string') {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  const response = getRelativeDateTime(value as AnyDateString);
  if (response === '' || !response) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  return response;
};
