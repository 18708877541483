import {
  type AtrigamUserSubscription,
  type AtrigamWorkItem,
  type AtrigamWorkItemId,
  createAtrigamUniverseAreaTaskFlow,
  isAtrigamUserProfileFlow,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable } from 'mobx';

import { updateObject } from '../../../helpers/updateObject';
import { Registry } from '../../../services/Registry/Registry';

interface Data {
  node: AtrigamWorkItemId;
  subscription: AtrigamUserSubscription;
  workItem?: AtrigamWorkItem;
}

export class SubscriptionEntity {
  @observable
  node: AtrigamWorkItemId;

  @observable
  userSubscription: AtrigamUserSubscription;

  @observable
  finishedLoading = false;

  @observable
  workItem?: AtrigamWorkItem;

  constructor(data: Data) {
    makeObservable(this);

    this.node = data.node;
    this.userSubscription = data.subscription;
    this.workItem = data.workItem;
  }

  @computed
  get changedFieldsCount() {
    return (this.userSubscription.changedFields ?? []).length;
  }

  @computed
  get unreadChatMessageCount() {
    return (
      (this.userSubscription.chatMessageCount ?? 0) - (this.userSubscription.chatMessageSeen ?? 0)
    );
  }

  @computed
  get currentTaskFlowModel() {
    const taskFlowModel = Registry.get('modelsStore').getTaskFlowModel({
      universeAreaTaskFlow: this.universeAreaTaskFlow,
    });
    return taskFlowModel;
  }

  @computed
  get displayCurrency() {
    return this.userSubscription.displayCurrency ?? undefined;
  }

  @computed
  get hasUnreadChatMessages() {
    return this.unreadChatMessageCount > 0;
  }

  @computed
  get isAtrigamUserProfile() {
    return isAtrigamUserProfileFlow(this.universeAreaTaskFlow);
  }

  @computed
  get isDisabled() {
    return this.isAtrigamUserProfile;
  }

  @computed
  get isValid() {
    return this.universeModel !== undefined && this.workItem !== undefined;
  }

  @computed
  get roles() {
    return this.userSubscription.roles;
  }

  @computed
  get universeAreaTaskFlow() {
    return createAtrigamUniverseAreaTaskFlow({
      area: this.userSubscription.area,
      taskFlow: this.userSubscription.taskFlow,
      universe: this.userSubscription.universe,
    });
  }

  @computed
  get universeModel() {
    return Registry.get('modelsStore').getUniverseModel({
      universe: this.userSubscription.universe,
    });
  }

  @computed
  get workItemModel() {
    const universeModel = this.universeModel;
    if (!universeModel) {
      return;
    }
    return universeModel.workItemModels.get(this.userSubscription.objectName);
  }

  @action
  setFinishedLoading = () => {
    this.finishedLoading = true;
  };

  @action
  updateSubscription = (subscription: AtrigamUserSubscription) => {
    updateObject({ target: this.userSubscription, source: subscription });
  };

  @action
  updateWorkItem = (workItem: AtrigamWorkItem) => {
    if (!this.workItem) {
      this.workItem = workItem;
      return;
    }

    updateObject({ target: this.workItem, source: workItem });
  };
}
