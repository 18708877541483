import {
  AtrigamWorkItem,
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamFirestoreUniverseKpi,
  ChangeSet,
} from '@atrigam/atrigam-types';

import { AtrigamFlowExtension, AtrigamFlowExtensionTypes } from '../atrigam-extensions.types';

export interface BaseProcessorOptions {
  beforeWorkItem?: Omit<AtrigamWorkItem, 'id'>;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
  universeKpisMap: Record<AtrigamFirestoreUniverseKpi['id'], AtrigamFirestoreUniverseKpi['result']>;
  workItem: AtrigamWorkItem;
}

export interface ProcessorOptions<T extends AtrigamFlowExtension> extends BaseProcessorOptions {
  extension: T;
}

export type ExtensionProcessFunction<T extends AtrigamFlowExtension> = (
  options: ProcessorOptions<T>,
) => Promise<ChangeSet[]> | ChangeSet[];

export enum AtrigamFlowExtensionProcessorType {
  All = 'all',
  ServerOnly = 'serverOnly',
  ClientOnly = 'clientOnly',
}

export interface AtrigamFlowExtensionProcessor<T extends AtrigamFlowExtension> {
  type: AtrigamFlowExtensionTypes;
  version: string;
  processorType: AtrigamFlowExtensionProcessorType;

  supportsVersion: (version: string) => boolean;

  process: ExtensionProcessFunction<T>;
}
