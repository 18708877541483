import { DeleteAccountFormPageAsync } from '../../pages/DeleteAccountForm/DeleteAccountForm.page.async';
import { RouteParameters, RouteQuery, RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './deleteAccountForm.path';

export const DeleteAccountFormRoute = createRoute<RouteParameters, RouteQuery>({
  title: () => translate('routes.deleteAccountForm.title'),
  components: {
    main: DeleteAccountFormPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedOut,
  // onBeforeEnter: () => {
  //   void track({
  //     event: AtrigamAnalyticEvents.LOGIN_EnterLogin,
  //     screen: AtrigamAnalyticScreens.Login,
  //   });
  // },
});
