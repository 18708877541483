import { Currency } from '../../../../../domain/common.types';
import { AtrigamFXRates } from '../../../../../firestore/atrigam-eu';
import { ATRIGAM_SYSTEM_CURRENCY } from '../../../../dataTypes.constants';

interface Options {
  value: number;
  targetCurrency: Currency;
  fxRates: AtrigamFXRates | undefined;
}

export const calculateSystemValueIntoCurrency = ({ value, targetCurrency, fxRates }: Options) => {
  // no need to calculate if the target is the systemCurrency
  if (ATRIGAM_SYSTEM_CURRENCY === targetCurrency) {
    return value;
  }

  if (!fxRates) {
    return;
  }
  if (!fxRates.rates[targetCurrency]) {
    return;
  }

  const rate = fxRates.rates[targetCurrency];
  return value * rate;
};
