import {
  type AtrigamModelsModelFlowInsightsAggregationType,
  AtrigamModelsModelFlowInsightsRoleBlock,
  type AtrigamModelsModelFlowInsightsRoleBlockVisualisationType,
  type AtrigamRole,
  UnhandledCaseError,
} from '@atrigam/atrigam-types';
import { computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { IS_DEV } from '../../../../../mode';

interface Data {
  blockKey: string;
  blockData: AtrigamModelsModelFlowInsightsRoleBlock;
  role: AtrigamRole;
}

export class InsightsRoleBlockEntity {
  @persist
  @observable
  blockKey?: string;

  @persist
  @observable
  role?: AtrigamRole;

  @persist
  @observable
  expanded = true;

  @persist
  @observable
  sequence?: number;

  @persist
  @observable
  insightsType?: AtrigamModelsModelFlowInsightsRoleBlock['insightsType'];

  // last items
  @persist
  @observable
  length?: number;

  @persist
  @observable
  timeDimension?: string;

  // visualization

  @persist
  @observable
  aggregationDimension?: string;

  @persist
  @observable
  aggregationType?: AtrigamModelsModelFlowInsightsAggregationType;

  @persist
  @observable
  visualisationDimension?: string;

  @persist
  @observable
  visualisationType?: AtrigamModelsModelFlowInsightsRoleBlockVisualisationType;

  constructor(data?: Data) {
    makeObservable(this);

    if (data) {
      this.blockKey = data.blockKey;
      this.role = data.role;

      this.expanded = data.blockData.expanded;

      this.sequence = data.blockData.sequence;
      if (!this.sequence && data.blockKey) {
        const match = data.blockKey.match(/^(\d+)-/);
        if (match) {
          this.sequence = Number.parseInt(match[1], 10);
        }
      }

      this.insightsType = data.blockData.insightsType;

      switch (data.blockData.insightsType) {
        case 'lastitems': {
          this.length = data.blockData.length;
          this.timeDimension = data.blockData.timeDimension;

          break;
        }

        case 'total': {
          this.aggregationDimension = data.blockData.aggregationDimension;
          this.aggregationType = data.blockData.aggregationType;

          break;
        }

        case 'visualisation': {
          this.aggregationDimension = data.blockData.aggregationDimension;
          this.aggregationType = data.blockData.aggregationType;
          this.visualisationDimension = data.blockData.visualisationDimension;
          this.visualisationType = data.blockData.visualisationType ?? 'pie';

          break;
        }

        default: {
          if (IS_DEV) {
            throw new UnhandledCaseError(data.blockData);
          }
        }
      }
    }
  }

  @computed
  get uniqueId() {
    const parts = [
      this.insightsType,
      this.length,
      this.timeDimension,
      this.aggregationDimension,
      this.aggregationType,
      this.visualisationDimension,
      this.visualisationType,
    ];

    return parts.map((v) => v ?? '?').join('#');
  }

  @computed
  get key() {
    return `${this.role ?? ''}-${this.blockKey ?? ''}`;
  }

  @computed
  get data() {
    if (!this.insightsType) {
      return;
    }

    switch (this.insightsType) {
      case 'lastitems': {
        if (this.length && this.timeDimension) {
          return {
            insightsType: this.insightsType,
            length: this.length,
            timeDimension: this.timeDimension,
          };
        }

        return;
      }

      case 'total': {
        if (this.aggregationDimension && this.aggregationType) {
          return {
            insightsType: this.insightsType,
            aggregationDimension: this.aggregationDimension,
            aggregationType: this.aggregationType,
          };
        }

        return;
      }

      case 'visualisation': {
        if (
          this.insightsType &&
          this.aggregationDimension &&
          this.aggregationType &&
          this.visualisationDimension &&
          this.visualisationType
        ) {
          return {
            insightsType: this.insightsType,
            aggregationDimension: this.aggregationDimension,
            aggregationType: this.aggregationType,
            visualisationDimension: this.visualisationDimension,
            visualisationType: this.visualisationType,
          };
        }

        return;
      }

      default: {
        return;
      }
    }
  }
}
