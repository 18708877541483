// Atrigam Domain: User Notification

import { FirestoreTimestamp } from '../utilities/date.types';

import { AtrigamObjectName } from './objectModel.types';
import { AtrigamTaskFlowName } from './taskFlow.types';
import {
  AtrigamAreaName,
  AtrigamDatabaseUniverse,
  AtrigamEnvironment,
  AtrigamRole,
  AtrigamUniverseName,
} from './universe.types';
import { Email, UID } from './user.types';
import { AtrigamWorkItemId } from './workItem.types';

export enum AtrigamNotificationType {
  ChatMessage = 'chatMessage',
  Digest = 'digest',
  EmailSuppression = 'emailSuppression',
  FieldUpdate = 'fieldUpdate',
  FieldNotUpdated = 'fieldNotUpdated',
  /** @deprecated not used anymore. */
  FlowDeployment = 'flowDeployment',
  Invite = 'invite',
  InviteStillPending = 'inviteStillPending',
  ModelError = 'modelError',
  /** @deprecated not used anymore. */
  RoleAssignment = 'roleAssignment',
  TaskFlowHandOver = 'taskFlowHandOver',
}

export enum AtrigamNotificationPushStateType {
  NotSend = 'NotSend',
  Send = 'Send',
  Failed = 'Failed',
}

export interface AtrigamUserNotification {
  area?: AtrigamAreaName;
  environment?: AtrigamEnvironment;
  flow?: AtrigamTaskFlowName;
  id: string;
  message: string;
  objectName?: AtrigamObjectName;
  pushStatus: AtrigamUserNotificationPushStatus;
  role?: AtrigamRole;
  sender?: UID;
  sendViaDigest?: boolean;
  subscriptionId?: AtrigamWorkItemId;
  timestamp: FirestoreTimestamp;
  title: string;
  type: AtrigamNotificationType | null;
  universe?: AtrigamUniverseName;
  unread: boolean;
  updatedAt?: FirestoreTimestamp;
  workitemId?: AtrigamWorkItemId;

  // modeler specific
  runCount?: number;
  modelerCategory?: string;
}

export type AtrigamPushNotificationData =
  | AtrigamPushNotificationDataChatMessage
  | AtrigamPushNotificationDataDigest
  | AtrigamPushNotificationDataEmailSuppression
  | AtrigamPushNotificationDataFieldNotUpdated
  | AtrigamPushNotificationDataFieldUpdate
  | AtrigamPushNotificationDataFlowDeployment
  | AtrigamPushNotificationDataInvite
  | AtrigamPushNotificationDataInviteStillPending
  | AtrigamPushNotificationDataModelError
  | AtrigamPushNotificationDataTaskFlowHandOver;

export interface AtrigamPushNotificationDataChatMessage {
  type: AtrigamNotificationType.ChatMessage;
  notificationId?: string;
  area: AtrigamAreaName;
  env: AtrigamEnvironment;
  flow: AtrigamTaskFlowName;
  nodeName: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  screen: 'Chat';
  universe: AtrigamUniverseName;
}

export interface AtrigamPushNotificationDataDigest {
  type: AtrigamNotificationType.Digest;
  notificationId?: string;
}

export interface AtrigamPushNotificationDataEmailSuppression {
  type: AtrigamNotificationType.EmailSuppression;
  notificationId?: string;
  email: Email;
  emailIsBlocked: boolean;
  emailIsBounced: boolean;
  emailIsMarkedAsSpam: boolean;
}

export interface AtrigamPushNotificationDataFieldNotUpdated {
  type: AtrigamNotificationType.FieldNotUpdated;
  notificationId?: string;
  area: AtrigamAreaName;
  env: AtrigamEnvironment;
  flow: AtrigamTaskFlowName;
  nodeName: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  screen: 'FieldUpdate';
  universe: AtrigamUniverseName;
  universeDB: AtrigamDatabaseUniverse;
}

export interface AtrigamPushNotificationDataFieldUpdate {
  type: AtrigamNotificationType.FieldUpdate;
  notificationId?: string;
  area: AtrigamAreaName;
  env: AtrigamEnvironment;
  flow: AtrigamTaskFlowName;
  nodeName: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  screen: 'FieldUpdate';
  universe: AtrigamUniverseName;
  universeDB: AtrigamDatabaseUniverse;
}

export interface AtrigamPushNotificationDataFlowDeployment {
  type: AtrigamNotificationType.FlowDeployment;
  notificationId?: string;
  area: AtrigamAreaName;
  env: AtrigamEnvironment;
  flow: AtrigamTaskFlowName;
  screen: 'Subscriptions';
  universe: AtrigamUniverseName;
  universeDB: AtrigamDatabaseUniverse;
}

export interface AtrigamPushNotificationDataInvite {
  type: AtrigamNotificationType.Invite;
  notificationId?: string;
  area: AtrigamAreaName;
  env: AtrigamEnvironment;
  flow: AtrigamTaskFlowName;
  nodeName: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  role: AtrigamRole;
  screen: 'Subscriptions';
  universe: AtrigamUniverseName;
}

export interface AtrigamPushNotificationDataInviteStillPending {
  type: AtrigamNotificationType.InviteStillPending;
  notificationId?: string;
  area: AtrigamAreaName;
  env: AtrigamEnvironment;
  flow: AtrigamTaskFlowName;
  nodeName: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  screen: 'Subscriptions';
  universe: AtrigamUniverseName;
}

export interface AtrigamPushNotificationDataModelError {
  type: AtrigamNotificationType.ModelError;
  notificationId?: string;
  area: AtrigamAreaName;
  env: AtrigamEnvironment;
  flow: AtrigamTaskFlowName;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
}

export interface AtrigamPushNotificationDataTaskFlowHandOver {
  type: AtrigamNotificationType.TaskFlowHandOver;
  notificationId?: string;
  area: AtrigamAreaName;
  flow: AtrigamTaskFlowName;
  universe: AtrigamUniverseName;
  oldUser: {
    uid: UID;
    email: Email;
  };
}

export interface AtrigamUserNotificationPushStatus {
  expo: AtrigamNotificationPushStateType;
  fcm: AtrigamNotificationPushStateType;
}
