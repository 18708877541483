import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseKpiListPath,
  createAtrigamDatabaseUniverseEnvironment,
  AtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';

interface Options {
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const createAtrigamUniverseKpiListPath = ({
  environment,
  objectName,
  universe,
}: Options): AtrigamUniverseKpiListPath => {
  const databaseUniverseEnvironment = createAtrigamDatabaseUniverseEnvironment({
    universe,
    environment,
  });

  return `/universes/${databaseUniverseEnvironment}/objects/${objectName}/kpi`;
};
