import { AtrigamFirestoreUserSubscriptionsUserDocument, UID } from '@atrigam/atrigam-types';

import { createAtrigamFirestoreUserSubscriptionsUserPath } from '../firestore/paths/createAtrigamFirestoreUserSubscriptionsUserPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  uid: UID;
}

export const watchUserSubscriptionsRootNode: WatcherFunction<
  AtrigamFirestoreUserSubscriptionsUserDocument,
  Options
> = ({ onUpdate, onError, uid }) => {
  const firestoreService = watcherServiceEu.get();

  const path = createAtrigamFirestoreUserSubscriptionsUserPath({ uid });

  return firestoreService.subscribeToDocument<AtrigamFirestoreUserSubscriptionsUserDocument>({
    path,
    onSnapshot: (snapshot, key) => {
      const userSubscription = snapshot.data();
      void onUpdate(userSubscription, key);
    },
    onError,
  });
};
