import { formatDuration } from '../../../utilities/dateUtilities/formatDuration';
import { getDuration } from '../../../utilities/dateUtilities/getDuration';
import { AtrigamDataType } from '../../atrigam-datatypes';
import { DATATYPE_FORMAT_NOT_AVAILABLE } from '../../dataTypes.constants';
import { FormatDataTypeFunction } from '../../formatDataType.types';

export const formatDataTypeFixedDuration: FormatDataTypeFunction<AtrigamDataType.FixedDuration> = ({
  value,
  options: { language = 'en' },
}) => {
  if (value === null || typeof value !== 'number') {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  const duration = getDuration(value);
  if (!duration.isValid) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  const formatted = formatDuration({ duration, options: { largest: 2, round: true, language } });
  return `~ ${formatted}`;
};
