import { AtrigamDataType } from '../../atrigam-datatypes';
import { DATATYPE_FORMAT_NOT_AVAILABLE } from '../../dataTypes.constants';
import { FormatDataTypeFunction } from '../../formatDataType.types';

export const formatDataTypeObject: FormatDataTypeFunction<AtrigamDataType.Object> = ({ value }) => {
  if (typeof value !== 'object' || value === null) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  const allValuesAreString = Object.values(value).every((v) => typeof v === 'string');
  const items = Object.keys(value).length;

  if (!allValuesAreString || items > 5) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  return Object.values(value).join(', ');
};
