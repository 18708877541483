import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';
import {
  watchObjectSubscriptionList,
  watchUniverseRegistration,
  watchUserSubscription,
} from '@atrigam/server-functions-eu-client';

import { WorkItemPageStore } from '../../WorkItem.page.store';

import { loadSubscribers } from './loadSubscribers';

interface Options {
  interactionStore: WorkItemPageStore;
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const watchWorkItemAndObjectSubscriptions = ({
  interactionStore,
  area,
  environment,
  taskFlow,
  node,
  objectName,
  uid,
  universe,
}: Options) => {
  // watch object subscriptions
  let initialObjectWatcher = true;
  const objectSubscriptionsDisposer = watchObjectSubscriptionList({
    environment,
    node,
    objectName,
    universe,
    onUpdate: (objectSubscriptionList) => {
      if (!objectSubscriptionList) {
        return;
      }

      interactionStore.updateObjectSubscriptionList(objectSubscriptionList);

      if (!initialObjectWatcher) {
        void loadSubscribers();
      }
      initialObjectWatcher = false;
    },
  });

  // watch user subscriptions
  const userSubscriptionDisposer = watchUserSubscription({
    environment,
    node,
    onUpdate: interactionStore.updateUserSubscription,
    universe,
    area,
    taskFlow,
    uid,
  });

  // watch universe registration
  let initialUniverseRegistration = true;
  const universeRegistrationDisposer = watchUniverseRegistration({
    universe,
    onUpdate: (data) => {
      if (!data) {
        return;
      }

      if (!initialUniverseRegistration) {
        // if the roles are changed in the modeler we need to sync it to the team
        void loadSubscribers();
      }

      initialUniverseRegistration = false;
    },
  });

  interactionStore.disposers.push(() => {
    if (objectSubscriptionsDisposer) {
      objectSubscriptionsDisposer();
    }

    if (userSubscriptionDisposer) {
      userSubscriptionDisposer();
    }

    if (universeRegistrationDisposer) {
      universeRegistrationDisposer();
    }
  });
};
