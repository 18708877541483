/* eslint-disable @typescript-eslint/no-explicit-any */
import localforage from 'localforage';
import { action } from 'mobx';

import { withReaction } from '../../helpers/withReaction';

type Hydrate = (serialized: any) => void;

interface PersistOptions<Data> {
  name: string;
  data: () => Data;
  hydrate: Hydrate;
  serialize?: (data: Data) => any;
}

interface GetItemOptions {
  name: string;
  hydrate?: Hydrate;
}

interface SetItemOptions<Value> {
  name: string;
  value: Value;
  serialize?: (value: Value) => any;
}

export class LocalStorage {
  private localforage: typeof localforage;

  constructor() {
    // create localforage instance
    this.localforage = localforage.createInstance({
      name: 'atrigam',
      storeName: 'webclient',
    });
  }

  getStorage = () => {
    return this.localforage;
  };

  setItem = <Value>({ name, value, serialize }: SetItemOptions<Value>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const serialized = serialize ? serialize(value) : value;
    void this.localforage.setItem(name, serialized);
  };

  getItem = async <Value>({ name, hydrate }: GetItemOptions): Promise<Value | undefined> => {
    try {
      const value = await this.localforage.getItem<Value>(name);
      if (hydrate) {
        hydrate(value);
      }

      if (value === null) {
        return;
      }

      return value;
    } catch {
      // something went wrong -> do not hydrate store
    }
  };

  persist = <Data>(options: PersistOptions<Data>) => {
    const { name, serialize, data } = options;
    const hydrate = action(options.hydrate);
    // try finding value in local forage
    void this.getItem({ name, hydrate });
    // add reaction to handle changes and return disposer
    return withReaction({
      name,
      data,
      onChange: (item) => {
        this.setItem({ name, value: item, serialize });
      },
      fireImmediately: true,
    });
  };

  clear = () => {
    return this.localforage.clear();
  };
}
