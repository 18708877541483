import { getISO } from '@atrigam/atrigam-types';
import { watchUserSubscriptionsRootNode } from '@atrigam/server-functions-eu-client';

import { hasDateChanged } from '../../../helpers/hasDateChanged';
import { Registry } from '../../../services/Registry/Registry';
import { ModelsStore } from '../Models.store';

/**
 * First part of watching the userSubscription and changes count for every Flow in the overview
 * This one watches for changes in the user subscription root node. If it changes, which means
 * some subscription has changed we write the updatedAt iso date into the modelsStore.
 *
 * The modelsStore userSubscriptionsLastUpdatedAt is then watched by the TaskFlowModelEntity for changes.
 */
export const watchUserSubscriptionsRootNodeUpdatedAt = (modelsStore: ModelsStore) => {
  const { uid } = Registry.get('userStore');

  const disposer = watchUserSubscriptionsRootNode({
    uid,
    onUpdate: (userSubscription) => {
      if (!userSubscription) {
        return;
      }
      const updatedAt = getISO(userSubscription.updatedAt);

      if (!hasDateChanged(modelsStore.userSubscriptionsLastUpdatedAt, updatedAt)) {
        return;
      }

      modelsStore.setUserSubscriptionsLastUpdatedAt(updatedAt);
    },
  });

  return disposer;
};
