import { NOOP, ServerFunctionHttpOptions } from './clientSDK.types';

export const TRANSACTION_ID_HEADER = 'X-Transaction-ID';
export const APP_ENVIRONMENT_HEADER = 'X-Application-Environment';

interface Options {
  functionName: string;
  region?: string;
  appUrlTemplate: string;
}

export const createHttpFunctionClientSDK = <P = Record<string, unknown>, R = unknown>({
  functionName,
  region = 'europe-west3',
  appUrlTemplate,
}: Options) => {
  return async ({
    onException,
    onStatusNotOk = NOOP,
    XAppEnvironment,
    XTransactionID,
    headers,
    credentials,
    ...data
  }: ServerFunctionHttpOptions<P>) => {
    const url = appUrlTemplate.replace('%REGION%', region).replace('%FUNCTION%', functionName);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
          ...(XAppEnvironment ? { [APP_ENVIRONMENT_HEADER]: XAppEnvironment } : {}),
          ...(XTransactionID ? { [TRANSACTION_ID_HEADER]: XTransactionID } : {}),
          ...headers,
        },
        credentials,
      }).catch(() => {
        // ignore all errors
      });

      if (!response) {
        return;
      }

      if (response.status !== 200) {
        let responseText: string | undefined;
        try {
          responseText = await response.text();
        } catch {}

        let responseJSON: unknown;
        try {
          responseJSON = await response.json();
        } catch {}

        onStatusNotOk({
          payload: data as P,
          headers: { XAppEnvironment, XTransactionID },
          response: {
            status: response.status,
            statusText: response.statusText,
            text: responseText,
            json: responseJSON,
          },
        });

        return;
      }

      return (await response.json()) as R;
    } catch (error) {
      if (onException) {
        onException(error);
        return;
      }

      // rethrow if not handled by user
      throw error;
    }
  };
};
