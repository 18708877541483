import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUniverseRegistrationClientRole,
  FirestoreTimestamp,
  type ISODateTime,
  getISO,
  getNowDateTime,
} from '@atrigam/atrigam-types';
import { action, computed, keys, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { UserUniverseClientRolesEntity } from './UserUniverseClientRoles.entity';

interface AddUniverseOptions {
  clientRoles: AtrigamUniverseRegistrationClientRole[];
  universe: AtrigamUniverseName;
  updatedAt: FirestoreTimestamp;
}

export class UserClientRolesEntity {
  @persist('map', UserUniverseClientRolesEntity)
  @observable
  universes = observable.map<AtrigamUniverseName, UserUniverseClientRolesEntity>();

  @observable
  isSynching = false;

  @observable
  lastUpdated?: ISODateTime;

  constructor() {
    makeObservable(this);
  }

  @computed
  get environments() {
    const environments = new Set<AtrigamEnvironment>();

    this.universes.forEach((universeClientRolesModel) => {
      universeClientRolesModel.clientRoles.forEach((clientRoleModel) => {
        if (clientRoleModel.environment) {
          environments.add(clientRoleModel.environment);
        }
      });
    });

    return [...environments];
  }

  @computed
  get universeNames() {
    return keys(this.universes) as AtrigamUniverseName[];
  }

  @action
  addUniverse = ({ universe, updatedAt, clientRoles }: AddUniverseOptions) => {
    if (this.universes.has(universe)) {
      this.universes.get(universe)!.update({ updatedAt, universe, clientRoles });
      return;
    }

    this.universes.set(
      universe,
      new UserUniverseClientRolesEntity({ universe, updatedAt, clientRoles }),
    );
  };

  @action
  clear = () => {
    this.universes.clear();
  };

  @action
  removeUniverse = ({ universe }: { universe: AtrigamUniverseName }) => {
    this.universes.delete(universe);
  };

  @action
  setIsSynching = (synching: boolean) => {
    this.isSynching = synching;

    if (!synching) {
      this.lastUpdated = getISO(getNowDateTime());
    }
  };

  getUniverseNamesForEnvironment = ({ environment }: { environment: AtrigamEnvironment }) => {
    const universeNames: AtrigamUniverseName[] = [];
    this.universes.forEach((userUniverseClientRolesModel) => {
      if (
        userUniverseClientRolesModel.universe &&
        userUniverseClientRolesModel.hasEnvironment({ environment })
      ) {
        universeNames.push(userUniverseClientRolesModel.universe);
      }
    });

    return universeNames;
  };

  getRolesForTaskFlow = ({
    environment,
    universe,
    area,
    taskFlow,
  }: {
    area: AtrigamAreaName;
    environment: AtrigamEnvironment;
    taskFlow: AtrigamTaskFlowName;
    universe: AtrigamUniverseName;
  }) => {
    if (!this.universes.has(universe)) {
      return [];
    }

    return this.universes.get(universe)!.getRolesForTaskFlow({ environment, area, taskFlow });
  };
}
