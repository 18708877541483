import { IS_DEV } from './mode';
import { CreateWorkItemRoute } from './routes/createWorkItem/createWorkItem.route';
import { DeleteAccountFormRoute } from './routes/deleteAccountForm/deleteAccountForm.route';
import { EditWorkItemRoute } from './routes/editWorkItem/editWorkItem.route';
import { FlowsOverviewRoute } from './routes/flowsOverview/flowsOverview.route';
import { HomeRoute } from './routes/home/home.route';
import { ImpersonateRoute } from './routes/impersonate/impersonate.route';
import { InsightsRoute } from './routes/insights/insights.route';
import { LoginRoute } from './routes/login/login.route';
import { LogoutRoute } from './routes/logout/logout.route';
import { NotFoundRoute } from './routes/notFound/notFound.route';
import { PlaygroundRoute } from './routes/playground/playground.route';
import { PushNotificationPlaygroundRoute } from './routes/pushNotificationPlayground/pushNotificationPlayground.route';
import { SubscriptionsRoute } from './routes/subscriptions/subscriptions.route';
import { UnimpersonateRoute } from './routes/unimpersonate/unimpersonate.route';
import { UserProfileRoute } from './routes/userProfile/userProfile.route';
import { VerifyEmailRoute } from './routes/verifyEmail/verifyEmail.route';
import { VerifyEmailTokenRoute } from './routes/verifyEmailToken/verifyEmailToken.route';

export const routes = {
  // Unauthorized Routes
  LoginRoute,
  LogoutRoute,
  VerifyEmailTokenRoute,
  DeleteAccountFormRoute,

  // Authorized Routes
  UnimpersonateRoute,
  ImpersonateRoute,

  VerifyEmailRoute,
  InsightsRoute,
  SubscriptionsRoute,
  CreateWorkItemRoute,
  EditWorkItemRoute,
  FlowsOverviewRoute,
  HomeRoute,
  UserProfileRoute,

  // Not found route
  NotFoundRoute,

  // Playground
  ...(IS_DEV ? { PlaygroundRoute, PushNotificationPlaygroundRoute } : {}),
};
