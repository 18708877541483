import { Currency } from '../../../../../domain/common.types';
import { isCurrencySymbol } from '../../../../../domain/guards/isCurrencySymbol';
import { ATRIGAM_SYSTEM_CURRENCY } from '../../../../dataTypes.constants';

import { extractMoneyAndCurrency } from './extractMoneyAndCurrency';
import { getCurrencyForCurrencySymbol } from './getCurrencyForCurrencySymbol';
import { DECIMAL_SEPARATOR, NUMBER_SEPARATOR } from './getMoneyNumberRegex';

/**
 * parses a string for money type with currency or symbol as a prefix or suffix.
 *
 * if no currency is there it will automatically use default one.
 */
export const parseMoneyString = (moneyString: string) => {
  // 1. Parse money parts from string (symbol, currency, value)

  const match = extractMoneyAndCurrency({ moneyString, strict: true });
  if (!match) {
    return;
  }

  // 2. Prepare variables

  const moneyValue = match.value;
  const currencyOrSymbol = match.currencyOrSymbol;

  let number: number | undefined;
  let currency: Currency | undefined;
  let fractions: number | undefined;

  // 3. convert value to a number

  if (moneyValue) {
    const reNumberSeparator = new RegExp(`\\${NUMBER_SEPARATOR}`, 'g');
    const preparedValue = moneyValue.replace(reNumberSeparator, '').replace(DECIMAL_SEPARATOR, '.');

    fractions = preparedValue.includes('.') ? preparedValue.split('.').pop()?.length ?? 0 : 0;

    number = Number.parseFloat(preparedValue);
  }

  // 4. handle if currency or symbol

  if (currencyOrSymbol) {
    if (isCurrencySymbol(currencyOrSymbol)) {
      currency = getCurrencyForCurrencySymbol(currencyOrSymbol);
    } else {
      currency = currencyOrSymbol as Currency;
    }
  } else {
    // use system currency as default
    currency = ATRIGAM_SYSTEM_CURRENCY;
  }

  // 5. return data

  // only return valid data
  if (!number || !currency) {
    return;
  }

  return { value: number, currency, fractions: fractions ?? 0 };
};
