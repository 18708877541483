import {
  AtrigamConditionsSetOrAndSet,
  AtrigamConditionsSetOrSet,
  AtrigamWorkItem,
} from '../domain';
import { AtrigamUniverseKpi } from '../firestore';

import { isAtrigamConditionsSetOrEmptySet } from './guards/isAtrigamConditionsSetOrEmptySet';
import { validateCondition } from './validateCondition/validateCondition';

interface Options {
  orSet: AtrigamConditionsSetOrSet;
  universeKpisMap?: Record<AtrigamUniverseKpi['id'], AtrigamUniverseKpi['result']>;
  workItem?: Omit<AtrigamWorkItem, 'id'>;
  beforeWorkItem?: Omit<AtrigamWorkItem, 'id'>;
}

export const validateConditionOrSet = ({
  orSet,
  universeKpisMap,
  workItem,
  beforeWorkItem,
}: Options) => {
  return Object.values(orSet)
    .filter((orEmptySet) => !isAtrigamConditionsSetOrEmptySet(orEmptySet))
    .some((andSet) =>
      Object.values(andSet as AtrigamConditionsSetOrAndSet).every((condition) =>
        validateCondition({ condition, universeKpisMap, workItem, beforeWorkItem }),
      ),
    );
};
