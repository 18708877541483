import { AtrigamUser, UID } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { createAtrigamUserPath } from '../paths/createAtrigamUserPath';

interface Options {
  uid: UID;
}

export const updateUserUpdatedAtMutation = async ({ uid }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUserPath({ uid });
  const reference = doc(database, path);
  const updatedAt = getNowFirestoreTimestamp();

  const data: Partial<AtrigamUser> = {
    updatedAt,
  };

  await updateDoc(reference, data);
};
