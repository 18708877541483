import { AnyDateString } from '../../../utilities/date.types';
import { getDateTime } from '../../../utilities/dateUtilities/getDateTime';
import { getISO } from '../../../utilities/dateUtilities/getISO';
import { isDate } from '../../../utilities/guards/isDate';
import { AtrigamDataType } from '../../atrigam-datatypes';
import { ParseDataTypeFunction } from '../../parseDataType.types';

export const parseDataTypeDateTime: ParseDataTypeFunction<AtrigamDataType.DateTime> = ({
  value,
}) => {
  if (typeof value !== 'string' && !isDate(value)) {
    return;
  }

  // at least a full iso date YYYY-MM-DD
  if (typeof value === 'string' && value.length < 10) {
    return;
  }

  const dateTime = getDateTime(value as AnyDateString);
  if (!dateTime.isValid) {
    return;
  }

  return { value: getISO(dateTime) };
};
