import { createMoneyFxFieldName } from '../../domain/helpers/createMoneyFxFieldName';
import { AtrigamInteractionMoneyFX } from '../../domain/taskFlow.interactions.types';
import { AtrigamWorkItem } from '../../domain/workItem.types';

interface Options {
  fieldName: string;
  workItem: Partial<AtrigamWorkItem>;
}

export const getMoneyField = ({ fieldName, workItem }: Options) => {
  const moneyFieldName = createMoneyFxFieldName(fieldName);

  const fxField = moneyFieldName
    ? (workItem[moneyFieldName] as AtrigamInteractionMoneyFX | undefined | null)
    : undefined;

  if (
    !fxField ||
    !fxField.currency ||
    !fxField.systemCurrency ||
    !fxField.rawValue ||
    !fxField.valueInSystemCurrency
  ) {
    return;
  }

  return fxField;
};
