import { reCurrencySymbol } from '../../../../../domain/helpers/findCurrencySymbolInString';

import {
  DECIMAL_SEPARATOR,
  NUMBER_SEPARATOR,
  NumberSeparator,
  getMoneyNumberRegex,
} from './getMoneyNumberRegex';

const reCurrencyStrict = /[A-Z]{3}/;
const reCurrencyLax = /[A-Z]{1,3}/;

const MoneyPrefixedSource = `^\\(?(%CURRENCY%|%CURRENCY_SYMBOL%)?\\)?\\s?(%NUMBER%)$`;
const MoneySuffixedSource = `^(%NUMBER%)\\s?\\(?(%CURRENCY%|%CURRENCY_SYMBOL%)?\\)?$`;

interface Options {
  moneyString: string;
  strict?: boolean;
}

/**
 * extracts the number and the currency out of a string, either prefixed or suffixed
 *
 * also handles cases where the number is in english format and returns it in the german one (default)
 *
 * @returns either extracted data or undefined
 */
export const extractMoneyAndCurrency = ({ moneyString, strict = true }: Options) => {
  // German = default
  let matchedPrefix = matchPrefix({ moneyString, strict, numberSeparator: NumberSeparator.DE });
  if (matchedPrefix) {
    if (strict) {
      return handleOnlyNumberSeparator(matchedPrefix);
    }
    return matchedPrefix;
  }

  let matchedSuffix = matchSuffix({ moneyString, strict, numberSeparator: NumberSeparator.DE });
  if (matchedSuffix) {
    if (strict) {
      return handleOnlyNumberSeparator(matchedSuffix);
    }
    return matchedSuffix;
  }

  // English
  matchedPrefix = matchPrefix({ moneyString, strict, numberSeparator: NumberSeparator.EN });
  if (matchedPrefix) {
    return translateENtoDE(matchedPrefix);
  }

  matchedSuffix = matchSuffix({ moneyString, strict, numberSeparator: NumberSeparator.EN });
  if (matchedSuffix) {
    return translateENtoDE(matchedSuffix);
  }

  return;
};

const reNumberSeparator = new RegExp(`\\${NUMBER_SEPARATOR}`, 'g');
const reDecimalSeparator = new RegExp(`\\${DECIMAL_SEPARATOR}`, 'g');
const TEMPORARY_SEPARATOR = 'T';
const reTemporarySeparator = new RegExp(`\\${TEMPORARY_SEPARATOR}`, 'g');

/**
 * handles cases where there is only a number separator and no decimal:
 * f.e. "1.023" which can be either 1023 or 1,023 (one with decimals)
 *
 * we decided if there is only a number separator, the user wanted to enter a decimal we change it to german variation.
 * f.e. "0.123(USD)" is a fraction of one (elmos example)
 */
const handleOnlyNumberSeparator = ({
  value,
  currencyOrSymbol,
}: {
  value: string;
  currencyOrSymbol: string;
}) => {
  if (value.match(reDecimalSeparator) !== null) {
    return {
      value,
      currencyOrSymbol,
    };
  }

  const match = value.match(reNumberSeparator);
  let handledValue = value;
  if (match !== null && match.length === 1) {
    handledValue = value.replace(reNumberSeparator, DECIMAL_SEPARATOR);
  }

  return {
    value: handledValue,
    currencyOrSymbol,
  };
};

/**
 * we extracted the value using the english notation and need to transform to the german one for the rest of atrigam to work.
 */
const translateENtoDE = ({
  value,
  currencyOrSymbol,
}: {
  value: string;
  currencyOrSymbol: string;
}) => ({
  value: value
    .replace(reNumberSeparator, TEMPORARY_SEPARATOR)
    .replace(reDecimalSeparator, NUMBER_SEPARATOR)
    .replace(reTemporarySeparator, DECIMAL_SEPARATOR),
  currencyOrSymbol,
});

interface MatchOptions {
  moneyString: string;
  strict: boolean;
  numberSeparator: NumberSeparator;
}

const matchPrefix = ({ moneyString, numberSeparator, strict }: MatchOptions) => {
  const match = matchString({
    moneyString,
    source: MoneyPrefixedSource,
    strict,
    numberSeparator,
  });

  if (match) {
    return {
      value: match[2],
      currencyOrSymbol: match[1],
    };
  }

  return;
};

const matchSuffix = ({ moneyString, numberSeparator, strict }: MatchOptions) => {
  const match = matchString({
    moneyString,
    source: MoneySuffixedSource,
    strict,
    numberSeparator,
  });

  if (match) {
    return {
      value: match[1],
      currencyOrSymbol: match[2],
    };
  }

  return;
};

const matchString = ({
  moneyString,
  source,
  strict,
  numberSeparator,
}: MatchOptions & {
  source: string;
}) => {
  const reNumber = getMoneyNumberRegex({ strict, numberSeparator });
  const reNumberSource = reNumber.source.replace('^(', '').replace(')$', '');

  const reSource = source
    .replace('%NUMBER%', reNumberSource)
    .replace('%CURRENCY%', strict ? reCurrencyStrict.source : reCurrencyLax.source)
    .replace('%CURRENCY_SYMBOL%', reCurrencySymbol.source);

  const regex = new RegExp(reSource);
  const match = moneyString.match(regex);

  return match;
};
