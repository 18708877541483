import { UnhandledCaseError } from '../../utilities/UnhandledCaseError';
import { AtrigamEnvironment } from '../universe.types';

export const isAtrigamEnvironment = (environment: unknown): environment is AtrigamEnvironment => {
  if (typeof environment !== 'string') {
    return false;
  }

  try {
    const environment_ = environment as AtrigamEnvironment;
    switch (environment_) {
      case AtrigamEnvironment.Modeler:
      case AtrigamEnvironment.Production:
      case AtrigamEnvironment.Staging:
      case AtrigamEnvironment.Testing: {
        return true;
      }

      default: {
        throw new UnhandledCaseError(environment_);
      }
    }
  } catch {}

  return false;
};
