import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfigVersion } from '../../extension-config-base.types';

import { AtrigamFlowExtensionAutoId_2_1 } from './autoId.2.1.extensions.types';
import { processPreviewAutoId_2_1 } from './helpers/processPreviewAutoId.2.1';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AtrigamFlowExtensionConfigAutoId_2_1: AtrigamFlowExtensionConfigVersion<AtrigamFlowExtensionAutoId_2_1> =
  {
    extensionType: AtrigamFlowExtensionTypes.AutoId,
    version: '2.1',
    description:
      'Generate an ID according to predefined patterns upon creation of a new work item.',
    example: 'INV-202005-0008',
    settings: {
      field: {
        __fieldType: 'defaultField',
        required: true,
      },
      description: {
        __fieldType: 'description',
        required: false,
      },
      patterns: {
        __fieldType: 'conditionsObject',
        name: 'patterns',
        conditions: true,
        defaultValue: '[auto-id]',
        required: true,
        processValue: (pattern) => {
          if (!pattern.includes('[auto-id]')) {
            return pattern + '-[auto-id]';
          }
          return pattern;
        },
      },
      zerofill: {
        __fieldType: 'additionalField',
        name: 'zerofill',
        label: 'zero fill',
        type: AtrigamDataType.Boolean,
        required: false,
        styles: {
          sm: 4,
        },
      },
      zerofillLength: {
        __fieldType: 'additionalField',
        name: 'zerofillLength',
        label: 'Zerofill Length',
        type: AtrigamDataType.Number,
        required: false,
        styles: {
          sm: 4,
        },
      },
      startAt: {
        __fieldType: 'additionalField',
        name: 'startAt',
        label: 'Start At',
        type: AtrigamDataType.Number,
        default: '0',
        required: true,
        styles: {
          sm: 4,
        },
      },
    },
    upgrade: [
      {
        from: '1.0',
        settingsMap: {
          zerofill: 'zerofill',
          zerofillLength: 'length',
        },
      },
      {
        from: '2.0',
        settingsMap: {
          zerofill: 'zerofill',
          zerofillLength: 'zerofillLength',
          startAt: 'startAt',
        },
      },
    ],
    processPreview: processPreviewAutoId_2_1,
    availableFields: {
      additionalValues: ['year', 'month', 'data'],
      fieldTypes: [AtrigamDataType.String],
    },
    prepareForSaving: ({ extension }) => {
      if (!extension.config.type) {
        extension.config.type = AtrigamDataType.String;
      }
    },
  };
