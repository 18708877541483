import { Drawer, DrawerProps } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../../../themes/Themes.types';

const drawerWidth = 240;

export const NavigationWrapper = styled(Drawer)<ThemedStyledComponent>`
  flex-shrink: 0;
  white-space: nowrap;
  left: 0;
  width: ${drawerWidth}px;
  z-index: 1205;

  // animation
  ${({ theme }: ThemedStyledComponent) => css`
    transition: ${theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })};
    width: ${drawerWidth}px;

    & > .MuiDrawer-paper {
      transition: ${theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })};
      width: ${drawerWidth}px;
    }
  `};

  ${({ open }: ThemedStyledComponent & DrawerProps) => {
    if (open) {
      return css`
        & > .MuiDrawer-paper {
          left: 0;
        }

        width: ${drawerWidth}px;
      `;
    }

    return css`
      & > .MuiDrawer-paper {
        /* overflow-x: hidden; */
        left: -340px;
      }

      width: 0;
    `;
  }}
`;
