import {
  ATRIGAM_PROFILE_ENVIRONMENT,
  ATRIGAM_PROFILE_UNIVERSE_AREA_FLOW,
} from '@atrigam/atrigam-types';

import { Registry } from '../../services/Registry/Registry';
import { RouteScope } from '../../services/Router/Router.types';
import { createRedirect } from '../../services/Router/helpers/createRedirect';
import { createRedirectRoute } from '../../services/Router/helpers/createRedirectRoute';
import { Parameters } from '../editWorkItem/editWorkItem.path';

import { pattern } from './userProfile.path';

export const UserProfileRoute = createRedirectRoute({
  pattern,
  scope: RouteScope.LoggedIn,
  onRedirect: () => {
    const userStore = Registry.get('userStore');

    return createRedirect<Parameters, undefined>({
      name: 'EditWorkItemRoute',
      params: {
        environment: ATRIGAM_PROFILE_ENVIRONMENT,
        universeAreaTaskFlow: ATRIGAM_PROFILE_UNIVERSE_AREA_FLOW,
        node: userStore.userOrFail.profileNode!,
      },
    });
  },
});
