import { AtrigamInteractionImage } from '@atrigam/atrigam-types';

import { getSmallestImageVariationLocation } from '../helpers/getSmallestImageVariationLocation';

import { useUniverseDataStorageUrl } from './useUniverseDataStorageUrl';

interface Options {
  image?: AtrigamInteractionImage;
}

export const useSmallestImageUrl = ({ image }: Options) => {
  return useUniverseDataStorageUrl({ location: getSmallestImageVariationLocation(image) });
};
