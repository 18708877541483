import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AtrigamAnalyticEvents,
  AtrigamAnalyticScreens,
  track,
  updateTracking,
} from '@atrigam/atrigam-tracking';
import {
  AtrigamInteractionImage,
  throwIfNullable,
  type AtrigamUser,
  type URI,
} from '@atrigam/atrigam-types';
import {
  getUniverseDataDownloadURL,
  isPlatformAdminQuery,
  watchUser,
} from '@atrigam/server-functions-eu-client';
import { action, computed, makeObservable, observable, when } from 'mobx';
import { persist } from 'mobx-persist';
import { list, object, primitive } from 'serializr';

import { createAcronymForUser } from '../../helpers/createAcronymForUser';
import { getName } from '../../helpers/getName';
import { getSmallestImageVariationLocation } from '../../helpers/getSmallestImageVariationLocation';
import { hasDateChanged } from '../../helpers/hasDateChanged';
import { logger } from '../../helpers/logger';
import { logout } from '../../helpers/logout';
import { createPersistSchema } from '../../helpers/mobx/createPersistSchema';
import { firestoreTimestamp } from '../../helpers/mobx/decorators/firestoreTimestamp';
import { updateObject } from '../../helpers/updateObject';
import { Registry } from '../../services/Registry/Registry';
import { sentry } from '../../services/Sentry/helpers/initializeSentry';

import { UserClientRolesEntity } from './entities/UserClientRoles.entity';
import { persistUserStore } from './helpers/persistUserStore';
import { syncUserClientRoles } from './helpers/syncUserClientRoles';
import { watchAuthState } from './helpers/watchAuthState';
import { watchUserUpdatedAt } from './helpers/watchUserUpdatedAt';

const userSchema = createPersistSchema<Partial<AtrigamUser>>({
  clientUniverses: list(primitive()),
  company: true,
  createdAt: firestoreTimestamp(),
  email: true,
  firstname: true,
  isEmailVerified: true,
  jobtitle: true,
  lastname: true,
  lastSeenOnline: firestoreTimestamp(),
  lastSignedIn: firestoreTimestamp(),
  lastUsedClient: true,
  modelerUniverses: list(primitive()),
  phoneNumber: true,
  profileNode: true,
  profilePicture: object(
    createPersistSchema<AtrigamUser['profilePicture']>({
      profilePicture: object(
        createPersistSchema<AtrigamInteractionImage>({
          id: true,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          fb_storage_location: true,
          midloc: true,
          minloc: true,
          thumbloc: true,
        }),
      ),
    }),
  ),
  profileUpdatedAt: firestoreTimestamp(),
  pushToken: true,
  salutation: true,
  termsAndConditionsAccepted: true,
  tutorialDone: true,
  uid: true,
  updatedAt: firestoreTimestamp(),
  verificationLinkResent: true,
  verificationLinkResentAt: firestoreTimestamp(),
  verifyEmailLater: firestoreTimestamp(),
});

export class UserStore extends DestructibleService {
  static PERSISTANCE_VERSION = 12;

  @persist('object', userSchema)
  @observable
  user?: AtrigamUser;

  @persist('object', UserClientRolesEntity)
  @observable
  userClientRoles = new UserClientRolesEntity();

  @persist
  @observable
  persistedVersion?: number;

  @persist('object', userSchema)
  @observable
  impersonator?: AtrigamUser;

  @observable
  isInitialized = false;

  @observable
  isChangingLoginState = false;

  @observable
  isPlatformAdmin = false;

  @observable
  avatarUri?: URI;

  @observable
  private unsubscribeUserWatcher?: () => void;

  constructor() {
    super();

    makeObservable(this);

    void persistUserStore(this);
    watchAuthState(this);
    watchUserUpdatedAt(this);
  }

  @computed
  get uid() {
    throwIfNullable('user.uid cannot be undefined', this.user?.uid);
    return this.user.uid;
  }

  @computed
  get userName() {
    if (!this.user) {
      return '';
    }

    return getName(this.user);
  }

  @computed
  get userInitials() {
    return createAcronymForUser(this.user ?? {});
  }

  @computed
  get isAuthenticated() {
    return this.user?.uid !== undefined;
  }

  @computed
  get isImpersonating() {
    return this.impersonator !== undefined;
  }

  @computed
  get userOrFail() {
    throwIfNullable('user cannot be undefined', this.user);
    return this.user;
  }

  @action
  setUser = async ({
    user,
    impersonating = false,
  }: {
    user: AtrigamUser;
    impersonating?: boolean;
  }) => {
    // add missing optionals
    if (!user.clientUniverses) {
      user.clientUniverses = [];
    }
    if (!user.modelerUniverses) {
      user.modelerUniverses = [];
    }
    this.user = user;
    this.persistedVersion = UserStore.PERSISTANCE_VERSION;

    await this.validatePlatformAdmin();
    await this.updateAvatar();

    // currently the client roles are already synching, so wait till they are done
    if (this.userClientRoles.isSynching) {
      await when(() => !this.userClientRoles.isSynching);
    }

    // clear the roles before starting to sync
    this.userClientRoles.clear();
    await syncUserClientRoles(this);

    sentry.setUser({
      id: this.uid,
      email: this.user?.email,
      username: getName(this.user ?? {}),
      phoneNumber: this.user?.phoneNumber,
    });

    updateTracking({
      uid: this.uid,
      user: {
        avatar: this.avatarUri ?? null,
        email: this.user?.email ?? null,
        firstname: this.user?.firstname ?? null,
        lastname: this.user?.lastname ?? null,
        phoneNumber: this.user?.phoneNumber ?? null,
        isTestUser: this.user?.isTestUser ?? null,
      },
    });

    if (!impersonating) {
      Registry.get('pushNotifications').setUser({
        uid: this.uid,
      });
    }

    this.startWatchers();
  };

  @action
  impersonateUser = async (user: AtrigamUser) => {
    if (this.isImpersonating || !this.isPlatformAdmin) {
      return;
    }

    this.isInitialized = false;
    this.stopWatchers();

    this.impersonator = this.userOrFail;
    updateTracking({
      isImpersonated: true,
      isImpersonatedBy: {
        avatar: this.avatarUri ?? null,
        email: this.userOrFail.email ?? null,
        firstname: this.userOrFail.firstname ?? null,
        lastname: this.userOrFail.lastname ?? null,
        phoneNumber: this.userOrFail.phoneNumber ?? null,
        isTestUser: this.userOrFail.isTestUser ?? null,
      },
    });

    await this.setUser({ user, impersonating: true });
    this.isInitialized = true;
  };

  @action
  setChangingLoginState = (changing: boolean) => {
    this.isChangingLoginState = changing;
  };

  @action
  stopImpersonate = async () => {
    if (!this.impersonator) {
      return;
    }

    this.isInitialized = false;
    this.stopWatchers();

    updateTracking({
      isImpersonated: false,
      isImpersonatedBy: undefined,
    });

    await this.setUser({ user: this.impersonator });
    this.impersonator = undefined;

    this.isInitialized = true;
  };

  @action
  startWatchers = () => {
    // stop watching before we start
    this.stopWatchers();

    this.unsubscribeUserWatcher = watchUser({
      uid: this.uid,
      onUpdate: (user) => {
        if (!user) {
          if (this.isChangingLoginState) {
            return;
          }

          logger.log('no user. Logging out...');

          // also track user has been forced logout
          void track({
            event: AtrigamAnalyticEvents.NAVIGATION_UserHasBeenDeletedAndForcedLogout,
            screen: AtrigamAnalyticScreens.Navigation,
          });

          Registry.get('snackbar').addNotification({
            message: translate('app.user.invalidSession'),
            options: {
              variant: 'error',
              persist: false,
            },
          });

          void logout({});
          return;
        }

        this.updateUser({ user });
      },
      onError: () => {
        if (Registry.get('userStore').isChangingLoginState) {
          return false;
        }

        return true;
      },
    });
  };

  @action
  stopWatchers = () => {
    if (this.unsubscribeUserWatcher) {
      this.unsubscribeUserWatcher();
    }

    this.unsubscribeUserWatcher = undefined;
  };

  @action
  clearUser = async () => {
    this.stopWatchers();

    // this will also persist
    this.user = undefined;
    this.avatarUri = undefined;
    this.isPlatformAdmin = false;
    this.impersonator = undefined;
    this.persistedVersion = undefined;

    this.userClientRoles.clear();

    sentry.setUser(null);
    updateTracking({
      uid: 'unregistered',
      user: undefined,
      isImpersonated: false,
      isImpersonatedBy: undefined,
    });
    await Registry.get('pushNotifications').clearUser();
  };

  @action
  updateAvatar = async () => {
    const location = getSmallestImageVariationLocation(this.user?.profilePicture?.profilePicture);

    if (location) {
      this.avatarUri = await getUniverseDataDownloadURL(location);
    }
  };

  @action
  updateUser = ({ user, forceUpdate = false }: { user: AtrigamUser; forceUpdate?: boolean }) => {
    if (!this.user) {
      return;
    }

    if (
      !forceUpdate &&
      !hasDateChanged(this.user.updatedAt, user.updatedAt) &&
      !hasDateChanged(this.user.profileUpdatedAt, user.profileUpdatedAt)
    ) {
      return;
    }

    updateObject({ target: this.user, source: user });
    this.persistedVersion = UserStore.PERSISTANCE_VERSION;
    void this.validatePlatformAdmin();
  };

  @action
  setInitializationFinished = () => {
    this.isInitialized = true;
  };

  @action
  startingInitialization = () => {
    this.isInitialized = false;
  };

  @action
  validatePlatformAdmin = async () => {
    if (!this.isAuthenticated || !this.user) {
      this.isPlatformAdmin = false;
      return;
    }

    this.isPlatformAdmin = await isPlatformAdminQuery({ uid: this.uid });
  };
}
