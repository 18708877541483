import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamObjectSubscriptionPath,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  createAtrigamDatabaseUniverseEnvironment,
} from '@atrigam/atrigam-types';

interface Options {
  environment: AtrigamEnvironment;
  id: string;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const createAtrigamObjectSubscriptionPath = ({
  environment,
  id,
  objectName,
  universe,
}: Options): AtrigamObjectSubscriptionPath => {
  const databaseUniverseEnvironment = createAtrigamDatabaseUniverseEnvironment({
    universe,
    environment,
  });

  return `/universes/${databaseUniverseEnvironment}/objects/${objectName}/objectSubscriptions/${id}`;
};
