import { UnhandledCaseError } from '@atrigam/atrigam-types';
import {
  create,
  evaluateDependencies,
  maxDependencies,
  minDependencies,
  meanDependencies,
  sumDependencies,
  addDependencies,
  divideDependencies,
  multiplyDependencies,
  subtractDependencies,
} from 'mathjs';

import { AggregationMethod } from '../aggregatedValue.1.0.processor.types';

const mathjs = create({
  evaluateDependencies,
  maxDependencies,
  minDependencies,
  meanDependencies,
  sumDependencies,
  addDependencies,
  divideDependencies,
  multiplyDependencies,
  subtractDependencies,
});

interface Options {
  method: AggregationMethod;
  pattern: string;
  values: number[];
}

export const calculateMathExpression = ({ method, pattern, values }: Options) => {
  try {
    switch (method) {
      case AggregationMethod.Count: {
        return values.length;
      }

      case AggregationMethod.Evaluate: {
        return mathjs.evaluate(pattern) as number | undefined;
      }

      case AggregationMethod.Max: {
        return mathjs.max(values) as number | undefined;
      }

      case AggregationMethod.Mean: {
        return mathjs.mean(values) as number | undefined;
      }

      case AggregationMethod.Min: {
        return mathjs.min(values) as number | undefined;
      }

      case AggregationMethod.Sum: {
        return mathjs.sum(values);
      }

      default: {
        throw new UnhandledCaseError(method);
      }
    }
  } catch {}
  return;
};
