import {
  AtrigamChatMessage,
  AtrigamChatMessageUser,
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
  createNewSystemISODateTime,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamChatMessageListPath } from '../paths/createAtrigamChatMessageListPath';

interface Options {
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  message: string;
  user: AtrigamChatMessageUser;
}

export const addChatMessageMutation = async ({
  environment,
  node,
  objectName,
  universe,
  message,
  user,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));
  const path = createAtrigamChatMessageListPath({
    environment,
    node,
    objectName,
    universe,
  });
  const reference = doc(collection(database, path));

  const id = reference.id;
  const createdAt = createNewSystemISODateTime();

  const data: AtrigamChatMessage = {
    _id: id,
    createdAt,
    text: message,
    user,
  };

  await setDoc(reference, mapUndefinedToNullValue(data));

  return id;
};
