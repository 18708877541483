import {
  AtrigamEnvironment,
  AtrigamFirestoreUniversesEnvironmentObjectsFlow,
  AtrigamObjectName,
  AtrigamUniverseName,
} from '@atrigam/atrigam-types';

import { createAtrigamFirestoreUniversesEnvironmentObjectsFlowPath } from '../firestore/paths/createAtrigamFirestoreUniversesEnvironmentObjectsFlowPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
}

export const watchFlowObject: WatcherFunction<
  AtrigamFirestoreUniversesEnvironmentObjectsFlow,
  Options
> = ({ environment, objectName, onUpdate, onError, universe }) => {
  const firestoreService = watcherServiceEu.get();

  const path = createAtrigamFirestoreUniversesEnvironmentObjectsFlowPath({
    environment,
    objectName,
    universe,
  });

  return firestoreService.subscribeToDocument<AtrigamFirestoreUniversesEnvironmentObjectsFlow>({
    path,
    onSnapshot: (snapshot, key) => {
      const data = snapshot.data();
      void onUpdate(data, key);
    },
    onError,
  });
};
