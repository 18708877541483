import { PushNotificationPlaygroundPageAsync } from '../../pages/PushNotificationPlayground/PushNotificationPlayground.page.async';
import { RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './pushNotificationPlayground.path';

export const PushNotificationPlaygroundRoute = createRoute({
  title: () => translate('routes.pushNotificationPlayground.title'),
  components: {
    main: PushNotificationPlaygroundPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
});
