import { DashboardOutlined } from '@mui/icons-material';
import React from 'react';

import { reactive } from '../../../../../../helpers/reactive';
import { NavigationItem } from '../../../../../../primitive/NavigationItem/NavigationItem';
import { FlowsOverviewPath } from '../../../../../../routes/flowsOverview/flowsOverview.path';
import { HomePath } from '../../../../../../routes/home/home.path';

const FlowOverviewNavigationItemComponent: React.FC = () => {
  return (
    <NavigationItem
      to={HomePath.getLink()}
      activeRoutes={[HomePath, FlowsOverviewPath]}
      icon={<DashboardOutlined />}
      label={translate(
        'layouts.DefaultLayout.Navigation.navigationItems.FlowOverviewNavigationItem.Overview',
      )}
      testId="navigation-item-overview"
    />
  );
};

export const FlowOverviewNavigationItem = reactive(FlowOverviewNavigationItemComponent);
