import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamSubscriptionState,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUserSubscription,
  FirestoreTimestamp,
  UID,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  QuerySnapshot,
  collection,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../clientSDK.constants';
import { createAtrigamUserSubscriptionListPath } from '../firestore/paths/createAtrigamUserSubscriptionListPath';
import { watcherServiceEu } from '../watcherService';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  limit: number;
  onError?: (error: Error) => boolean;
  onSnapshot: (querySnapshot: QuerySnapshot<AtrigamUserSubscription>, key: string) => void;
  startAfter?: FirestoreTimestamp;
  subscriptionState?: AtrigamSubscriptionState;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

/**
 * This is different. This is using
 */
export const subscribeToUserSubscriptions = ({
  area,
  environment,
  limit: limit_,
  onError,
  onSnapshot: onSnapshot_,
  startAfter: startAfter_,
  subscriptionState,
  taskFlow,
  uid,
  universe,
}: Options) => {
  const firestoreService = watcherServiceEu.get();

  const storeApp = getApp(APP_NAME_EU);
  const path = createAtrigamUserSubscriptionListPath({
    area,
    environment,
    taskFlow,
    uid,
    universe,
  });

  const reference = collection(getFirestore(storeApp), path);
  let query_ = subscriptionState
    ? query(reference, where('subscriptionState', '==', subscriptionState))
    : reference;

  query_ = query(query_, orderBy('invitedAt', 'desc'), limit(limit_));

  if (startAfter_) {
    query_ = query(query_, startAfter(startAfter_));
  }

  const key = `${path}:${subscriptionState ?? 'unknown'}:${limit_}`;

  const unsubscribe = onSnapshot(
    query_,
    (snapshotCollection) => {
      onSnapshot_(snapshotCollection as QuerySnapshot<AtrigamUserSubscription>, key);
    },
    (error) => {
      if (onError && !onError(error)) {
        return;
      }
    },
  );

  firestoreService.subscribe({ unsubscribe, key, path });

  return () => {
    firestoreService.unsubscribe(key);
  };
};
