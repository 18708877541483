import { AtrigamFirestoreUserNotificationsUser, throwIfNullable } from '@atrigam/atrigam-types';
import {
  getUserNotificationListQuery,
  watchUserNotifications,
} from '@atrigam/server-functions-eu-client';

import { Registry } from '../../../services/Registry/Registry';
import { UserNotificationsStore } from '../UserNotifications.store';

import { warmupFirestoreCache } from './warmupFirestoreCache';

export const setupUserNotificationsWatcher = (store: UserNotificationsStore) => {
  throwIfNullable('User UID cannot be undefined', store.uid);

  return watchUserNotifications({
    uid: store.uid,
    onUpdate: async (notificationsRoot?: AtrigamFirestoreUserNotificationsUser) => {
      if (!notificationsRoot) {
        return;
      }

      store.setUnreadCount(notificationsRoot.unreadCount ?? 0);
      store.setLastNotificationSent(notificationsRoot.lastNotificationSent);
      store.setUpdatedAt(notificationsRoot.updatedAt);

      if (!store.shouldUpdateNotifications) {
        return;
      }

      const notifications = await getUserNotificationListQuery({
        uid: notificationsRoot.uid,
      });

      await warmupFirestoreCache({ notifications, uid: notificationsRoot.uid });
      store.updateNotifications(notifications);
    },
    onError: () => {
      if (Registry.get('userStore').isChangingLoginState) {
        return false;
      }

      return true;
    },
  });
};
