import { alpha } from '@mui/material/styles';
import UppyCoreStyles from '@uppy/core/dist/style.css?inline';
import UppyDashboardStyles from '@uppy/dashboard/dist/style.css?inline';
import UppyWebcamStyles from '@uppy/webcam/dist/style.css?inline';
import { createGlobalStyle, css } from 'styled-components';

import { ThemedStyledComponent } from '../../../themes/Themes.types';

export const GlobalFileUploadStyle = createGlobalStyle<ThemedStyledComponent>(
  ({ theme }) => css`
    ${UppyCoreStyles};
    ${UppyDashboardStyles};
    ${UppyWebcamStyles};

    .uppy-Root {
      z-index: ${theme.zIndex.modal};
    }

    .uppy-Root,
    .uppy-StatusBar-content,
    .uppy-Dashboard-AddFiles-title {
      color: ${theme.palette.text.primary};
    }

    .uppy-Dashboard-inner,
    .uppy-StatusBar,
    .uppy-DashboardContent-panel,
    .uppy-DashboardContent-bar {
      background-color: ${theme.palette.background.default};
    }

    .uppy-Dashboard-browse,
    .uppy-DashboardContent-back,
    .uppy-DashboardContent-addMore,
    .uppy-ProviderBrowser-userLogout {
      color: ${theme.palette.primary.main};

      &:hover {
        color: ${theme.palette.primary.main};
      }
    }

    .uppy-Dashboard-browse {
      &:hover,
      &:focus {
        border-color: ${theme.palette.primary.main};
      }
    }

    .uppy-DashboardTab-btn {
      color: ${theme.palette.text.secondary};

      &:hover,
      &:focus {
        color: ${theme.palette.mode === 'light'
          ? theme.palette.text.primary
          : theme.palette.primary.contrastText};
      }
    }

    .uppy-DashboardItem.is-complete .progress {
      stroke: ${theme.palette.success.main};
      fill: ${theme.palette.success.main};
    }

    .uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
      color: ${theme.palette.success.main};
    }

    .uppy-StatusBar-actionBtn--done {
      color: ${theme.palette.primary.main};
      background: none;

      &:hover {
        text-decoration: none;
        background-color: ${alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)};
        color: ${theme.palette.primary.main};
      }
    }

    .uppy-StatusBar-actionBtn--retry {
      background-color: ${theme.palette.error.main};
    }

    .uppy-StatusBar-spinner {
      fill: ${theme.palette.primary.main};
    }

    .uppy-StatusBar {
      .uppy-StatusBar-statusIndicator {
        color: ${theme.palette.primary.main};
      }

      &.is-complete .uppy-StatusBar-statusIndicator {
        color: ${theme.palette.success.main};
      }

      &.is-error .uppy-StatusBar-statusIndicator {
        color: ${theme.palette.error.main};
      }
    }

    .uppy-StatusBar {
      &::before {
      }

      .uppy-StatusBar-progress {
        background-color: ${theme.palette.primary.main};
      }

      &.is-complete {
        .uppy-StatusBar-progress {
          background-color: ${theme.palette.success.main};
        }
      }

      &.is-error {
        .uppy-StatusBar-progress {
          background-color: ${theme.palette.error.main};
        }
      }
    }

    .uppy-Webcam-container {
      .uppy-Webcam-button {
        background-color: ${theme.palette.error.main};
      }

      .uppy-Webcam-recordingLength {
        color: ${theme.palette.text.secondary};
      }
    }
  `,
);
