import { AtrigamFlowExtensionTypes } from '../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfig } from '../extension-config-base.types';

import { AtrigamFlowExtensionConfigAggregatedValue_1_0 } from './1.0/aggregatedValue.1.0.extensions.config';
import { AtrigamFlowExtensionAggregatedValue } from './aggregatedValue.extensions.types';

export const AtrigamFlowExtensionConfigAggregatedValue: AtrigamFlowExtensionConfig<AtrigamFlowExtensionAggregatedValue> =
  {
    extensionType: AtrigamFlowExtensionTypes.AggregatedValue,
    name: 'Aggregation',
    acronym: '⨋',
    materialUiIcon: 'functions',
    color: '#d899d1',
    atrigamTeamMembersOnly: true,
    versions: [AtrigamFlowExtensionConfigAggregatedValue_1_0],
  };
