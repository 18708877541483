import {
  AtrigamEnvironment,
  AtrigamUniverseName,
  AtrigamWorkItem,
  ISODateTime,
  AtrigamWorkItemId,
  parseDataType,
  AtrigamDataType,
} from '@atrigam/atrigam-types';

import { extractFieldsFromPattern } from '../../../../helpers/extractFieldsFromPattern';
import { AtrigamFlowExtensionAggregatedValue_1_0 } from '../aggregatedValue.1.0.extensions.types';
import { GetWorkItemQueryFunction } from '../aggregatedValue.1.0.processor.types';

interface Options {
  environment: AtrigamEnvironment;
  extension: AtrigamFlowExtensionAggregatedValue_1_0;
  pattern: string;
  universe: AtrigamUniverseName;
  workItem: AtrigamWorkItem;

  getWorkItemQuery: GetWorkItemQueryFunction;
}

type WorkItemChildItems = Record<string, { updatedAt: ISODateTime }>;

export const getAggregationValues = async ({
  environment,
  extension,
  getWorkItemQuery,
  pattern,
  universe,
  workItem,
}: Options) => {
  const aggregationFields = extractFieldsFromPattern({
    pattern,
  });
  const aggregationValues: number[] = [];

  const objectName = extension.config.childItemObject;
  let aggregationChildren: AtrigamWorkItemId[] = [];

  if (extension.config.childItemField && workItem[extension.config.childItemField]) {
    aggregationChildren = Object.keys(
      workItem[extension.config.childItemField] as WorkItemChildItems,
    ) as AtrigamWorkItemId[];

    await Promise.all(
      aggregationChildren.map(async (node) => {
        const childWorkItem = await getWorkItemQuery({
          universe,
          environment,
          objectName,
          node,
        });

        if (!childWorkItem) {
          return;
        }

        if (aggregationFields) {
          aggregationFields.forEach((aggregationField) => {
            if (childWorkItem[aggregationField]) {
              const value = parseDataType({
                dataType: AtrigamDataType.Number,
                value: childWorkItem[aggregationField],
              });

              if (value) {
                aggregationValues.push(value.value);
              }
            }
          });
        } else {
          // aggregationValues.push(childWorkItem.id);
          aggregationValues.push(1); // only usefuly if count is what we want
        }
      }),
    );
  }

  return aggregationValues;
};
