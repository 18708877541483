import React from 'react';
import { FallbackProps } from 'react-error-boundary';

import { reactive } from '../../../../helpers/reactive';

// eslint-disable-next-line import/no-unassigned-import
import './ErrorFallback.css';
import { getWithExpiry } from './helpers/getWithExpiry';
import { setWithExpiry } from './helpers/setWithExpiry';

const ErrorFallbackComponent: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  // Handle failed lazy loading of a JS/CSS chunk.
  React.useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    try {
      if (
        error &&
        typeof error === 'object' &&
        error !== null &&
        (error as Error).message &&
        chunkFailedMessage.test((error as Error).message) &&
        !getWithExpiry({ key: 'chunk_failed' })
      ) {
        setWithExpiry({ key: 'chunk_failed', value: 'true', ttl: 10_000 });
        window.location.reload();
      }
    } catch {}
  }, [error]);

  return (
    <div className="ErrorContainer">
      <div className="ErrorContainer-Outer">
        <div className="ErrorContainer-Paper">
          <div className="ErrorContainer-Logo">
            <img src="/logo.svg" alt="logo" height="30" width="90" />
          </div>

          <p>
            <strong>Oops! An error occured!</strong>
          </p>

          <p>
            A crash report has been sent to the developers. They are on it and fix the bug as soon
            as possible.
          </p>

          <p>
            If you feel like joining in, contact us at:{' '}
            <a href="https://atrigam.com/contact">https://atrigam.com/contact</a>
          </p>

          <div className="ErrorContainer-Flex ErrorContainer-MarginTop">
            <p>
              With a bit of luck, you can &nbsp;&nbsp;
              <button
                color="primary"
                onClick={() => window.location.reload()}
                className="ErrorContainer-Button"
              >
                Retry
              </button>
            </p>

            <p className="ErrorContainer-TextRight">
              Or you can try to &nbsp;&nbsp;
              <button
                color="primary"
                onClick={resetErrorBoundary}
                className="ErrorContainer-Button"
              >
                Log in again
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ErrorFallback = reactive(ErrorFallbackComponent);
