import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamModelsModel,
  AtrigamTaskFlowName,
  type AtrigamUniverseName,
  type ISODateTime,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable, values } from 'mobx';
import { persist } from 'mobx-persist';

import { watchModel } from '../helpers/watchModel';

import { AreaModelEntity } from './AreaModel.entity';
import { RoleModelEntity } from './RoleModel.entity';
import { TaskFlowModelEntity } from './TaskFlowModel.entity';
import { WorkItemModelEntity } from './WorkItemModel.entity';

interface Options {
  environment?: AtrigamEnvironment;
  data?: AtrigamModelsModel;
}

export class UniverseModelEntity extends DestructibleService {
  @persist('map', AreaModelEntity)
  @observable
  areas = observable.map<AtrigamAreaName, AreaModelEntity>();

  @persist
  @observable
  environment?: AtrigamEnvironment;

  @persist('map', TaskFlowModelEntity)
  @observable
  taskFlowModelList = observable.map<AtrigamTaskFlowName, TaskFlowModelEntity>();

  @persist('map', WorkItemModelEntity)
  @observable
  workItemModels = observable.map<string, WorkItemModelEntity>();

  @persist('list', RoleModelEntity)
  @observable
  roles: RoleModelEntity[] = [];

  @persist
  @observable
  updatedAt?: ISODateTime;

  @persist
  @observable
  universe?: AtrigamUniverseName;

  @persist
  @observable
  _label?: string;

  constructor(options: Options = {}) {
    super();
    makeObservable(this);

    if (options?.environment) {
      this.environment = options.environment;
    }

    if (options?.data) {
      this.update(options.data);
    }

    watchModel(this);
    this.disposers.push(() => this.removeAllFlows());
  }

  @computed
  get flowNames() {
    return [...values(this.taskFlowModelList)]
      .filter((taskFlowModel) => taskFlowModel.taskFlow !== undefined)
      .map((taskFlowModel) => taskFlowModel.taskFlow!);
  }

  @computed
  get label() {
    if (this._label) {
      return this._label;
    }

    return this.universe;
  }

  @action
  removeFlow = (taskFlowName: AtrigamTaskFlowName) => {
    const taskFlowModel = this.taskFlowModelList.get(taskFlowName);
    if (!taskFlowModel) {
      return;
    }

    taskFlowModel._destruct();
    this.taskFlowModelList.delete(taskFlowName);
  };

  @action
  update = (universeModel: AtrigamModelsModel) => {
    this.universe = universeModel.universe;
    this.updatedAt = universeModel.updatedAt;
    this._label = universeModel.label;

    this.areas.clear();
    if (universeModel.areas) {
      Object.entries(universeModel.areas).forEach(([key, areaData]) => {
        this.areas.set(key as AtrigamAreaName, new AreaModelEntity(areaData));
      });
    }

    // we need to clear all flows or the interaction store will not work
    // since it uses the taskFlow directly, any update would automatically
    // populate the interaction store. Need a different strategy.
    this.removeAllFlows();
    if (universeModel.flows) {
      Object.values(universeModel.flows).forEach((taskFlowModel) => {
        const areaModel = this.areas.get(taskFlowModel.area);
        this.taskFlowModelList.set(
          taskFlowModel.flow,
          new TaskFlowModelEntity({
            environment: this.environment,
            flowData: taskFlowModel,
            universeLabel: this.label,
            areaLabel: areaModel ? areaModel.label : undefined,
          }),
        );
      });
    }

    this.workItemModels.clear();
    if (universeModel.objects) {
      Object.entries(universeModel.objects).forEach(([key, object]) => {
        this.workItemModels.set(key, new WorkItemModelEntity(object));
      });
    }

    this.roles = Object.values(universeModel.roles).map((role) => new RoleModelEntity(role));
  };

  @action
  private removeAllFlows = () => {
    values(this.taskFlowModelList).forEach((model) => model._destruct());
    this.taskFlowModelList.clear();
  };
}
