import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfigVersion } from '../../extension-config-base.types';
import { processPreviewAggregatedValue } from '../helpers/processPreviewAggregatedValue';

import { AtrigamFlowExtensionAggregatedValue_1_0 } from './aggregatedValue.1.0.extensions.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AtrigamFlowExtensionConfigAggregatedValue_1_0: AtrigamFlowExtensionConfigVersion<AtrigamFlowExtensionAggregatedValue_1_0> =
  {
    extensionType: AtrigamFlowExtensionTypes.AggregatedValue,
    version: '1.0',
    description: 'Define a field and aggregate the value based on the values of child work items.',
    example: 'SUM(hours)',
    settings: {
      field: {
        __fieldType: 'defaultField',
        // allowedDataTypes: [AtrigamDataType.Money, AtrigamDataType.Number],
        required: true,
      },
      description: {
        __fieldType: 'description',
        required: false,
      },
      type: {
        __fieldType: 'dataTypeSelectField',
        name: 'type',
        unitTarget: 'unit',
        options: [AtrigamDataType.Money, AtrigamDataType.Number],
        required: true,
      },
      child: {
        __fieldType: 'childItemSelect',
        label: 'Sub Item',
        required: true,
        fieldInteraction: 'childItemInteraction',
        fieldObject: 'childItemObject',
        field: 'childItemField',
      },
      aggregations: {
        __fieldType: 'conditionsObject',
        name: 'aggregations',
        conditions: true,
        required: true,
      },
    },
    processPreview: processPreviewAggregatedValue,
  };
