import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamObjectSubscription,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  CollectionReference,
  DocumentData,
  collection,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamObjectSubscriptionListPath } from '../paths/createAtrigamObjectSubscriptionListPath';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  node: AtrigamWorkItemId;
}

export const getObjectSubscriptionListQueryReference = ({
  universe,
  environment,
  objectName,
  node,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamObjectSubscriptionListPath({ environment, objectName, universe });
  const reference = collection(database, path) as CollectionReference<
    AtrigamObjectSubscription,
    DocumentData
  >;
  return query(reference, where('node', '==', node));
};
