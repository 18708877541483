import { AtrigamDataType } from '../../atrigam-datatypes';
import { DATATYPE_FORMAT_NOT_AVAILABLE } from '../../dataTypes.constants';
import { FormatDataTypeFunction } from '../../formatDataType.types';

export const formatDataTypeArray: FormatDataTypeFunction<AtrigamDataType.Array> = ({ value }) => {
  if (!Array.isArray(value)) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  const allValuesAreString = value.every((v) => typeof v === 'string');
  const items = value.length;

  if (!allValuesAreString || items > 5) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  if (items === 0) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  return value.join(', ');
};
