import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { collection, doc, getFirestore } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamWorkItemListPath } from '../paths/createAtrigamWorkItemListPath';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
}

export const createNewWorkItemIdMutation = ({ universe, environment, objectName }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamWorkItemListPath({ environment, objectName, universe });
  const reference = doc(collection(database, path));

  return reference.id as AtrigamWorkItemId;
};
