import { PlaygroundPageAsync } from '../../pages/Playground/Playground.page.async';
import { RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './playground.path';

export const PlaygroundRoute = createRoute({
  title: () => translate('routes.playground.title'),
  components: {
    main: PlaygroundPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
});
