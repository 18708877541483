import { AtrigamObjectSubscription, isAfter } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamObjectSubscriptionPath } from '../paths/createAtrigamObjectSubscriptionPath';

import { updateWorkItemUpdatedAtMutation } from './updateWorkItemUpdatedAt.mutation';

type Options = Required<
  Pick<
    AtrigamObjectSubscription,
    'id' | 'universe' | 'environment' | 'objectName' | 'chatMessageSeen' | 'lastChatMessageSeen'
  >
>;

export const updateObjectSubscriptionChatMetaDataMutation = async ({
  id,
  universe,
  environment,
  objectName,
  chatMessageSeen,
  lastChatMessageSeen,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamObjectSubscriptionPath({ environment, id, objectName, universe });
  const reference = doc(database, path);

  const snapshot = await getDoc(reference);
  if (!snapshot.exists) {
    // TODO throw error?
    return;
  }

  // date would be older than the current one, so stick with it
  const data = snapshot.data()! as AtrigamObjectSubscription;
  if (
    data.lastChatMessageSeen?.createdAt &&
    isAfter(data.lastChatMessageSeen.createdAt, lastChatMessageSeen.createdAt)
  ) {
    return;
  }

  const updatedAt = getNowFirestoreTimestamp();

  const update: Partial<AtrigamObjectSubscription> = {
    chatMessageSeen,
    lastChatMessageSeen,
    updatedAt,
  };

  await updateDoc(reference, mapUndefinedToNullValue(update));

  // change universe Object updatedAt
  await updateWorkItemUpdatedAtMutation({
    universe,
    environment,
    node: data.node,
    objectName,
    uid: data.uid,
  });

  return update;
};
