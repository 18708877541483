import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AvailableExtensionVersionProcessPreviewFunction } from '../../extension-config-base.types';
import { AtrigamFlowExtensionSetValue } from '../set-value.extensions.types';

export const processPreviewSetValue: AvailableExtensionVersionProcessPreviewFunction<
  AtrigamFlowExtensionSetValue
> = (extension) => {
  let values = '';
  let unit = '';

  if (extension.config.values) {
    values = Object.values(extension.config.values).slice(0, 5).join('/');
  }

  if (values === '') {
    values = '?';
  }

  if (
    extension.config.unit && // unit only goes with number
    extension.config.type === AtrigamDataType.Number
  ) {
    unit = ' ' + extension.config.unit;
  }

  return values + unit;
};
