import { FirebaseWatcherService, FirebaseWatcherServiceOptions } from './firebase.watcher.service';

export const FirebaseWatcherHandler = () => {
  let service: FirebaseWatcherService | undefined;

  const initialize = (options: FirebaseWatcherServiceOptions) => {
    if (!service) {
      service = new FirebaseWatcherService(options);
    }
  };

  const get = () => {
    if (service === null || service === undefined) {
      throw new Error(`Service has not been initialized yet!`);
    }

    return service;
  };

  return { initialize, get };
};
