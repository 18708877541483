import {
  AtrigamInteractionCodeScan,
  AtrigamInteractionType,
} from '../../taskFlow.interactions.types';

export const isAtrigamInteractionCodeScan = (data: unknown): data is AtrigamInteractionCodeScan => {
  if (typeof data !== 'object') {
    return false;
  }

  return (data as AtrigamInteractionCodeScan).interaction === AtrigamInteractionType.CodeScan;
};
