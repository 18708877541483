import { AtrigamFlowExtensionTypes } from '../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfig } from '../extension-config-base.types';

import { AtrigamFlowExtensionConfigSetValue_2_0 } from './2.0/set-value.2.0.extensions.config';
import { AtrigamFlowExtensionSetValue } from './set-value.extensions.types';

export const AtrigamFlowExtensionConfigSetValue: AtrigamFlowExtensionConfig<AtrigamFlowExtensionSetValue> =
  {
    extensionType: AtrigamFlowExtensionTypes.SetValue,
    name: 'Set Value',
    acronym: 'SV',
    color: '#e8ddb5',
    versions: [AtrigamFlowExtensionConfigSetValue_2_0],
  };
