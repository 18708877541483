import { UID } from '@atrigam/atrigam-types';

import { RouteParameters } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export interface Parameters extends RouteParameters {
  uid: UID;
}

export const pattern = '/impersonate/:uid';

export const ImpersonatePath = createRoutePath<Parameters>({ pattern });
