import { AtrigamDataType } from '../../atrigam-datatypes';
import { getObjectAsStringOrValue } from '../../helpers/getObjectAsStringOrValue';
import { ParseDataTypeFunction } from '../../parseDataType.types';

export const parseDataTypeString: ParseDataTypeFunction<
  AtrigamDataType.String | AtrigamDataType.Text
> = ({ value: value_ }) => {
  const value = getObjectAsStringOrValue(value_);

  // is a string, return it
  if (typeof value === 'string') {
    return { value };
  }

  // handle undefined and null
  if (value === undefined || value === null) {
    return;
  }

  if (typeof value === 'object' || typeof value === 'function') {
    return;
  }

  return { value: String(value) };
};
