import {
  AtrigamFlowExtensionSettingNames,
  AtrigamFlowExtensionSettingsData,
} from '@atrigam/atrigam-extensions';
import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { getDoc } from 'firebase/firestore';

import { getExtensionSettingsQueryReference } from '../queryReferences/getExtensionSettings.query.reference';

interface Options {
  environment: AtrigamEnvironment;
  extensionName: AtrigamFlowExtensionSettingNames;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getExtensionSettingsQuery = async <T extends AtrigamFlowExtensionSettingsData>(
  options: Options,
) => {
  const reference = getExtensionSettingsQueryReference<T>(options);
  const snapshot = await getDoc(reference);
  return snapshot.data();
};
