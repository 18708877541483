import { Breadcrumbs as MaterialBreadcrumbs } from '@mui/material';
import React from 'react';

import { reactive } from '../../../../helpers/reactive';
import { Link } from '../../../../primitive/Link/Link';
import { Typography } from '../../../../primitive/Typography/Typography.style';
import { Registry } from '../../../../services/Registry/Registry';
import { NavigationContext } from '../../contexts/NavigationContext';

import { BreadcrumbsWrapper } from './styles/BreadcrumbsWrapper.style';

const BreadcrumbsComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = Registry.get('router');

  const { isOpen } = React.useContext(NavigationContext);

  if (!router.isReady || !router.currentRoute.breadcrumbs) {
    return null;
  }

  return (
    <BreadcrumbsWrapper $isOpen={isOpen}>
      <MaterialBreadcrumbs aria-label="breadcrumb">
        {router.currentRoute.breadcrumbs.map(({ link, label }) => {
          if (link) {
            return (
              <Link to={link} key={label} color="secondary">
                {label}
              </Link>
            );
          }

          return (
            <Typography color="textPrimary" key={label}>
              {label}
            </Typography>
          );
        })}
      </MaterialBreadcrumbs>
      {children}
    </BreadcrumbsWrapper>
  );
};

export const Breadcrumbs = reactive(BreadcrumbsComponent);
