export const createTranslate =
  <
    R extends Record<string, Record<string, string>>,
    Language extends keyof R,
    LanguageKeys extends keyof R[Language],
  >(
    translations: R,
  ) =>
  ({ language, key }: { language: Language; key: LanguageKeys }) => {
    if (!translations[language]?.[key]) {
      throw new Error(
        `Cannot find language key ${key as string} for language ${language as string}.`,
      );
    }

    return translations[language][key];
  };
