import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocument,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  UID,
} from '@atrigam/atrigam-types';

import { createAtrigamUserSubscriptionsTaskFlowPath } from '../firestore/paths/createAtrigamUserSubscriptionsTaskFlowPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const watchUserSubscriptionsFlowNode: WatcherFunction<
  AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocument,
  Options
> = ({ onUpdate, onError, uid, environment, area, universe, taskFlow }) => {
  const firestoreService = watcherServiceEu.get();

  const path = createAtrigamUserSubscriptionsTaskFlowPath({
    area,
    environment,
    taskFlow,
    uid,
    universe,
  });

  return firestoreService.subscribeToDocument<AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocument>(
    {
      path,
      onSnapshot: (snapshot, key) => {
        const userSubscription = snapshot.data();
        void onUpdate(userSubscription, key);
      },
      onError,
    },
  );
};
