import { isEmpty, isNil } from 'rambda';

import {
  AtrigamConditionsSetCondition,
  AtrigamValidationTypes,
  AtrigamWorkItem,
  isAtrigamInteractionCodeScan,
} from '../../domain';
import { AtrigamUniverseKpi } from '../../firestore';
import { UnhandledCaseError } from '../../utilities';

import { validateConditionValueHasChanged } from './validateCondition.valueHasChanged';
import { validateConditionValueIs } from './validateCondition.valueIs';
import { validateConditionValueIsExactly } from './validateCondition.valueIsExactly';
import { validateConditionValueIsNot } from './validateCondition.valueIsNot';

interface Options {
  condition: AtrigamConditionsSetCondition;
  universeKpisMap?: Record<AtrigamUniverseKpi['id'], AtrigamUniverseKpi['result']>;
  workItem?: Omit<AtrigamWorkItem, 'id'>;
  beforeWorkItem?: Omit<AtrigamWorkItem, 'id'>;
}

export const validateCondition = ({
  condition,
  universeKpisMap = {},
  workItem,
  beforeWorkItem,
}: Options) => {
  let nodeValue =
    workItem && workItem[condition.data] !== undefined ? workItem[condition.data] : undefined;
  // get value from universeKpis
  if (nodeValue === undefined && universeKpisMap[condition.data] !== undefined) {
    nodeValue = universeKpisMap[condition.data];
  }

  const originalValue = beforeWorkItem?.[condition.data] ?? undefined;

  const conditionType = condition.validation;
  const conditionValue = condition.value;

  switch (conditionType) {
    case AtrigamValidationTypes.IsNotTrue: {
      return nodeValue === false || nodeValue === undefined || nodeValue === null;
    }

    case AtrigamValidationTypes.IsTrue: {
      return nodeValue === true;
    }

    case AtrigamValidationTypes.ObjectDataDoesNotExist: {
      return nodeValue === null || nodeValue === undefined;
    }

    case AtrigamValidationTypes.ObjectDataExists: {
      return !isNil(nodeValue) && (nodeValue === false || !isEmpty(nodeValue));
    }

    case AtrigamValidationTypes.ValueHasChanged: {
      return validateConditionValueHasChanged({ currentValue: nodeValue, originalValue });
    }

    case AtrigamValidationTypes.ValueIsExactly: {
      return validateConditionValueIsExactly({ nodeValue, conditionValue });
    }

    case AtrigamValidationTypes.ValueIsGreaterThan: {
      return validateConditionValueIs({ nodeValue, conditionValue, conditionType });
    }

    case AtrigamValidationTypes.ValueIsGreaterThanOrEqual: {
      return validateConditionValueIs({ nodeValue, conditionValue, conditionType });
    }

    case AtrigamValidationTypes.ValueIsLowerThan: {
      return validateConditionValueIs({ nodeValue, conditionValue, conditionType });
    }

    case AtrigamValidationTypes.ValueIsLowerThanOrEqual: {
      return validateConditionValueIs({ nodeValue, conditionValue, conditionType });
    }

    case AtrigamValidationTypes.ValueIsNot: {
      return validateConditionValueIsNot({ nodeValue, conditionValue });
    }

    case AtrigamValidationTypes.ValueIsNotOneOf: {
      // verify both values are not undefined
      if (isNil(nodeValue) || isNil(conditionValue) || conditionValue === undefined) {
        return false;
      }

      // handle multiselect differently
      if (
        typeof nodeValue === 'object' &&
        nodeValue !== null &&
        !isAtrigamInteractionCodeScan(nodeValue)
      ) {
        // handle multiselect
        const nodeValues = Object.values(nodeValue);
        const conditionValues = String(conditionValue)
          .split(',')
          .map((cv) => cv.trim());

        return conditionValues.every((cv) => !nodeValues.includes(cv));
      }

      return (
        conditionValue &&
        typeof conditionValue === 'string' &&
        conditionValue
          .split(',')
          .map((cv) => cv.trim())
          .every((cv) => validateConditionValueIsNot({ nodeValue, conditionValue: cv }))
      );
    }

    case AtrigamValidationTypes.ValueIsOneOf: {
      // verify both values are not undefined
      if (isNil(nodeValue) || isNil(conditionValue) || conditionValue === undefined) {
        return false;
      }

      // handle multiselect differently
      if (
        typeof nodeValue === 'object' &&
        nodeValue !== null &&
        !isAtrigamInteractionCodeScan(nodeValue)
      ) {
        // handle multiselect
        const nodeValues = Object.values(nodeValue);
        const conditionValues = String(conditionValue)
          .split(',')
          .map((cv) => cv.trim());

        return conditionValues.some((cv) => nodeValues.includes(cv));
      }

      return (
        nodeValue &&
        typeof conditionValue === 'string' &&
        conditionValue
          .split(',')
          .map((cv) => cv.trim())
          .some((cv) => validateConditionValueIsExactly({ nodeValue, conditionValue: cv }))
      );
    }

    default: {
      throw new UnhandledCaseError(conditionType);
    }
  }
};
