import { type AtrigamAreaName, AtrigamModelsModelAreasArea } from '@atrigam/atrigam-types';
import { computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class AreaModelEntity {
  @persist
  @observable
  name?: AtrigamAreaName;

  @persist
  @observable
  private _label?: string;

  constructor(data?: AtrigamModelsModelAreasArea) {
    makeObservable(this);

    if (data) {
      this.name = data.name;
      this._label = data.label;
    }
  }

  @computed
  get label() {
    if (this._label) {
      return this._label;
    }

    return this.name;
  }
}
