import { AtrigamFirestoreUniversesEnvironmentObjectsFlow, isAfter } from '@atrigam/atrigam-types';
import { getUniverseKpiListQuery, watchFlowObject } from '@atrigam/server-functions-eu-client';

import { withReaction } from '../../../helpers/withReaction';
import { Registry } from '../../../services/Registry/Registry';
import { TaskFlowModelEntity } from '../entities/TaskFlowModel.entity';

export const watchUniverseKpis = (flowModel: TaskFlowModelEntity) => {
  const userStore = Registry.get('userStore');

  const disposer = withReaction({
    name: 'TaskFlowModelEntity.watchUniverseKpis',
    data: () => ({
      environment: flowModel.environment,
      objectName: flowModel.objectName,
      universe: flowModel.universe,
      userInitialized: userStore.isInitialized,
    }),
    fireImmediately: true,
    onChange: ({ environment, objectName, universe, userInitialized }) => {
      // wait till we have something to do
      if (!environment || !universe || !objectName || !userInitialized) {
        return;
      }

      const onUpdate = async (data?: AtrigamFirestoreUniversesEnvironmentObjectsFlow) => {
        if (!data?.kpiUpdatedAt) {
          return;
        }

        // only check for updates if the updatedAt field has changed
        if (!flowModel.kpiUpdatedAt || isAfter(data.kpiUpdatedAt, flowModel.kpiUpdatedAt)) {
          const universeKpis = await getUniverseKpiListQuery({ environment, objectName, universe });
          flowModel.updateUniverseKpis({ kpis: universeKpis, kpiUpdatedAt: data.kpiUpdatedAt });
        }
      };

      const flowWatcherDisposer = watchFlowObject({ environment, objectName, universe, onUpdate });
      flowModel.disposers.push(flowWatcherDisposer);
    },
  });

  flowModel.disposers.push(disposer);
};
