import { Currency } from '../../../domain/common.types';
import { AtrigamInteractionMoneyFX } from '../../../domain/taskFlow.interactions.types';
import { AtrigamFXRates } from '../../../firestore/atrigam-eu';
import { getDay } from '../../../utilities/dateUtilities/getDay';
import { AtrigamDataType } from '../../atrigam-datatypes';
import { ATRIGAM_SYSTEM_CURRENCY } from '../../dataTypes.constants';
import { getObjectAsStringOrValue } from '../../helpers/getObjectAsStringOrValue';
import { ParseDataTypeFunction } from '../../parseDataType.types';

import { formatMoneyValue } from './helpers/parse/formatMoneyValue';
import { parseMoneyString } from './helpers/parse/parseMoneyString';

export const parseDataTypeMoney: ParseDataTypeFunction<AtrigamDataType.Money> = ({
  value: value_,
  fxRates,
}) => {
  const value = getObjectAsStringOrValue(value_);

  // parse the field
  const parsed = parseValue({ value });
  if (!parsed) {
    return;
  }

  const converted = convertIntoSystemCurrency({ parsed, fxRates });

  let fxRate = fxRates?.rates[parsed.currency];
  if (parsed.currency === ATRIGAM_SYSTEM_CURRENCY && fxRates?.base === ATRIGAM_SYSTEM_CURRENCY) {
    fxRate = 1;
  }

  const formattedValue = formatMoneyValue({
    value: parsed.value,
    currency: parsed.currency || ATRIGAM_SYSTEM_CURRENCY,
  });

  const fxValue: AtrigamInteractionMoneyFX = {
    currency: parsed.currency,
    fxBase: fxRates?.base,
    fxRate,
    fxDate: fxRates?.date ? (getDay(fxRates.date) as unknown as Date) : undefined,
    rawValue: String(parsed.value),
    systemCurrency: fxRates ? ATRIGAM_SYSTEM_CURRENCY : parsed.currency,
    value: formattedValue,
    valueInSystemCurrency: converted.value,
  };

  return { ...converted, fxValue };
};

const parseValue = ({ value }: { value: unknown }) => {
  if (typeof value === 'string') {
    const parsed = parseMoneyString(value);
    if (parsed) {
      return parsed;
    }

    if (/^(\d*[,.]?\d*)$/.test(value)) {
      const number = Number.parseFloat(value);
      if (number) {
        return {
          value: number,
          currency: ATRIGAM_SYSTEM_CURRENCY,
          fractions: 0,
        };
      }
    }
  }

  if (typeof value === 'number' && !Number.isNaN(value)) {
    return {
      value,
      currency: ATRIGAM_SYSTEM_CURRENCY,
      fractions: 0,
    };
  }

  return;
};

const convertIntoSystemCurrency = ({
  parsed,
  fxRates,
}: {
  parsed: { value: number; currency: Currency; fractions: number };
  fxRates: AtrigamFXRates | undefined;
}) => {
  if (parsed.currency === ATRIGAM_SYSTEM_CURRENCY) {
    return parsed;
  }

  // convert into system currency
  if (fxRates?.rates[parsed.currency]) {
    const valueInSystemCurrency = parsed.value / fxRates.rates[parsed.currency];

    return {
      value: valueInSystemCurrency,
      currency: ATRIGAM_SYSTEM_CURRENCY,
      fractions: 0,
    };
  }

  return parsed;
};
