import { SubscriptionEntity } from '../entities/Subscription.entity';

import { sortSubscriptionsAfterInvitedAt } from './sortSubscriptionsAfterInvitedAt';
import { sortSubscriptionsAfterState } from './sortSubscriptionsAfterState';

export const sortSubscriptions = (a: SubscriptionEntity, b: SubscriptionEntity) => {
  // first sort by state
  const stateSort = sortSubscriptionsAfterState(a, b);
  if (stateSort !== 0) {
    return stateSort;
  }

  // now sort by invited at
  return sortSubscriptionsAfterInvitedAt(a, b);
};
