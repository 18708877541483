import { isValidEmail } from '../../../utilities/isValidEmail';
import { AtrigamDataType } from '../../atrigam-datatypes';
import { ParseDataTypeFunction } from '../../parseDataType.types';
import { parseDataTypeString } from '../String/parseDataType.string';

export const parseDataTypeEmail: ParseDataTypeFunction<AtrigamDataType.Email> = ({ value }) => {
  const parsed = parseDataTypeString({ value, dataType: AtrigamDataType.String });
  if (!parsed?.value) {
    return;
  }

  if (!isValidEmail(parsed.value)) {
    return;
  }

  return parsed;
};
