import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { getDocs } from 'firebase/firestore';

import { getUniverseKpiListQueryReference } from '../queryReferences/getUniverseKpi.list.query.reference';

interface Options {
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getUniverseKpiListQuery = async (options: Options) => {
  const query_ = getUniverseKpiListQueryReference(options);

  const collection_ = await getDocs(query_);
  return collection_.docs.map((snapshot) => snapshot.data());
};
