import { trackingConfiguration } from '@atrigam/atrigam-tracking';
import { sendVerificationLinkHttpOnCallFunction } from '@atrigam/server-functions-eu-sdk';
import React from 'react';

import { Registry } from '../services/Registry/Registry';
import { sentry } from '../services/Sentry/helpers/initializeSentry';

export const useSendVerificationMail = () => {
  const [isSending, setIsSending] = React.useState(false);

  const onSendVerificationMail = React.useCallback(async () => {
    setIsSending(true);

    const { user } = Registry.get('userStore');

    const result = await sendVerificationLinkHttpOnCallFunction({
      onException: (error) => sentry.log({ error: error as Error, context: { uid: user?.uid } }),
      XAppEnvironment: trackingConfiguration?.appEnvironment,
      XTransactionID: sentry.transactionID,
    });
    setIsSending(false);

    return result === true;
  }, []);

  return { isSending, onSendVerificationMail };
};
