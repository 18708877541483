import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamSubscriptionState,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUserSubscription,
  ISODateTime,
  UID,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  CollectionReference,
  DocumentData,
  collection,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamUserSubscriptionListPath } from '../paths/createAtrigamUserSubscriptionListPath';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  limit?: number;
  startAfter?: ISODateTime;
  subscriptionState?: AtrigamSubscriptionState;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const getUserSubscriptionListQueryReference = ({
  area,
  environment,
  limit: limit_ = 15,
  startAfter: startAfter_,
  subscriptionState,
  taskFlow,
  uid,
  universe,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));
  const path = createAtrigamUserSubscriptionListPath({
    area,
    environment,
    taskFlow,
    uid,
    universe,
  });

  const reference = collection(database, path) as CollectionReference<
    AtrigamUserSubscription,
    DocumentData
  >;
  let query_ = query(reference);
  if (subscriptionState) {
    query_ = query(query_, where('subscriptionState', '==', subscriptionState));
  }

  query_ = query(query_, orderBy('invitedAt', 'desc'), limit(limit_));

  if (startAfter_) {
    query_ = query(query_, startAfter(startAfter_));
  }

  return query_;
};
