import {
  ChangeSet,
  getDateTime,
  getNowDateTime,
  validateConditionSet,
} from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import {
  AtrigamFlowExtensionProcessor,
  AtrigamFlowExtensionProcessorType,
  ExtensionProcessFunction,
} from '../../../extensionsRunner/atrigam-processor.types';
import { resolvePatternWithWorkItem } from '../../../helpers/resolvePatternWithWorkItem';

import { AtrigamFlowExtensionAutoId_2_1 } from './autoId.2.1.extensions.types';
import { AutoId_2_1_ProcessorOptions } from './autoId.2.1.processor.types';
import { createAutoIDFieldName } from './helpers/createAutoIDFieldName';
import { getNextAutoID } from './helpers/getNextAutoID';

// eslint-disable-next-line @typescript-eslint/naming-convention
export class AutoId_2_1_Processor
  implements AtrigamFlowExtensionProcessor<AtrigamFlowExtensionAutoId_2_1>
{
  type = AtrigamFlowExtensionTypes.AutoId;
  version = '2.1';
  processorType = AtrigamFlowExtensionProcessorType.All;

  private getExtensionSettingsQuery: AutoId_2_1_ProcessorOptions['getExtensionSettingsQuery'];
  private updateExtensionSettingsMutation: AutoId_2_1_ProcessorOptions['updateExtensionSettingsMutation'];

  constructor(options: AutoId_2_1_ProcessorOptions) {
    this.getExtensionSettingsQuery = options.getExtensionSettingsQuery;
    this.updateExtensionSettingsMutation = options.updateExtensionSettingsMutation;
  }

  supportsVersion: (version: string) => boolean = (version) => version === this.version;

  process: ExtensionProcessFunction<AtrigamFlowExtensionAutoId_2_1> = async ({
    beforeWorkItem,
    extension,
    universeKpisMap,
    workItem,
    environment,
    objectName,
    universe,
  }) => {
    //
    if (!extension.config?.patterns || Object.keys(extension.config.patterns).length === 0) {
      return [];
    }

    const patterns = extension.config.patterns;

    const changes: ChangeSet[] = [];

    let resolvedPattern: string | undefined;
    Object.entries(patterns).forEach(([key, pattern]) => {
      let resolved = true;

      if (extension.config.conditions?.[key]) {
        resolved = validateConditionSet({
          conditions: extension.config.conditions[key].conditions,
          universeKpisMap,
          workItem,
          beforeWorkItem,
        });
      }

      // not resolved
      if (!resolved) {
        return;
      }

      const nextResolvedPattern = resolvePatternWithWorkItem({ pattern, workItem });
      if (nextResolvedPattern?.resolved) {
        resolvedPattern = nextResolvedPattern.resolved;
      }
    });

    // could not parse
    if (!resolvedPattern) {
      // TODO!!
      const changeSetUnresolved = {
        fieldName: extension.config.field,
        fieldValue: null,
      };
      workItem[changeSetUnresolved.fieldName] = changeSetUnresolved.fieldValue;
      changes.push(changeSetUnresolved);

      const changeSetAutoIdUnresolved = {
        fieldName: createAutoIDFieldName(extension.config.field),
        fieldValue: null,
      };
      workItem[changeSetAutoIdUnresolved.fieldName] = changeSetAutoIdUnresolved.fieldValue;
      changes.push(changeSetAutoIdUnresolved);

      return changes;
    }

    // get current auto-id, update next auto-id
    const workField = workItem[extension.config.field];

    const workItemAutoId = workField
      ? (workItem[createAutoIDFieldName(extension.config.field)] as number | undefined)
      : undefined;

    // use the current auto-id if there is already one
    const autoIDRaw =
      workItemAutoId ??
      (await getNextAutoID({
        environment,
        extension,
        objectName,
        universe,
        getExtensionSettingsQuery: this.getExtensionSettingsQuery,
        updateExtensionSettingsMutation: this.updateExtensionSettingsMutation,
      }));

    let autoID = autoIDRaw.toString();
    if (extension.config.zerofill && extension.config.zerofillLength) {
      autoID = autoIDRaw
        .toString()
        .padStart(Number.parseInt(extension.config.zerofillLength, 10), '0');
    }

    autoID = resolvedPattern.replace('[auto-id]', autoID);

    const dateTime = workItem.createdAt ? getDateTime(workItem.createdAt) : getNowDateTime();
    const month = dateTime.month.toString().padStart(2, '0'); // months from 1-12
    const day = dateTime.day.toString().padStart(2, '0');
    const year = dateTime.year.toString();
    autoID = autoID.replace('[year]', year).replace('[month]', month).replace('[day]', day);

    const changeSet = {
      fieldName: extension.config.field,
      fieldValue: autoID,
    };

    // did something change?
    if (workItem[changeSet.fieldName] !== changeSet.fieldValue) {
      // update workItem directly so other extensions can handle it
      workItem[changeSet.fieldName] = changeSet.fieldValue;
      // add change set for update mutation
      changes.push(changeSet);
    }

    const changeSetAutoId = {
      fieldName: createAutoIDFieldName(extension.config.field),
      fieldValue: autoIDRaw,
    };

    if (workItem[changeSetAutoId.fieldName] !== changeSetAutoId.fieldValue) {
      workItem[changeSetAutoId.fieldName] = changeSetAutoId.fieldValue;
      changes.push(changeSetAutoId);
    }

    return changes;
  };
}
