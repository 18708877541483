import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AvailableExtensionVersionProcessPreviewFunction } from '../../extension-config-base.types';
import { AtrigamFlowExtensionAggregatedValue } from '../aggregatedValue.extensions.types';

export const processPreviewAggregatedValue: AvailableExtensionVersionProcessPreviewFunction<
  AtrigamFlowExtensionAggregatedValue
> = (extension) => {
  let values = '';
  let unit = '';

  if (extension.config.aggregations) {
    values = Object.values(extension.config.aggregations)
      .slice(0, 5)
      .join('/')
      .replaceAll('[', '')
      .replaceAll(' ', '')
      .replaceAll(']', '');
  }

  if (values === '') {
    values = '?';
  }

  if (
    extension.config.unit && // unit only goes with number
    extension.config.type === AtrigamDataType.Number
  ) {
    unit = ' ' + extension.config.unit;
  }

  return values + unit;
};
