import {
  AtrigamDataType,
  AtrigamModelsUniverseKpiModel,
  type AtrigamModelsUniverseKpiModelAggregationType,
} from '@atrigam/atrigam-types';
import { computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class WorkItemModelKpiEntity {
  @persist
  @observable
  active?: boolean;

  @persist
  @observable
  aggregationDimension?: string;

  @persist
  @observable
  aggregationType?: AtrigamModelsUniverseKpiModelAggregationType;

  @persist
  @observable
  name?: string;

  @persist
  @observable
  label?: string;

  @persist
  @observable
  type?: AtrigamDataType;

  constructor(data?: AtrigamModelsUniverseKpiModel) {
    makeObservable(this);

    if (data) {
      this.aggregationDimension = data.aggregationDimension;
      this.aggregationType = data.aggregationType;
      this.name = data.name;
      this.label = data.label;
      this.type = data.type;
      this.active = data.active;

      // TODO handle filter!
    }
  }

  @computed
  get asKpiModel() {
    if (
      !this.active ||
      !this.aggregationDimension ||
      !this.aggregationType ||
      !this.name ||
      !this.label ||
      !this.type
    ) {
      return;
    }

    const kpiModel: AtrigamModelsUniverseKpiModel = {
      active: this.active,
      aggregationDimension: this.aggregationDimension,
      aggregationType: this.aggregationType,
      name: this.name,
      label: this.label,
      type: this.type,
    };

    return kpiModel;
  }
}
