import { URI } from '@atrigam/atrigam-types';
import { getDownloadURL as getDownloadURL_, ref } from 'firebase/storage';

import { getFirebaseStorage } from './getFirebaseStorage';

interface Options {
  path: string;
  bucketUrl?: string;
}

export const getDownloadURL = async ({ path, bucketUrl }: Options) => {
  return (await getDownloadURL_(ref(getFirebaseStorage(bucketUrl), path))) as URI;
};
