import { AtrigamEnvironment, AtrigamUniverseName, ISODateTime } from '@atrigam/atrigam-types';

import { WatcherFunction } from '../../types/watchers.types';
import { watcherServiceModels } from '../../watcherService';
import { createAtrigamModelsModelUpdatedAtPath } from '../paths/createAtrigamModelsModelUpdatedAtPath';

interface Properties {
  environment: AtrigamEnvironment;
  universe: AtrigamUniverseName;
}

export const watchModelsModelUpdatedAt: WatcherFunction<ISODateTime, Properties> = ({
  environment,
  universe,
  onUpdate,
  onError,
}) => {
  const firebaseService = watcherServiceModels.get();
  const path = createAtrigamModelsModelUpdatedAtPath({ environment, universe });

  return firebaseService.subscribePath<ISODateTime>({
    path,
    onChange: (snapshot, key) => {
      const updatedAt = snapshot.val();
      void onUpdate(updatedAt, key);
    },
    onError,
  });
};
