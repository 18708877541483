import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AtrigamEnvironment,
  AtrigamInteractionType,
  AtrigamRole,
  throwIfNullable,
  type AtrigamObjectName,
  type AtrigamUniverseName,
  type AtrigamWorkItem,
  type AtrigamWorkItemId,
} from '@atrigam/atrigam-types';
import {
  createNewWorkItemIdMutation,
  createStorageUniverseDataFieldPath,
} from '@atrigam/server-functions-eu-client';
import { action, computed, makeObservable, observable, when } from 'mobx';
import { v4 as uuid } from 'uuid';

import { cloneObject } from '../../../../helpers/cloneObject';
import { WorkItemPageStore } from '../WorkItem.page.store';
import { CreateWorkItemData, EditWorkItemData } from '../WorkItem.page.store.types';

import { InteractionStepEntity } from './entities/InteractionStep.entity';
import { UniverseInteractionEntity } from './entities/UniverseInteraction.entity';
import { UserRoleChooserEntity } from './entities/UserRoleChooser.entity';
import { isEditWorkItemData } from './helpers/isEditWorkItemData';
import { watchForChangesToBeSaved } from './helpers/watchForChangesToBeSaved';
import { watchForWorkItemUpdates } from './helpers/watchForWorkItemUpdates';

export interface UserChange {
  workItem: Partial<AtrigamWorkItem>;
  status: 'added' | 'saving' | 'saved' | 'merged';
  interactionType?: AtrigamInteractionType;
  id: string;
}

export class InteractionsStore extends DestructibleService {
  @observable
  changes: UserChange[] = [];

  @observable
  savedChanges: Partial<AtrigamWorkItem>[] = [];

  @observable
  changedInteractions = new Set<string>();

  @observable
  createRoles: AtrigamRole[] = [];

  @observable
  isSaving = false;

  @observable
  reservedNode?: AtrigamWorkItemId;

  @observable
  userRoleChooser?: UserRoleChooserEntity;

  @observable
  workItem: AtrigamWorkItem;

  @observable
  lastStoreWorkItem?: AtrigamWorkItem;

  @observable
  private _pageStore: WorkItemPageStore;

  @observable
  private _steps: InteractionStepEntity[] = [];

  @observable
  private _environment: AtrigamEnvironment;

  @observable
  private _universe: AtrigamUniverseName;

  @observable
  private _objectName: AtrigamObjectName;

  constructor({
    data,
    pageStore,
  }: {
    data: CreateWorkItemData | EditWorkItemData;
    pageStore: WorkItemPageStore;
  }) {
    super();
    makeObservable(this);

    this._pageStore = pageStore;
    this.workItem = cloneObject(data.workItem);
    this._environment = pageStore.environment;

    const universe = pageStore.taskFlowModel.universe;
    const objectName = pageStore.taskFlowModel.objectName;
    throwIfNullable('objectName cannot be null', objectName);
    throwIfNullable('universe cannot be null', universe);
    this._universe = universe;
    this._objectName = objectName;

    this.updateSteps();

    watchForChangesToBeSaved(this);

    if (isEditWorkItemData(data)) {
      this.watchWorkItemStoreItem(data.node);
    }
  }

  @computed
  get hasMoneyInput() {
    return this._steps.some((step) =>
      step.allInteractions.some(
        (interaction) => interaction.interaction.type === AtrigamInteractionType.MoneyInput,
      ),
    );
  }

  @computed
  get isDirty() {
    return this.changes.some((change) => ['added', 'saving'].includes(change.status));
  }

  @computed
  get isNewWorkItem() {
    return !this.workItem.id;
  }

  @computed
  get rolesFromInteractions() {
    const roles = new Set<AtrigamRole>();
    this._steps.forEach((step) => {
      step.allInteractions.forEach((interaction) => {
        interaction.interaction.roles.forEach((role) => {
          roles.add(role);
        });
      });
    });

    return [...roles].sort();
  }

  @computed
  get steps() {
    return this._steps
      .slice()
      .sort((a, b) => a.sequence - b.sequence)
      .filter((step) => {
        if (!step.name || !step.hasInteractions) {
          return false;
        }

        return true;
      });
  }

  @action
  _destruct = () => {
    this.disposers.forEach((dispose) => {
      dispose();
    });

    this._steps.forEach((step) => step._destruct());
  };

  @action
  askUserWhichRole = async ({ interactionRoles }: { interactionRoles: AtrigamRole[] }) => {
    this.userRoleChooser = new UserRoleChooserEntity({ roles: interactionRoles });

    await when(() => this.userRoleChooser!.chosenRole !== undefined);

    const chosenRole = this.userRoleChooser.chosenRole!;
    this.userRoleChooser = undefined;

    return chosenRole;
  };

  /**
   * helper function for fileUploads, we might need to reserve a workitem id
   * so we can use it to save the file. This only happens if the first
   * interaction of a user is a file upload.
   */
  @action
  getOrCreateReserveWorkItemNode = () => {
    // first use id from workItem
    if (this.workItem.id) {
      return this.workItem.id;
    }

    // next lookup if we already reserved a node
    if (this.reservedNode) {
      return this.reservedNode;
    }

    // lastly create a new reserved node
    const { universe, objectName, environment } = this._pageStore.basicData;
    const node = createNewWorkItemIdMutation({ universe, environment, objectName });
    this.reservedNode = node;

    return node;
  };

  @action
  getStoragePath = (fieldName: string) => {
    const { universe, objectName, environment } = this._pageStore.basicData;
    const workItemId = this.getOrCreateReserveWorkItemNode();

    return createStorageUniverseDataFieldPath({
      environment,
      fieldName,
      objectName,
      universe,
      workItemId,
    });
  };

  @action
  saveInteraction = ({
    changes,
    interaction,
  }: {
    changes: Partial<AtrigamWorkItem>;
    interaction: UniverseInteractionEntity;
  }) => {
    if (changes.length === 0) {
      throw new Error('Need a change!');
    }

    // if we create a workitem
    // we need to check if are multiple roles possible
    if (!this.workItem.id && this.createRoles.length === 0) {
      const interactionRoles = interaction.interaction.getFilteredRoles({
        roles: this._pageStore.subscribedUserRoles,
      });
      if (interactionRoles.length === 0) {
        throw new Error(
          `Could not find any roles of the interaction ${
            interaction.interaction.label ?? ''
          } for the current user`,
        );
      }
      this.createRoles = interactionRoles;
    }

    // add to update
    this.changes.push({
      workItem: changes,
      status: 'added',
      id: uuid(),
      interactionType: interaction.interaction.type,
    });

    this.changedInteractions.add(
      interaction.fieldName ?? interaction.interaction.type ?? 'unknown',
    );
  };

  @action
  setIsSaving = (saving: boolean) => {
    this.isSaving = saving;
  };

  @action
  watchWorkItemStoreItem = (node: AtrigamWorkItemId) => {
    watchForWorkItemUpdates({
      interactionsStore: this,
      environment: this._environment,
      node,
      universe: this._universe,
      objectName: this._objectName,
    });
  };

  @action
  updateSteps = () => {
    const taskFlowModel = this._pageStore.taskFlowModel;
    this._steps = [...taskFlowModel.steps]
      .sort((a, b) => a.sequence - b.sequence)
      .map(
        (flowStep) =>
          new InteractionStepEntity({
            flowStep,
            workItem: this.workItem,
            interactionStore: this._pageStore,
          }),
      );
  };
}
