import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  type AtrigamUniverseName,
  AtrigamUniverseRegistrationClientRole,
  FirestoreTimestamp,
  type ISODateTime,
  getISO,
} from '@atrigam/atrigam-types';
import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { schemaPersist } from '../../../helpers/mobx/decorators/schemaPersist';

interface Options {
  clientRoles: AtrigamUniverseRegistrationClientRole[];
  universe: AtrigamUniverseName;
  updatedAt: FirestoreTimestamp;
}

export class UserUniverseClientRolesEntity {
  @persist
  @observable
  updatedAt?: ISODateTime;

  @persist
  @observable
  universe?: AtrigamUniverseName;

  @schemaPersist<AtrigamUniverseRegistrationClientRole>('list', {
    area: true,
    environment: true,
    flow: true,
    role: true,
    universe: true,
  })
  @observable
  clientRoles: AtrigamUniverseRegistrationClientRole[] = [];

  constructor(data?: Options) {
    makeObservable(this);

    if (data) {
      this.universe = data.universe;

      this.update(data);
    }
  }

  @action
  hasEnvironment = ({ environment }: { environment: AtrigamEnvironment }) => {
    return this.clientRoles.some((clientRole) => clientRole.environment === environment);
  };

  @action
  update = ({ updatedAt, clientRoles }: Options) => {
    this.updatedAt = getISO(updatedAt);
    // this.clientRoles = clientRoles.map((clientRole) => new ClientRoleEntity(clientRole));
    this.clientRoles = clientRoles.map((clientRole) => ({ ...clientRole }));
  };

  getTaskFlowNamesForEnvironment = ({ environment }: { environment: AtrigamEnvironment }) => {
    const flowNames = this.clientRoles
      .filter((clientRole) => clientRole.environment === environment && clientRole.flow)
      .map((clientRole) => clientRole.flow);

    return [...new Set(flowNames)];
  };

  getRolesForTaskFlow = ({
    environment,
    area,
    taskFlow,
  }: {
    area: AtrigamAreaName;
    environment: AtrigamEnvironment;
    taskFlow: AtrigamTaskFlowName;
  }) => {
    const roles = this.clientRoles
      .filter(
        (clientRole) =>
          clientRole.environment === environment &&
          clientRole.area === area &&
          clientRole.flow === taskFlow &&
          clientRole.role !== undefined,
      )
      .map((clientRole) => clientRole.role);

    return [...new Set(roles)];
  };
}
