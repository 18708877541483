import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamInteractionType,
  AtrigamObjectName,
  AtrigamRole,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamWorkItem,
  AtrigamWorkItemId,
  UID,
  createAtrigamUniverseAreaTaskFlow,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import {
  createWorkItem,
  getObjectSubscriptionListQuery,
  getUserSubscriptionQuery,
} from '@atrigam/server-functions-eu-client';

import { TaskFlowModelEntity } from '../../../../../stores/ModelsStore/entities/TaskFlowModel.entity';
import { Registry } from '../../../../../services/Registry/Registry';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  flowModel: TaskFlowModelEntity;
  flowName: AtrigamTaskFlowName;
  interactionRoles: AtrigamRole[];
  interactionType?: AtrigamInteractionType;
  objectName: AtrigamObjectName;
  reservedNode?: AtrigamWorkItemId;
  universe: AtrigamUniverseName;
  uid: UID;
  workItem: Partial<AtrigamWorkItem>;
}

export const createWorkItemHelper = async ({
  area,
  environment,
  flowModel,
  flowName,
  interactionRoles,
  interactionType,
  objectName,
  reservedNode,
  universe,
  uid,
  workItem,
}: Options) => {
  const workItemPageStore = Registry.get('workItemPageStore');
  const interactionsStore = workItemPageStore.interactionsStore;

  if (interactionRoles.length === 0) {
    throw new Error(
      `Could not find any roles of the interaction ${interactionType ?? ''} for the current user`,
    );
  }

  let role = interactionRoles[0];
  if (interactionRoles.length > 1) {
    role = await interactionsStore.askUserWhichRole({ interactionRoles });
  }

  throwIfNullable('role cannot be undefined', role);

  const universeAreaTaskFlow = createAtrigamUniverseAreaTaskFlow({
    universe,
    area,
    taskFlow: flowName,
  });
  throwIfNullable('uid cannot be undefined', uid);

  const createdWorkItem = await createWorkItem({
    area,
    fieldUpdateNotifications: flowModel.getDefaultFieldUpdateNotificationsForRole({ role }),
    environment,
    taskFlow: flowName,
    objectName,
    reservedNode,
    role,
    universe,
    uid,
    workItem,
  });

  void track({
    event: AtrigamAnalyticEvents.INTERACTION_CreateNewWorkItem,
    screen: AtrigamAnalyticScreens.Interaction,
    area,
    env: environment,
    flow: flowName,
    interactionType,
    object: objectName,
    roles: [role],
    universe,
    workitemId: createdWorkItem.id,
  });

  const objectSubscriptionList = await getObjectSubscriptionListQuery({
    universe,
    environment,
    objectName,
    node: createdWorkItem.id,
  });

  const userSubscription = await getUserSubscriptionQuery({
    environment,
    node: createdWorkItem.id,
    uid,
    universe,
    area,
    taskFlow: flowName,
  });

  const modelsStore = Registry.get('modelsStore');
  const workItemModel = modelsStore.getWorkItemModel({ universeAreaTaskFlow });

  if (!createdWorkItem || !objectSubscriptionList || !userSubscription || !workItemModel) {
    throw new Error('could not create a new work item');
  }

  return {
    objectSubscriptionList,
    userSubscription,
    workItemData: createdWorkItem,
  };
};
