import {
  ChangeSet,
  AtrigamDataType,
  createMoneyFxFieldName,
  validateConditionSet,
} from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import {
  AtrigamFlowExtensionProcessor,
  AtrigamFlowExtensionProcessorType,
  ExtensionProcessFunction,
} from '../../../extensionsRunner/atrigam-processor.types';

import { AtrigamFlowExtensionAggregatedValue_1_0 } from './aggregatedValue.1.0.extensions.types';
import { AggregatedValue_1_0_ProcessorOptions } from './aggregatedValue.1.0.processor.types';
import { calculateMathExpression } from './helpers/calculateMathExpression';
import { getAggregationMethod } from './helpers/getAggregationMethod';
import { getAggregationValues } from './helpers/getAggregationValues';

// eslint-disable-next-line @typescript-eslint/naming-convention
export class AggregatedValue_1_0_Processor
  implements AtrigamFlowExtensionProcessor<AtrigamFlowExtensionAggregatedValue_1_0>
{
  type: AtrigamFlowExtensionTypes = AtrigamFlowExtensionTypes.AggregatedValue;
  version = '1.0';
  processorType = AtrigamFlowExtensionProcessorType.ClientOnly;

  private _getWorkItemQuery: AggregatedValue_1_0_ProcessorOptions['getWorkItemQuery'];

  constructor(options: AggregatedValue_1_0_ProcessorOptions) {
    this._getWorkItemQuery = options.getWorkItemQuery;
  }

  supportsVersion: (version: string) => boolean = (version) => version === this.version;

  process: ExtensionProcessFunction<AtrigamFlowExtensionAggregatedValue_1_0> = async ({
    beforeWorkItem,
    environment,
    extension,
    universe,
    universeKpisMap,
    workItem,
  }) => {
    //
    if (!extension.config?.aggregations) {
      return [];
    }

    const aggregations = extension.config.aggregations;

    const changes: ChangeSet[] = [];

    let resolvedPattern: string | null | undefined;
    let calculated: number | undefined | null;

    Object.entries(aggregations).forEach(([key, pattern]) => {
      let resolved = true;

      if (extension.config.conditions?.[key]) {
        resolved = validateConditionSet({
          conditions: extension.config.conditions[key].conditions,
          universeKpisMap,
          workItem,
          beforeWorkItem,
        });
      }

      if (!resolved) {
        if (!calculated) {
          calculated = null;
        }
        return;
      }

      resolvedPattern = pattern;
    });

    if (resolvedPattern) {
      // get values and aggregation method
      const aggregationValues = await getAggregationValues({
        environment,
        extension,
        getWorkItemQuery: this._getWorkItemQuery,
        pattern: resolvedPattern,
        universe,
        workItem,
      });

      const aggregationMethod = getAggregationMethod({ pattern: resolvedPattern });

      if (aggregationMethod) {
        calculated = calculateMathExpression({
          method: aggregationMethod,
          pattern: resolvedPattern,
          values: aggregationValues,
        });

        // set calculated to null if pattern cannot be resolved
        if (calculated === undefined) {
          calculated = null;
        }
      }
    }

    // don't do anything if the calculations never run
    if (calculated === undefined) {
      return changes;
    }

    // if we can't resolve the conditions, set the value to null
    if (calculated === null) {
      // no changes, nothing to do
      if (workItem[extension.config.field] === null) {
        return changes;
      }

      const unresolvedSet = {
        fieldName: extension.config.field,
        fieldValue: null,
      };
      workItem[unresolvedSet.fieldName] = unresolvedSet.fieldValue;
      changes.push(unresolvedSet);

      return changes;
    }

    const changeSet = {
      fieldName: extension.config.field,
      fieldValue: calculated,
    };
    // did we change something?
    if (workItem[changeSet.fieldName] !== changeSet.fieldValue) {
      workItem[changeSet.fieldName] = changeSet.fieldValue;
      changes.push(changeSet);
    }

    // handle moneyField
    if (extension.config.type === AtrigamDataType.Money) {
      const fxField = createMoneyFxFieldName(extension.config.field);
      if (calculated === null) {
        if (workItem[fxField] !== null) {
          const moneyChange = {
            fieldName: fxField,
            fieldValue: null,
          };
          workItem[fxField] = null;
          changes.push(moneyChange);
        }
      } else {
        let fxValue = {};
        fxValue = {
          currency: 'EUR',
          systemCurrency: 'EUR',
          rawValue: String(calculated),
          value: `EUR ${calculated}`,
          valueInSystemCurrency: calculated,
        };

        // todo check if fxValue has changed
        const moneyChange = {
          fieldName: fxField,
          fieldValue: fxValue,
        };
        workItem[fxField] = fxValue;
        changes.push(moneyChange);
      }
    }

    return changes;
  };
}
