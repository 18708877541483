import { DateTime } from 'luxon';
import { isNil } from 'rambda';

import { AtrigamValidationTypes } from '../../domain';
import { getDateTimeFromISO, ISODateTime, UnhandledCaseError } from '../../utilities';

interface Options {
  conditionValue?: string | number;
  conditionType: CompareTypes;
  nodeValue: unknown;
}

type CompareTypes =
  | AtrigamValidationTypes.ValueIsGreaterThan
  | AtrigamValidationTypes.ValueIsGreaterThanOrEqual
  | AtrigamValidationTypes.ValueIsLowerThan
  | AtrigamValidationTypes.ValueIsLowerThanOrEqual;

export const validateConditionValueIs = ({ conditionValue, nodeValue, conditionType }: Options) => {
  // verify both values are not undefined
  if (isNil(nodeValue) || isNil(conditionValue) || conditionValue === undefined) {
    return false;
  }

  // handle number values
  if (typeof nodeValue === 'number') {
    if (typeof conditionValue === 'number') {
      return compare({ a: nodeValue, b: conditionValue, conditionType });
    }
    return compare({ a: nodeValue, b: Number.parseFloat(conditionValue), conditionType });
  }

  // handle dates
  if (typeof nodeValue === 'string' && nodeValue.length === 25) {
    // if length = 10 use only date to check
    const nodeDateTime = getDateTimeFromISO(
      (String(conditionValue).length === 10 ? nodeValue.slice(0, 10) : nodeValue) as ISODateTime,
    );
    const conditionDateTime = getDateTimeFromISO(conditionValue as ISODateTime);

    if (nodeDateTime.isValid && conditionDateTime.isValid) {
      return compare({ a: nodeDateTime, b: conditionDateTime, conditionType });
    }
  }

  return false;
};

interface CompareOptions {
  a: number | DateTime;
  b: number | DateTime;
  conditionType: CompareTypes;
}

const compare = ({ a, b, conditionType }: CompareOptions) => {
  switch (conditionType) {
    case AtrigamValidationTypes.ValueIsLowerThan: {
      return a < b;
    }

    case AtrigamValidationTypes.ValueIsLowerThanOrEqual: {
      return a <= b;
    }

    case AtrigamValidationTypes.ValueIsGreaterThanOrEqual: {
      return a >= b;
    }

    case AtrigamValidationTypes.ValueIsGreaterThan: {
      return a > b;
    }

    default: {
      throw new UnhandledCaseError(conditionType);
    }
  }
};
