import { UnhandledCaseError } from '../utilities/UnhandledCaseError';

import { AtrigamDataType } from './atrigam-datatypes';
import { DATATYPE_FORMAT_NOT_AVAILABLE } from './dataTypes.constants';
import { formatDataTypeArray } from './dataTypes/Array/formatDataType.array';
import { formatDataTypeBoolean } from './dataTypes/Boolean/formatDataType.boolean';
import { formatDataTypeDate } from './dataTypes/Date/formatDataType.date';
import { formatDataTypeDateTime } from './dataTypes/DateTime/formatDataType.datetime';
import { formatDataTypeEmail } from './dataTypes/Email/formatDataType.email';
import { formatDataTypeFixedDuration } from './dataTypes/FixedDuration/formatDataType.fixedDuration';
import { formatDataTypeMoney } from './dataTypes/Money/formatDataType.money';
import { formatDataTypeNumber } from './dataTypes/Number/formatDataType.number';
import { formatDataTypeObject } from './dataTypes/Object/formatDataType.object';
import { formatDataTypeOpenDuration } from './dataTypes/OpenDuration/formatDataType.openDuration';
import { formatDataTypePhone } from './dataTypes/Phone/formatDataType.phone';
import { formatDataTypeSignature } from './dataTypes/Signature/formatDataType.signature';
import { formatDataTypeString } from './dataTypes/String/formatDataType.string';
import { GeneralFormatDataTypeFunction } from './formatDataType.types';

// export const formatDataType: FormatDataTypeFunction<AtrigamDataType> = (options) => {
export const formatDataType: GeneralFormatDataTypeFunction = ({ dataType, value, options }) => {
  switch (dataType) {
    case AtrigamDataType.Array: {
      return formatDataTypeArray({ dataType, value });
    }

    case AtrigamDataType.Boolean: {
      return formatDataTypeBoolean({ dataType, value, options: { language: options?.language } });
    }

    case AtrigamDataType.Date: {
      return formatDataTypeDate({
        dataType,
        value,
        options: { dateFormat: options?.dateFormat, relativeDateTime: options?.relativeDateTime },
      });
    }

    case AtrigamDataType.DateTime: {
      return formatDataTypeDateTime({
        dataType,
        value,
        options: {
          dateTimeFormat: options?.dateTimeFormat,
          relativeDateTime: options?.relativeDateTime,
        },
      });
    }

    case AtrigamDataType.Email: {
      return formatDataTypeEmail({ dataType, value });
    }

    case AtrigamDataType.FixedDuration: {
      return formatDataTypeFixedDuration({
        dataType,
        value,
        options: {
          language: options?.language,
        },
      });
    }

    case AtrigamDataType.Integer:
    case AtrigamDataType.Number: {
      return formatDataTypeNumber({
        dataType,
        value,
        options: {
          numberLanguage: options?.numberLanguage,
          numberFormat: options?.numberFormat,
        },
      });
    }

    case AtrigamDataType.Money: {
      return formatDataTypeMoney({
        dataType,
        value,
        options: {
          displayCurrency: options?.displayCurrency,
          fxRates: options?.fxRates,
          numberLanguage: options?.numberLanguage,
        },
      });
    }

    case AtrigamDataType.Object: {
      return formatDataTypeObject({ dataType, value });
    }

    case AtrigamDataType.OpenDuration: {
      return formatDataTypeOpenDuration({
        dataType,
        value,
        options: {
          language: options?.language,
        },
      });
    }

    case AtrigamDataType.Phone: {
      return formatDataTypePhone({ dataType, value });
    }

    case AtrigamDataType.String:
    case AtrigamDataType.Text: {
      return formatDataTypeString({ dataType, value });
    }

    case AtrigamDataType.Signature: {
      return formatDataTypeSignature({
        dataType,
        value,
        options: {
          language: options?.language,
        },
      });
    }

    // unsupported
    case AtrigamDataType.FileList:
    case AtrigamDataType.Image:
    case AtrigamDataType.ImageList:
    case AtrigamDataType.Location: {
      return DATATYPE_FORMAT_NOT_AVAILABLE;
    }

    default: {
      throw new UnhandledCaseError(dataType);
    }
  }
};
