import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';

import { updateObjectSubscriptionLastViewedMutation } from '../mutations/updateObjectSubscriptionLastViewed.mutation';
import { updateUserSubscriptionLastViewedMutation } from '../mutations/updateUserSubscriptionLastViewed.mutation';
import { getObjectSubscriptionForUserQuery } from '../queries/getObjectSubscriptionForUser.query';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const updateSubscriptionLastViewed = async ({
  area,
  environment,
  node,
  objectName,
  taskFlow,
  uid,
  universe,
}: Options) => {
  const objectSubscription = await getObjectSubscriptionForUserQuery({
    universe,
    environment,
    objectName,
    node,
    uid,
  });

  if (objectSubscription) {
    // TODO error? if not

    await updateObjectSubscriptionLastViewedMutation({
      environment,
      id: objectSubscription.id,
      objectName,
      universe,
    });
  }

  await updateUserSubscriptionLastViewedMutation({
    area,
    environment,
    node,
    taskFlow,
    uid,
    universe,
  });
};
