import { AtrigamDataType } from '../../atrigam-datatypes';
import { FormatDataTypeFunction } from '../../formatDataType.types';
import { createTranslate } from '../../helpers/createTranslate';

export const formatDataTypeSignature: FormatDataTypeFunction<AtrigamDataType.Signature> = ({
  value,
  options: { language = 'en' },
}) => {
  if (value === true) {
    return translate({ language, key: 'Signed' });
  }

  return translate({ language, key: 'Unsigned' });
};

const translations = {
  de: {
    Signed: 'Signiert',
    Unsigned: 'Nicht Signiert',
  },
  en: {
    Signed: 'Signed',
    Unsigned: 'Unsigned',
  },
};

const translate = createTranslate(translations);
