import { AvailableExtensionVersionProcessPreviewFunction } from '../../extension-config-base.types';
import { AtrigamFlowExtensionMeasureTime } from '../measureTime.extensions.types';

export const processPreviewMeasureTime: AvailableExtensionVersionProcessPreviewFunction<
  AtrigamFlowExtensionMeasureTime
> = (extension) => {
  if (extension.config.fromFieldLabel && extension.config.toFieldLabel) {
    return 'From ' + extension.config.fromFieldLabel + ' until ' + extension.config.toFieldLabel;
  }
  return 'From ? until ?';
};
