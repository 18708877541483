import { AtrigamDataType } from '@atrigam/atrigam-types';

import { WorkItemModelEntity } from '../../../stores/ModelsStore/entities/WorkItemModel.entity';

interface Options {
  field: string;
  model?: WorkItemModelEntity;
}

export const getDataTypeForField = ({ field, model }: Options) => {
  if (field === 'createdAt' || field === 'updatedAt') {
    return AtrigamDataType.DateTime;
  }

  if (!model || !model.fields.has(field)) {
    return;
  }

  return model.fields.get(field)!.type;
};
