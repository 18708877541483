import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfigVersion } from '../../extension-config-base.types';
import { AtrigamFlowExtensionAutoId } from '../autoId.extensions.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AtrigamFlowExtensionConfigAutoId_1_0: AtrigamFlowExtensionConfigVersion<AtrigamFlowExtensionAutoId> =
  {
    extensionType: AtrigamFlowExtensionTypes.AutoId,
    version: '1.0',
    description: 'erate an ID according to a predefined pattern upon creation of a new work item.',
    deprecated: true,
    example: 'INV-202005-0008',
    settings: {
      field: {
        __fieldType: 'defaultField',
        required: true,
      },
      description: {
        __fieldType: 'description',
        required: false,
      },
      zerofill: {
        __fieldType: 'additionalField',
        name: 'zerofill',
        type: AtrigamDataType.String,
        required: false,
      },
      length: {
        __fieldType: 'additionalField',
        name: 'zerofillLength',
        type: AtrigamDataType.Integer,
        required: false,
      },
    },
  };
