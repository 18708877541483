import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseKpi,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  CollectionReference,
  DocumentData,
  collection,
  getFirestore,
  query,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamUniverseKpiListPath } from '../paths/createAtrigamUniverseKpiListPath';

interface Options {
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getUniverseKpiListQueryReference = ({
  environment,
  objectName,
  universe,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUniverseKpiListPath({ environment, objectName, universe });
  const reference = collection(database, path) as CollectionReference<
    AtrigamUniverseKpi,
    DocumentData
  >;

  return query(reference);
};
