import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';
import { getDocs } from 'firebase/firestore';

import { getObjectSubscriptionForUserQueryReference } from '../queryReferences/getObjectSubscriptionForUser.query.reference';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  node: AtrigamWorkItemId;
  uid: UID;
}

export const getObjectSubscriptionForUserQuery = async (options: Options) => {
  const query_ = getObjectSubscriptionForUserQueryReference(options);
  const collection_ = await getDocs(query_);

  const list = collection_.docs.map((snapshot) => snapshot.data());
  return list.length > 0 ? list[0] : undefined;
};
