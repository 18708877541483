import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';
import { getDocs } from 'firebase/firestore';

import { getObjectSubscriptionListQueryReference } from '../queryReferences/getObjectSubscription.list.query.reference';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  node: AtrigamWorkItemId;
}

export const getObjectSubscriptionListQuery = async (options: Options) => {
  const query_ = getObjectSubscriptionListQueryReference(options);
  const collection_ = await getDocs(query_);

  return collection_.docs.map((snapshot) => snapshot.data());
};
