import { AtrigamUniverseAreaTaskFlow, AtrigamTaskFlowName } from '../taskFlow.types';
import { AtrigamAreaName, AtrigamUniverseName } from '../universe.types';

export const extractAtrigamUniverseAreaTaskFlow = (
  universeAreaFlow: AtrigamUniverseAreaTaskFlow,
) => {
  const [universe, area, taskFlow] = universeAreaFlow.split(':');

  return {
    area: area as AtrigamAreaName,
    taskFlow: taskFlow as AtrigamTaskFlowName,
    universe: universe as AtrigamUniverseName,
  };
};
