// Atrigam Domain: TaskFlow.Interactions

import { FirestoreTimestamp, ISODateTime, Timestamp } from '../utilities/date.types';

import { Currency, URI } from './common.types';
import { AtrigamRole } from './universe.types';

export enum AtrigamRestrictionActiveState {
  Hidden = 'hidden',
  Inactive = 'inactive',
  Active = 'active',
}

// different Image Variations
export interface ImageVariations {
  midloc?: string;
  miduri?: URI; // biggest

  minloc?: string;
  minuri?: URI; // medium

  thumbloc?: string;
  thumburi?: URI; // smallest
}

export enum AtrigamInteractionType {
  AcceptInput = 'AcceptInput',
  AddressInput = 'AddressInput',
  CodeScan = 'CodeScan',
  CreateUniqueID = 'CreateUniqueID',
  DatePick = 'DatePick',
  DateTimePick = 'DateTimePick',
  EmailInput = 'EmailInput',
  Files = 'Files',
  Flow = 'Flow',
  // IntegerInput = 'IntegerInputIcon',
  Invite = 'Invite',
  Location = 'Location',
  MoneyInput = 'MoneyInput',
  MultiSelect = 'MultiSelect', // not supported anymore
  MultiSelectArray = 'MultiSelectArray',
  NumberInput = 'NumberInput',
  Photo = 'Photo',
  PhoneNumberInput = 'PhoneNumberInput',
  Select = 'Select',
  SetValue = 'SetValue',
  Signature = 'Signature',
  SinglePhoto = 'SinglePhoto',
  Switch = 'Switch',
  TextInput = 'TextInput',
}

export type AtrigamInteractionValueType =
  | string
  | string[]
  | ISODateTime
  | boolean
  | null
  | Record<string, unknown>
  | number;

interface BaseFileMetadata {
  extension: string;
  folder: string;
  name: string;
  size: number;
  type: string | null;
}

// CodeScan
export interface AtrigamInteractionCodeScan {
  interaction: AtrigamInteractionType.CodeScan;
  type: string;
  value: string;
}

// Files
export type AtrigamInteractionFileList = Record<string, AtrigamInteractionFile>;

export interface AtrigamInteractionFile {
  id?: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  fb_storage_location: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  local_path?: string; // make it optional as we don't need it in the web version

  metadata: AtrigamInteractionFileMetadata;

  uploadedAt: FirestoreTimestamp;

  uri: URI; // original
}

export type AtrigamInteractionFileMetadata = BaseFileMetadata;

// Invite
export interface AtrigamInteractionInvite {
  interaction: AtrigamInteractionType.Invite;
  invite: {
    assignRole: AtrigamRole;
  };
}

// Location
export interface AtrigamInteractionLocation {
  coords: {
    accuracy: number;
    altitude: number;
    altitudeAccuracy: number;
    heading: number;
    latitude: number;
    longitude: number;
    speed: number;
  };
  device: string;
  mocked: boolean;
  recording: boolean;
  timestamp: Timestamp;
}

// MoneyInput
export interface AtrigamInteractionMoneyFX {
  currency: Currency;
  fxBase?: Currency;
  fxDate?: Date;
  fxRate?: number;
  fxSource?: URI;
  rawValue: string;
  systemCurrency: Currency;
  value: string;
  valueInSystemCurrency: number;
}

// Photo, SinglePhoto
export type AtrigamInteractionImageList = Record<string, AtrigamInteractionImage>;

export interface AtrigamInteractionImage extends ImageVariations {
  id?: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  fb_storage_location: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  local_path?: string; // make it optional as we don't need it in the web version

  metadata?: AtrigamInteractionImageListItemMetadata;

  picloc?: string;

  /** @deprecated */
  uri?: URI; // original
}

export interface AtrigamInteractionImageListItemMetadata extends BaseFileMetadata {
  height: number | null;
  width: number | null;
}
