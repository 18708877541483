import {
  AtrigamEnvironment,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamModelsModelPath,
  createAtrigamDatabaseUniverse,
} from '@atrigam/atrigam-types';

interface Options {
  environment: AtrigamEnvironment;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

type AtrigamModelsModelUpdatedAtPath = `${AtrigamModelsModelPath}/updatedAt`;

export const createAtrigamModelsModelUpdatedAtPath = ({
  environment,
  universe,
}: Options): AtrigamModelsModelUpdatedAtPath => {
  const databaseUniverse = createAtrigamDatabaseUniverse({ universe });
  return `/atrigam-model-${environment}/${databaseUniverse}/updatedAt`;
};
