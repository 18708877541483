import {
  getUniverseModelQuery,
  watcherServiceModels,
  watchModelsModelUpdatedAt,
} from '@atrigam/server-functions-eu-client';

import { withReaction } from '../../../helpers/withReaction';
import { Registry } from '../../../services/Registry/Registry';
import { UniverseModelEntity } from '../entities/UniverseModel.entity';

export const watchModel = (universeModel: UniverseModelEntity) => {
  const userStore = Registry.get('userStore');

  const disposer = withReaction({
    name: 'ModelModel.watchModel',
    data: () => ({
      environment: universeModel.environment,
      universe: universeModel.universe,
      isInitialized: userStore.isInitialized,
    }),
    fireImmediately: true,
    onChange: ({ environment, universe, isInitialized }) => {
      // wait till we have something to do
      // also wait until the userStore has been initialized or we run into permission errors
      if (!environment || !universe || !isInitialized) {
        return;
      }

      const unsubscribe = watchModelsModelUpdatedAt({
        environment,
        universe,
        onUpdate: async (updatedAt, key) => {
          const modelsStore = Registry.get('modelsStore');

          const model = modelsStore.getUniverseModel({ environment, universe });
          if (!model) {
            if (key) {
              watcherServiceModels.get().unsubscribe(key);
            }
            return;
          }

          // no change
          if (model.updatedAt === updatedAt) {
            return;
          }

          // changes
          const modelData = await getUniverseModelQuery({ environment, universe });
          if (modelData) {
            modelsStore.updateUniverseModelForEnvironment({ environment, modelData });
          }
        },
      });
      universeModel.disposers.push(() => {
        unsubscribe();
      });
    },
  });

  universeModel.disposers.push(disposer);
};
