import { UID } from '@atrigam/atrigam-types';
import { getDocs } from 'firebase/firestore';

import { getUserNotificationListQueryReference } from '../queryReferences/getUserNotification.list.query.reference';

interface Options {
  uid: UID;
}

export const getUserNotificationListQuery = async ({ uid }: Options) => {
  const query_ = getUserNotificationListQueryReference({ uid });

  const collection_ = await getDocs(query_);
  return collection_.docs.map((snapshot) => snapshot.data());
};
