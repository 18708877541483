import { AtrigamFXRates } from '@atrigam/atrigam-types';

import { createAtrigamFXRatesPath } from '../firestore/paths/createAtrigamFXRatesPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

export const watchFxRates: WatcherFunction<AtrigamFXRates> = ({ onUpdate, onError }) => {
  const firestoreService = watcherServiceEu.get();

  const path = createAtrigamFXRatesPath();

  return firestoreService.subscribeToDocument<AtrigamFXRates>({
    path,
    onSnapshot: (snapshot, key) => {
      const fxRates = snapshot.data();
      if (!fxRates) {
        return;
      }

      void onUpdate(fxRates, key);
    },
    onError,
  });
};
