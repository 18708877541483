import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfigVersion } from '../../extension-config-base.types';
import { processPreviewAutomaticInvites } from '../helpers/processPreviewAutomaticInvites';

import { AtrigamFlowExtensionAutomatedInvites_1_0 } from './automatedInvites.1.0.extensions.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AtrigamFlowExtensionConfigAutomatedInvites_1_0: AtrigamFlowExtensionConfigVersion<AtrigamFlowExtensionAutomatedInvites_1_0> =
  {
    extensionType: AtrigamFlowExtensionTypes.AutomatedInvites,
    version: '1.0',
    description: 'Send invites to specific users automatically based on conditions.',
    example: 'sue@company.com, joe@company.com',
    settings: {
      description: {
        __fieldType: 'description',
        required: false,
      },
      invites: {
        __fieldType: 'conditionsObject',
        name: 'invites',
        conditions: true,
        required: true,
      },
    },
    processPreview: processPreviewAutomaticInvites,
  };
