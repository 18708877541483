import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseId,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
  AtrigamWorkItemPath,
  createAtrigamDatabaseUniverseEnvironment,
} from '@atrigam/atrigam-types';

interface Options {
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName | AtrigamUniverseId;
}

export const createAtrigamWorkItemPath = ({
  environment,
  node,
  objectName,
  universe,
}: Options): AtrigamWorkItemPath => {
  const databaseUniverseEnvironment = createAtrigamDatabaseUniverseEnvironment({
    universe,
    environment,
  });

  return `/universes/${databaseUniverseEnvironment}/objects/${objectName}/data/${node}`;
};
