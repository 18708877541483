import { Backdrop } from '@mui/material';

import { reactive } from '../../../../helpers/reactive';
import { Registry } from '../../../../services/Registry/Registry';

import { TransitionMessage } from './styles/TransitionMessage.style';

const RouterTransitionComponent: React.FC = () => {
  const router = Registry.get('router');

  const message = router.transitionMessage;
  const isOpen = router.inTransition && message !== undefined;

  return (
    <Backdrop open={isOpen}>
      <TransitionMessage>{message}</TransitionMessage>
    </Backdrop>
  );
};

export const RouterTransition = reactive(RouterTransitionComponent);
