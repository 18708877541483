import { Registry } from '../../Registry/Registry';
import { Language } from '../../../typings/language';

import { collectTranslations } from './collectTranslations';

export const toggleLanguage = () => {
  const translationService = Registry.get('translations');

  const language = translationService.language === Language.en ? Language.de : Language.en;

  const translations = collectTranslations(language);
  const timezone = translationService.timezone;

  translationService.updateTranslations({ language, timezone, translations });
};
