import { AtrigamAppEnvironment, setupTracking } from '@atrigam/atrigam-tracking';
import { AtrigamApps } from '@atrigam/atrigam-types';

import { getBrowserInfo } from '../../helpers/getBrowserInfo';
import { IS_BETA, IS_DEV, IS_PROD, IS_TEST } from '../../mode';

interface Options {
  appVersion: string;
}

// introduce appEnvironment
let appEnvironment = AtrigamAppEnvironment.Production;
if (IS_DEV) {
  appEnvironment = AtrigamAppEnvironment.Development;
} else if (IS_TEST) {
  appEnvironment = AtrigamAppEnvironment.Testing;
} else if (IS_BETA) {
  appEnvironment = AtrigamAppEnvironment.Beta;
}

export const initializeTracking = ({ appVersion }: Options) => {
  setupTracking({
    app: AtrigamApps.Web,
    appVersion,
    appEnvironment,
    isEnabled: IS_PROD,

    uid: 'unregistered',
    // web specific
    Browser: getBrowserInfo(),
  });
};
