import { difference, isNil } from 'rambda';

import { isAtrigamInteractionCodeScan } from '../../domain';
import { getDateTimeFromISO, ISODateTime } from '../../utilities';

interface Options {
  conditionValue?: string | number;
  nodeValue: unknown;
}

export const validateConditionValueIsNot = ({ conditionValue, nodeValue }: Options) => {
  // verify both values are not undefined
  if (isNil(nodeValue) || isNil(conditionValue) || conditionValue === undefined) {
    return false;
  }

  // quick check if they are the same
  if (nodeValue === conditionValue) {
    return false;
  }

  // handle number values
  if (typeof nodeValue === 'number') {
    if (typeof conditionValue === 'number') {
      return nodeValue !== conditionValue;
    }
    return nodeValue !== Number.parseFloat(conditionValue);
  }

  // handle objects
  if (typeof nodeValue === 'object' && nodeValue !== null) {
    // handle code scan
    if (isAtrigamInteractionCodeScan(nodeValue)) {
      return nodeValue.value !== conditionValue;
    }

    // handle multiselect
    const nodeValues = Object.values(nodeValue);
    const conditionValues = String(conditionValue)
      .split(',')
      .map((cv) => cv.trim());

    return (
      difference(nodeValues, conditionValues).length > 0 ||
      nodeValues.length !== conditionValues.length
    );
  }

  // handle dates
  if (typeof nodeValue === 'string' && nodeValue.length === 25) {
    const nodeDateTime = getDateTimeFromISO(nodeValue as ISODateTime);
    if (nodeDateTime.isValid) {
      // handle date
      if (String(conditionValue).length === 10) {
        return !nodeValue.includes(String(conditionValue));
      }

      // handle dateTime
      const conditionDateTime = getDateTimeFromISO(conditionValue as ISODateTime);
      if (conditionDateTime.isValid) {
        return !conditionDateTime.equals(nodeDateTime);
      }
    }
  }

  return true;
};
