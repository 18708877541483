import { isAtrigamInteractionMoneyFX } from '../../../domain/guards/interactions/isAtrigamInteractionMoneyFX';
import { AtrigamInteractionMoneyFX } from '../../../domain/taskFlow.interactions.types';
import { AtrigamDataType } from '../../atrigam-datatypes';
import { FormatDataTypeFunction } from '../../formatDataType.types';
import { formatDataTypeNumber } from '../Number/formatDataType.number';

import { calculateSystemValueIntoCurrency } from './helpers/format/calculateSystemValueIntoCurrency';
import { formatAverageCurrency } from './helpers/format/formatAverageCurrency';

// default to german is by design
export const formatDataTypeMoney: FormatDataTypeFunction<AtrigamDataType.Money> = ({
  value,
  options: { displayCurrency: subscriptionDisplayCurrency, fxRates, numberLanguage = 'de' },
}) => {
  const language = numberLanguage;

  // fall back
  if (!isAtrigamInteractionMoneyFX(value)) {
    // handle if moneyFx field is just broken
    if (
      typeof value === 'object' &&
      value !== null &&
      (value as AtrigamInteractionMoneyFX).valueInSystemCurrency
    ) {
      return formatDataTypeNumber({
        value: (value as AtrigamInteractionMoneyFX).valueInSystemCurrency,
        dataType: AtrigamDataType.Number,
        options: { numberLanguage },
      });
    }

    return formatDataTypeNumber({
      value,
      dataType: AtrigamDataType.Number,
      options: { numberLanguage },
    });
  }

  let displayCurrency = value.systemCurrency;
  let displayValue = value.valueInSystemCurrency;

  // user has a different currency
  if (subscriptionDisplayCurrency && displayCurrency !== subscriptionDisplayCurrency) {
    const calculated = calculateSystemValueIntoCurrency({
      value: value.valueInSystemCurrency,
      targetCurrency: subscriptionDisplayCurrency,
      fxRates,
    });
    if (calculated) {
      displayCurrency = subscriptionDisplayCurrency;
      displayValue = calculated;
    }
  }

  // show display value if missing
  let appendix = '';
  if (displayCurrency !== value.currency) {
    const appendixValue = formatAverageCurrency({
      value: displayValue,
      currency: displayCurrency,
      language,
    });
    appendix = ` (${appendixValue})`;
  }

  const averageValue = formatAverageCurrency({
    value: Number.parseFloat(value.rawValue),
    currency: value.currency,
    language,
  });

  return `${averageValue}${appendix}`;
};
