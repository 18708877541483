import { AtrigamDataType, ISODateTime } from '@atrigam/atrigam-types';
import { GetAllWorkItemsQueryFilter } from '@atrigam/server-functions-eu-client';

interface Options {
  activeFilters: Map<string, unknown[]>;
  allAvailableFilters: Map<
    string,
    {
      label: string;
      value: string;
      dataType: AtrigamDataType;
    }
  >;
}

export const convertActiveFiltersToQueryFilters = ({
  activeFilters,
  allAvailableFilters,
}: Options): GetAllWorkItemsQueryFilter[] => {
  const filters: GetAllWorkItemsQueryFilter[] = [];
  activeFilters.forEach((values, filter) => {
    const availableFilter = allAvailableFilters.get(filter);
    if (!availableFilter?.dataType) {
      return;
    }

    switch (availableFilter.dataType) {
      case AtrigamDataType.Date:
      case AtrigamDataType.DateTime: {
        if (values.length !== 2) {
          return;
        }

        filters.push({
          type: 'dateRange',
          field: filter,
          from: values[0] as ISODateTime,
          to: values[1] as ISODateTime,
        });
        return;
      }

      default: {
        if (values.length === 0) {
          return;
        }

        filters.push({
          type: 'default',
          field: filter,
          values: values as string[],
        });

        return;
      }
    }
  });
  return filters;
};
