import { AtrigamDataType } from '../../atrigam-datatypes';
import { ParseDataTypeFunction } from '../../parseDataType.types';

export const parseDataTypeArray: ParseDataTypeFunction<AtrigamDataType.Array> = ({ value }) => {
  if (typeof value === 'string') {
    const result = value.split(',').map((v) => v.trim());
    return { value: result };
  }

  return;
};
