import { DestructibleService } from '@atrigam/atrigam-service-registry';
import { AtrigamEnvironment, AtrigamModelsModel, throwIfNullable } from '@atrigam/atrigam-types';
import { action, makeObservable, observable, values } from 'mobx';
import { persist } from 'mobx-persist';

import { filterTaskFlowModelsForUserRoles } from '../helpers/filterFlowdataForUserRoles';

import { UniverseModelEntity } from './UniverseModel.entity';

export class EnvironmentModelsEntity extends DestructibleService {
  @persist
  @observable
  environment?: AtrigamEnvironment;

  @persist('map', UniverseModelEntity)
  @observable
  models = observable.map<string, UniverseModelEntity>();

  constructor(data?: { environment: AtrigamEnvironment }) {
    super();
    makeObservable(this);

    if (data) {
      this.environment = data.environment;
    }

    // call all destruct services
    this.disposers.push(() => this.removeAllModels());
  }

  @action
  removeModel = (universe: string) => {
    const model = this.models.get(universe);
    if (!model) {
      return;
    }

    model._destruct();
    this.models.delete(universe);
  };

  @action
  updateModel = (modelData: AtrigamModelsModel) => {
    const universe = modelData.universe.toLowerCase();
    throwIfNullable('environment cannot be undefined', this.environment);

    // create
    if (!this.models.has(universe)) {
      return this.models.set(
        modelData.universe.toLowerCase(),
        new UniverseModelEntity({
          environment: this.environment,
          data: filterTaskFlowModelsForUserRoles({ environment: this.environment, modelData }),
        }),
      );
    }

    // update
    this.models
      .get(universe)!
      .update(filterTaskFlowModelsForUserRoles({ environment: this.environment, modelData }));
  };

  @action
  private removeAllModels = () => {
    values(this.models).forEach((model) => model._destruct());
    this.models.clear();
  };
}
