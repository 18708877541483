import { isValidPhoneNumber } from '../../../utilities/isValidPhoneNumber';
import { AtrigamDataType } from '../../atrigam-datatypes';
import { ParseDataTypeFunction } from '../../parseDataType.types';
import { parseDataTypeString } from '../String/parseDataType.string';

export const parseDataTypePhone: ParseDataTypeFunction<AtrigamDataType.Phone> = ({ value }) => {
  const parsed = parseDataTypeString({ value, dataType: AtrigamDataType.String });
  if (!parsed?.value) {
    return;
  }

  if (!isValidPhoneNumber(parsed.value)) {
    return;
  }

  return parsed;
};
