import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AvailableExtensionVersionProcessPreviewFunction } from '../../extension-config-base.types';
import { AtrigamFlowExtensionCalculatedValue2 } from '../calculatedValue2.extensions.types';

export const processPreviewCalculatedValue2: AvailableExtensionVersionProcessPreviewFunction<
  AtrigamFlowExtensionCalculatedValue2
> = (extension) => {
  let calculations = '';
  let unit = '';
  if (extension.config.calculations) {
    calculations = Object.values(extension.config.calculations)
      .slice(0, 5)
      .join('/')
      .replaceAll('[', '')
      .replaceAll(' ', '')
      .replaceAll(']', '');
  }

  if (calculations === '') {
    calculations = '?';
  }

  if (
    extension.config.unit && // unit only goes with number
    extension.config.type === AtrigamDataType.Number
  ) {
    unit = ` ${extension.config.unit}`;
  }

  return calculations + unit;
};
