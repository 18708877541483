import {
  UID,
  AtrigamEnvironment,
  AtrigamWorkItemId,
  AtrigamUserSubscriptionPath,
  AtrigamUniverseName,
  AtrigamAreaName,
  AtrigamTaskFlowName,
  createAtrigamUniverseAreaTaskFlow,
} from '@atrigam/atrigam-types';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const createAtrigamUserSubscriptionPath = ({
  area,
  environment,
  node,
  taskFlow,
  uid,
  universe,
}: Options): AtrigamUserSubscriptionPath => {
  const universeAreaTaskFlow = createAtrigamUniverseAreaTaskFlow({ universe, area, taskFlow });
  return `/userSubscriptions/${uid}/${environment}/${universeAreaTaskFlow}/data/${node}`;
};
