import { getNumbro } from './getNumbro';

interface Options {
  value: number;
  mantissa?: number;
  language?: 'de' | 'en';
}

export const formatNumberAbbreviated = ({ value, mantissa = 1, language = 'en' }: Options) => {
  const numbro = getNumbro({ language });

  return numbro(value || 0).format({
    average: true,
    trimMantissa: true,
    mantissa,
  });
};
