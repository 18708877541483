import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamObjectSubscription,
  AtrigamUniverseName,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';

import { createAtrigamObjectSubscriptionListPath } from '../firestore/paths/createAtrigamObjectSubscriptionListPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
}

export const watchObjectSubscriptionList: WatcherFunction<AtrigamObjectSubscription[], Options> = ({
  universe,
  environment,
  objectName,
  node,
  onUpdate,
  onError,
}) => {
  const firestoreService = watcherServiceEu.get();
  const path = createAtrigamObjectSubscriptionListPath({ environment, objectName, universe });

  return firestoreService.subscribeToCollection<AtrigamObjectSubscription>({
    path,
    where: [{ fieldPath: 'node', opStr: '==', value: node }],
    onSnapshot: (collectionSnapshot, key) => {
      if (collectionSnapshot.size === 0) {
        void onUpdate([], key);
        return;
      }

      const subscriptions = collectionSnapshot.docs.map((snapshot) => snapshot.data());
      void onUpdate(subscriptions, key);
    },
    onError,
  });
};
