import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUserSubscription,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';

import { createAtrigamUserSubscriptionPath } from '../firestore/paths/createAtrigamUserSubscriptionPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const watchUserSubscription: WatcherFunction<AtrigamUserSubscription, Options> = ({
  area,
  environment,
  node,
  onError,
  onUpdate,
  taskFlow,
  uid,
  universe,
}) => {
  const firestoreService = watcherServiceEu.get();

  const path = createAtrigamUserSubscriptionPath({
    area,
    environment,
    node,
    taskFlow,
    uid,
    universe,
  });

  return firestoreService.subscribeToDocument<AtrigamUserSubscription>({
    path,
    onSnapshot: (snapshot, key) => {
      const userSubscription = snapshot.data();
      void onUpdate(userSubscription, key);
    },
    onError,
  });
};
