import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItem,
  AtrigamWorkItemId,
  AtrigamWorkItemOrigin,
  UID,
  createNewSystemISODateTime,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamWorkItemPath } from '../paths/createAtrigamWorkItemPath';

import { createNewWorkItemIdMutation } from './createNewWorkItemId.mutation';

interface Options {
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  reservedNode?: AtrigamWorkItemId;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  uid: UID;
  workItem: Partial<AtrigamWorkItem>;
}

export const createWorkItemMutation = async ({
  environment,
  objectName,
  reservedNode,
  universe,
  uid,
  workItem,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const node = reservedNode ?? createNewWorkItemIdMutation({ universe, environment, objectName });

  const path = createAtrigamWorkItemPath({ environment, node, objectName, universe });
  const document = doc(database, path);

  const createdAt = createNewSystemISODateTime();
  const updatedAt = getNowFirestoreTimestamp();

  const workItemData: AtrigamWorkItem = {
    id: node,
    createdAt,
    createdBy: uid,
    updatedAt,
    updatedBy: uid,
    origin: AtrigamWorkItemOrigin.Client,
    ...workItem,
  };

  await setDoc(document, mapUndefinedToNullValue(workItemData));

  return workItemData;
};
