import { AnyDateString } from '../../../utilities/date.types';
import { getDateISO } from '../../../utilities/dateUtilities/getDateISO';
import { getDateTime } from '../../../utilities/dateUtilities/getDateTime';
import { isDate } from '../../../utilities/guards/isDate';
import { AtrigamDataType } from '../../atrigam-datatypes';
import { ParseDataTypeFunction } from '../../parseDataType.types';

export const parseDataTypeDate: ParseDataTypeFunction<AtrigamDataType.Date> = ({ value }) => {
  if (typeof value !== 'string' && !isDate(value)) {
    return;
  }

  const dateTime = getDateTime(value as AnyDateString);
  if (!dateTime.isValid) {
    return;
  }

  return { value: getDateISO(dateTime) };
};
