import { AtrigamUserNotification, AtrigamWorkItem } from '@atrigam/atrigam-types';
import { getWorkItemFromCacheQuery } from '@atrigam/server-functions-eu-client';
import React from 'react';

interface Options {
  notification: AtrigamUserNotification;
}

export const useLoadWorkItem = ({ notification }: Options) => {
  const [workItem, setWorkItem] = React.useState<AtrigamWorkItem>();
  const [loadingWorkItem, setLoadingWorkItem] = React.useState(false);

  React.useEffect(() => {
    if (
      notification.workitemId &&
      notification.environment &&
      notification.objectName &&
      notification.universe
    ) {
      setLoadingWorkItem(true);
      void getWorkItemFromCacheQuery({
        environment: notification.environment,
        node: notification.workitemId,
        objectName: notification.objectName,
        universe: notification.universe,
      })
        .then((value) => {
          setLoadingWorkItem(false);
          if (value) {
            setWorkItem(value);
          }
        })
        .catch(() => {
          setLoadingWorkItem(false);
        });
    }
  }, [
    notification.environment,
    notification.objectName,
    notification.universe,
    notification.workitemId,
  ]);

  return { workItem, loadingWorkItem };
};
