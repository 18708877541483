import { AtrigamFlowExtensionSettingNames } from '@atrigam/atrigam-extensions';
import {
  AtrigamEnvironment,
  AtrigamFlowExtensionSettingsPath,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  createAtrigamDatabaseUniverseEnvironment,
} from '@atrigam/atrigam-types';

interface Options {
  environment: AtrigamEnvironment;
  extensionName: AtrigamFlowExtensionSettingNames;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const createAtrigamFlowExtensionSettingsPath = ({
  environment,
  extensionName,
  objectName,
  universe,
}: Options): AtrigamFlowExtensionSettingsPath => {
  const databaseUniverseEnvironment = createAtrigamDatabaseUniverseEnvironment({
    universe,
    environment,
  });

  return `/universes/${databaseUniverseEnvironment}/objects/${objectName}/extensionSettings/${extensionName}`;
};
