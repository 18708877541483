import { Currency } from '../../../../../domain/common.types';
import { formatNumber } from '../../../Number/helpers/formatNumber';
import { getNumbro } from '../../../Number/helpers/getNumbro';

interface Options {
  value: number;
  currency: Currency;
  language?: 'de' | 'en';
}

export const formatAverageCurrency = ({ value, currency, language }: Options) => {
  // create average from bigger values
  const template = formatNumber({
    value: 0,
    language,
    options: {
      currency,
      style: 'currency',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      maximumSignificantDigits: 1,
    },
  });

  const numbro = getNumbro({ language });

  const averageValue = numbro(value).format({
    average: true,
    mantissa: 1,
  });

  return template.replace('0', averageValue);
};
