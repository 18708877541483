import {
  AtrigamDatabaseUniverseEnvironment,
  AtrigamObjectName,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';

export const UNIVERSE_DATA_STORAGE_BUCKET_URL = 'gs://atrigam-eu-universe-data';

export type AtrigamStorageUniverseDataFieldPath =
  `${AtrigamDatabaseUniverseEnvironment}/${AtrigamObjectName}/${AtrigamWorkItemId}/${string}`;
