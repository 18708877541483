import { DestructibleService } from '@atrigam/atrigam-service-registry';
import { updateTracking } from '@atrigam/atrigam-tracking';
import {
  AtrigamEnvironment,
  AtrigamObjectSubscription,
  AtrigamSubscriptionState,
  AtrigamUserSubscription,
  AtrigamWorkItemId,
  throwIfNullable,
  type AtrigamRole,
} from '@atrigam/atrigam-types';
import { updateUserSubscriptionResetChangedFieldsMutation } from '@atrigam/server-functions-eu-client';
import { action, computed, makeObservable, observable } from 'mobx';

import { SubscriptionEntity } from '../../../stores/SubscriptionsStore/entities/Subscription.entity';
import { WorkItemModelEntity } from '../../../stores/ModelsStore/entities/WorkItemModel.entity';
import { TaskFlowModelEntity } from '../../../stores/ModelsStore/entities/TaskFlowModel.entity';
import { Registry } from '../../../services/Registry/Registry';

import { ChatStore } from './Chat/Chat.store';
import { InteractionsStore } from './Interactions/Interactions.store';
import { TeamStore } from './Interactions/Team.store';
import { UniverseRecordDetailEntity } from './Interactions/entities/UniverseRecordDetail.entity';
import { isEditWorkItemData } from './Interactions/helpers/isEditWorkItemData';
import { watchForFlowUpdates } from './Interactions/helpers/watchForFlowUpdates';
import { watchWorkItemAndObjectSubscriptions } from './Interactions/helpers/watchWorkItemAndObjectSubscriptions';
import { CreateWorkItemData, EditWorkItemData } from './WorkItem.page.store.types';

export class WorkItemPageStore extends DestructibleService {
  @observable
  environment: AtrigamEnvironment;

  @observable
  taskFlowModel: TaskFlowModelEntity;

  @observable
  taskFlowModelUpdate?: TaskFlowModelEntity;

  @observable
  isSandboxTestingEnabled = false;

  @observable
  workItemModel: WorkItemModelEntity;

  @observable
  objectSubscriptionList?: AtrigamObjectSubscription[];

  @observable
  userSubscription?: SubscriptionEntity;

  @observable
  chatStore: ChatStore;

  @observable
  interactionsStore: InteractionsStore;

  @observable
  teamStore: TeamStore = new TeamStore();

  @observable
  headerSmall = false;

  @observable
  sandboxRole?: AtrigamRole;

  constructor(data: CreateWorkItemData | EditWorkItemData) {
    super();

    makeObservable(this);

    this.environment = data.environment;
    this.taskFlowModel = data.taskFlow;
    this.workItemModel = data.workItemModel;

    // setup interactionsStore
    this.interactionsStore = new InteractionsStore({ pageStore: this, data });
    this.disposers.push(() => this.interactionsStore._destruct());

    // setup chatStore
    this.chatStore = new ChatStore({
      pageStore: this,
      userSubscription: this.userSubscription,
    });
    // cleanup chatStore afterwards
    this.disposers.push(() => this.chatStore._destruct());

    if (isEditWorkItemData(data)) {
      this.setSubscriptions({
        userSubscription: data.userSubscription,
        objectSubscriptionList: data.objectSubscriptionList,
        node: data.node,
      });
    }

    // setup Tracking Information
    updateTracking({
      area: data.taskFlow.area,
      flow: data.taskFlow.taskFlow,
      flowVersion: data.taskFlow.version,
      object: data.taskFlow.objectName,
      roles: this.subscribedUserRoles,
      universe: data.taskFlow.universe,
      workitemId: data.workItem.id,
    });

    this.disposers.push(() => {
      // reset context again
      updateTracking({
        area: undefined,
        flow: undefined,
        flowVersion: undefined,
        object: undefined,
        roles: undefined,
        universe: undefined,
        workitemId: undefined,
      });
    });

    // prepare steps
    watchForFlowUpdates(this);
  }

  @computed
  get basicData() {
    const universe = this.taskFlowModel.universe;
    const area = this.taskFlowModel.area;
    const taskFlow = this.taskFlowModel.taskFlow;
    const node = this.interactionsStore.workItem.id;
    const objectName = this.taskFlowModel.objectName;

    throwIfNullable('area cannot be null', area);
    throwIfNullable('taskFlow cannot be null', taskFlow);
    throwIfNullable('objectName cannot be null', objectName);
    throwIfNullable('universe cannot be null', universe);

    return {
      universe,
      area,
      taskFlow,
      node,
      objectName,
      environment: this.environment,
    };
  }

  @computed
  get canEnableSandboxTesting() {
    return this.isFlowOwner && this.environment === AtrigamEnvironment.Testing;
  }

  /**
   * only get the count of changed fields of fields i am allowed to see
   */
  @computed
  get visibleChangedFieldsCount() {
    // shortcut, if there are no changedFields, nothing to do
    if ((this.userSubscription?.userSubscription.changedFields ?? []).length === 0) {
      return 0;
    }

    return this.recordDetail.filter((model) => model.hasFieldChanged).length;
  }

  @computed
  get userSubscriptionChangedFieldsCount() {
    return (this.userSubscription?.userSubscription.changedFields ?? []).length;
  }

  @computed
  get displayCurrency() {
    if (this.userSubscription?.displayCurrency) {
      return this.userSubscription.displayCurrency;
    }

    return Registry.get('translations').systemCurrency;
  }

  @computed
  get hasFlowUpdateWaiting() {
    return this.taskFlowModelUpdate !== undefined;
  }

  @computed
  get isDisabled() {
    // sandbox testing disables the save possibility, it is just for viewing the interface
    if (
      this.isSandboxTestingEnabled &&
      (!this.sandboxRole || !this.subscribedUserRoles.includes(this.sandboxRole))
    ) {
      return true;
    }

    // new work items are not disabled
    if (this.interactionsStore.isNewWorkItem) {
      return false;
    }

    // editing a workitem and subscription state is not accepted
    return (
      this.userSubscription?.userSubscription.subscriptionState !==
      AtrigamSubscriptionState.Accepted
    );
  }

  @computed
  get isFlowOwner() {
    const { user } = Registry.get('userStore');
    if (!user?.email) {
      return false;
    }

    return this.taskFlowModel.ownerList.includes(user.email);
  }

  @computed
  get node() {
    return this.interactionsStore.workItem.id as AtrigamWorkItemId | undefined;
  }

  @computed
  get recordDetail() {
    const roles = this.userRoles;
    const recordDetailInteractions = this.taskFlowModel.getRecordDetailForRoles({ roles });
    const fieldUpdateNotifications =
      this.userSubscription?.userSubscription.fieldUpdateNotifications;
    const fieldNotUpdatedNotifications =
      this.userSubscription?.userSubscription.fieldNotUpdateNotifications;
    const changedFields = this.userSubscription?.userSubscription.changedFields ?? [];

    const fields: UniverseRecordDetailEntity[] = [];
    recordDetailInteractions
      .sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0))
      .forEach((recordDetailInteraction) => {
        throwIfNullable('fieldName cannot be undefined', recordDetailInteraction.fieldName);
        const workItemModelField = this.workItemModel.fields.get(recordDetailInteraction.fieldName);

        // if recordDetail shows fields that are not there anymore
        // or the object field is broken
        if (!workItemModelField?.type) {
          return;
        }

        const isNotifyEnabled =
          (fieldUpdateNotifications &&
            fieldUpdateNotifications[recordDetailInteraction.fieldName]) ??
          false;

        const fieldNotUpdatedNotification =
          fieldNotUpdatedNotifications?.[recordDetailInteraction.fieldName];

        fields.push(
          new UniverseRecordDetailEntity({
            workItemModelField,
            recordDetailInteraction,
            isNotifyEnabled,
            fieldNotUpdatedNotification,
            hasFieldChanged: changedFields.includes(recordDetailInteraction.fieldName),
          }),
        );
      });

    // set last item flag for view
    if (fields.length > 0) {
      const lastIndex = fields.length - 1;
      fields[lastIndex].setIsLastItem();
    }

    return fields;
  }

  /**
   * Only return roles the user has on the subscription and the clientRoles.
   */
  @computed
  get subscribedUserRoles() {
    const userClientRoles = this.userClientRoles;
    if (userClientRoles.length === 0) {
      return [];
    }

    if (!this.userSubscription) {
      return userClientRoles;
    }

    return this.userSubscription.roles.filter((role) => userClientRoles.includes(role));
  }

  @computed
  get universeAreaTaskFlow() {
    return this.taskFlowModel.universeAreaTaskFlow;
  }

  /**
   * Only return roles the user has on the subscription and the clientRoles.
   */
  @computed
  get userRoles() {
    // sandbox view
    if (this.canEnableSandboxTesting && this.isSandboxTestingEnabled && this.sandboxRole) {
      return [this.sandboxRole];
    }

    return this.subscribedUserRoles;
  }

  @computed
  get userClientRoles() {
    const taskFlowModel = this.taskFlowModel;
    const { userClientRoles } = Registry.get('userStore');

    if (!taskFlowModel.universe || !taskFlowModel.area || !taskFlowModel.taskFlow) {
      return [];
    }

    return userClientRoles.getRolesForTaskFlow({
      environment: this.environment,
      universe: taskFlowModel.universe,
      area: taskFlowModel.area,
      taskFlow: taskFlowModel.taskFlow,
    });
  }

  @action
  disableSandboxTesting = () => {
    this.isSandboxTestingEnabled = false;
    this.sandboxRole = undefined;
  };

  @action
  enableSandboxTesting = () => {
    if (!this.canEnableSandboxTesting) {
      return;
    }

    // automatically set a role for the first element
    if (!this.sandboxRole) {
      const role = this.interactionsStore.rolesFromInteractions[0];
      if (role) {
        this.setSandboxRole(role);
      }
    }

    this.isSandboxTestingEnabled = true;
  };

  @action
  resetChangedFields = async () => {
    const { universe, area, taskFlow, environment } = this.basicData;
    const { uid } = Registry.get('userStore');

    if (!this.interactionsStore.workItem.id) {
      return;
    }

    await updateUserSubscriptionResetChangedFieldsMutation({
      environment,
      node: this.interactionsStore.workItem.id,
      uid,
      universe,
      area,
      taskFlow,
    });
  };

  @action
  setSandboxRole = (role?: AtrigamRole) => {
    this.sandboxRole = role;
  };

  @action
  setTaskFlowModelUpdate = (taskFlowModel: TaskFlowModelEntity) => {
    this.taskFlowModelUpdate = taskFlowModel;
  };

  @action
  setHeaderSmall = (small: boolean) => {
    this.headerSmall = small;
  };

  @action
  updateTaskFlowModel = () => {
    if (!this.taskFlowModelUpdate) {
      return;
    }

    // update taskFlow
    this.taskFlowModel = this.taskFlowModelUpdate;
    this.taskFlowModelUpdate = undefined;

    // update workItemModel
    const model = Registry.get('modelsStore').getUniverseModel({
      universe: this.taskFlowModel.universe!,
    });
    if (model) {
      const workItemModel = model.workItemModels.get(this.taskFlowModel.objectName!);
      if (workItemModel) {
        this.workItemModel = workItemModel;
      }
    }

    this.interactionsStore.updateSteps();
  };

  @action
  updateObjectSubscriptionList = (objectSubscriptionList: AtrigamObjectSubscription[]) => {
    this.objectSubscriptionList = objectSubscriptionList;
  };

  @action
  updateUserSubscription = (userSubscription?: AtrigamUserSubscription) => {
    // user subscription has suddenly been revoked/removed
    if (!userSubscription) {
      this.userSubscription = undefined;
      this.chatStore.setUserSubscription(this.userSubscription);
      // todo remove watcher
      return;
    }

    // subscription had an update
    if (this.userSubscription) {
      this.userSubscription.updateSubscription(userSubscription);
      return;
    }

    // subscription is new
    this.userSubscription = new SubscriptionEntity({
      subscription: userSubscription,
      node: userSubscription.node,
    });
    this.chatStore.setUserSubscription(this.userSubscription);
  };

  @action
  setSubscriptions = (data: {
    objectSubscriptionList: AtrigamObjectSubscription[];
    userSubscription: AtrigamUserSubscription;
    node: AtrigamWorkItemId;
  }) => {
    const { uid } = Registry.get('userStore');

    if (this.userSubscription !== undefined) {
      throw new Error('Already got a user subscription, override is NOT allowed');
    }

    if (this.objectSubscriptionList !== undefined) {
      throw new Error('Already got a user objectSubscriptionList, override is NOT allowed');
    }

    this.userSubscription = new SubscriptionEntity({
      subscription: data.userSubscription,
      node: data.node,
      workItem: this.interactionsStore.workItem,
    });
    this.chatStore.setUserSubscription(this.userSubscription);
    this.objectSubscriptionList = data.objectSubscriptionList;

    // watch universe object
    watchWorkItemAndObjectSubscriptions({
      interactionStore: this,
      environment: this.environment,
      node: data.node,
      objectName: this.userSubscription.userSubscription.objectName,
      universe: this.userSubscription.userSubscription.universe,
      area: this.userSubscription.userSubscription.area,
      taskFlow: this.userSubscription.userSubscription.taskFlow,
      uid,
    });
  };

  getSubscribedUserIdsForRole = (inviteRole: AtrigamRole) => {
    if (this.objectSubscriptionList) {
      return this.objectSubscriptionList
        .filter(
          (subscription) =>
            subscription.roles.includes(inviteRole) &&
            subscription.subscriptionState !== AtrigamSubscriptionState.Archived,
        )
        .map((subscription) => subscription.uid);
    }

    return [];
  };
}
