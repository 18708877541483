import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';
import { getDoc } from 'firebase/firestore';

import { getUserSubscriptionQueryReference } from '../queryReferences/getUserSubscription.query.reference';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const getUserSubscriptionQuery = async (options: Options) => {
  const reference = getUserSubscriptionQueryReference(options);

  const snapshot = await getDoc(reference);
  return snapshot.data();
};
