import { UID, AtrigamUserNotificationPath } from '@atrigam/atrigam-types';

interface Options {
  uid: UID;
  id: string;
}

export const createAtrigamUserNotificationPath = ({
  uid,
  id,
}: Options): AtrigamUserNotificationPath => `/userNotifications/${uid}/notifications/${id}`;
