import { AtrigamFlowExtensionTypes } from '../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfig } from '../extension-config-base.types';

import { AtrigamFlowExtensionConfigAutomatedInvites_1_0 } from './1.0/automatedInvites.1.0.extensions.config';
import { AtrigamFlowExtensionAutomatedInvites } from './automatedInvites.extensions.types';

export const AtrigamFlowExtensionConfigAutomatedInvites: AtrigamFlowExtensionConfig<AtrigamFlowExtensionAutomatedInvites> =
  {
    extensionType: AtrigamFlowExtensionTypes.AutomatedInvites,
    name: 'Auto-Invites',
    acronym: '✉',
    materialUiIcon: 'mail_outline',
    color: '#c3e88f',
    versions: [AtrigamFlowExtensionConfigAutomatedInvites_1_0],
  };
