import { AtrigamModelsModelFlowExtensions, ChangeSet } from '@atrigam/atrigam-types';

import { AtrigamFlowExtension, AtrigamFlowExtensionTypes } from '../atrigam-extensions.types';
import { sortExtensionsByPriority } from '../helpers/sortExtensionsByPriority';

import {
  AtrigamFlowExtensionProcessor,
  AtrigamFlowExtensionProcessorType,
  BaseProcessorOptions,
} from './atrigam-processor.types';

interface ProcessExtensionsOptions extends BaseProcessorOptions {
  extensions: AtrigamModelsModelFlowExtensions;
}

export interface AtrigamExtensionsRunnerOptions {
  supportedProcessorType:
    | AtrigamFlowExtensionProcessorType.ClientOnly
    | AtrigamFlowExtensionProcessorType.ServerOnly;
  unsupportedExtension: <T extends AtrigamFlowExtension>(extension: T) => void;
}

export class AtrigamExtensionsRunner {
  private _processors: AtrigamFlowExtensionProcessor<AtrigamFlowExtension>[] = [];

  private _supportedProcessorType: AtrigamExtensionsRunnerOptions['supportedProcessorType'];
  private _unsupportedExtension: AtrigamExtensionsRunnerOptions['unsupportedExtension'];

  private _ignoredExtensionTypes: AtrigamFlowExtensionTypes[] = [];

  constructor(options: AtrigamExtensionsRunnerOptions) {
    this._supportedProcessorType = options.supportedProcessorType;
    this._unsupportedExtension = options.unsupportedExtension;
  }

  addProcessor = <T extends AtrigamFlowExtension>(processor: AtrigamFlowExtensionProcessor<T>) => {
    if (
      AtrigamFlowExtensionProcessorType.All !== processor.processorType &&
      this._supportedProcessorType !== processor.processorType
    ) {
      throw new Error(
        `Processor "${processor.type}" of type "${processor.processorType}" is not supported in this extension processor. Only "${this._supportedProcessorType}".`,
      );
    }
    this._processors.push(processor as AtrigamFlowExtensionProcessor<AtrigamFlowExtension>);
  };

  ignoreExtensionType = (type: AtrigamFlowExtensionTypes) => {
    this._ignoredExtensionTypes.push(type);
  };

  processExtensions = async ({ extensions, ...options }: ProcessExtensionsOptions) => {
    let changes: ChangeSet[] = [];

    await Promise.all(
      (Object.values(extensions) as AtrigamFlowExtension[])
        // only active extensions
        .filter((extension) => extension.active)
        // ignore ignoredExtensionTypes
        .filter((extension) => !this._ignoredExtensionTypes.includes(extension.ext))
        // sort by priority
        .sort(sortExtensionsByPriority)
        // process extension
        .map(async (extension) => {
          const processor = this._findProcessor({ extension });
          if (!processor) {
            this._unsupportedExtension(extension);
            return;
          }

          const processorChanges = await processor.process({
            extension,
            ...options,
          });

          changes = [...changes, ...processorChanges];
        }),
    );

    return changes;
  };

  private _findProcessor = ({ extension }: { extension: AtrigamFlowExtension }) =>
    this._processors.find((p) => p.type === extension.ext && p.supportsVersion(extension.version));
}
