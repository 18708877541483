import { AtrigamUniverseName, UID } from '@atrigam/atrigam-types';
import { getDocs } from 'firebase/firestore';

import { getAllUniverseRegistrationClientRolesForUserListQueryReference } from '../queryReferences/getAllUniverseRegistrationClientRolesForUser.list.query.reference';

interface Options {
  uid: UID;
  universe: AtrigamUniverseName;
}

export const getAllUniverseRegistrationClientRolesForUserListQuery = async (options: Options) => {
  const query_ = getAllUniverseRegistrationClientRolesForUserListQueryReference(options);

  const collection_ = await getDocs(query_);
  return collection_.docs.map((document) => document.data());
};
