import { AtrigamDataType, AtrigamObjectName } from '@atrigam/atrigam-types';

import {
  AtrigamFlowExtensionMeasureTime,
  ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW,
} from '../measureTime.extensions.types';

interface Options {
  extension: AtrigamFlowExtensionMeasureTime;
  objectName?: AtrigamObjectName;
}

export const processValueMeasureTime =
  (fieldName: string) =>
  ({ extension, objectName }: Options) => {
    // adjust objectName
    if (objectName && objectName !== extension.config.object) {
      extension.config.object = objectName;
    }

    // adjust type
    extension.config.type = AtrigamDataType.OpenDuration;
    if (
      extension.config.fromField !== ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW &&
      extension.config.toField !== ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW
    ) {
      extension.config.type = AtrigamDataType.FixedDuration;
    }

    // make sure they from and to are not the same
    if (extension.config.fromField && extension.config.fromField === extension.config.toField) {
      if (fieldName === 'fromField') {
        extension.config.toField = null;
      } else {
        extension.config.fromField = null;
      }
    }
  };
