import {
  AtrigamRole,
  AtrigamSubscriptionRoleInvites,
  AtrigamSubscriptionState,
  AtrigamUserSubscription,
  createAtrigamDatabaseUniverse,
  createAtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { flattenObject } from 'flatten-anything';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamUserSubscriptionPath } from '../paths/createAtrigamUserSubscriptionPath';

type Options = Omit<
  AtrigamUserSubscription,
  // will be created
  | 'invitedAt'
  | 'updatedAt'
  | 'subscriptionState'
  | 'universeDB'
  | 'universeNodeName'
  | 'roleInvites'
> & {
  subscriptionState?: AtrigamSubscriptionState;
  roleInvites?: AtrigamSubscriptionRoleInvites;
};

export const createUserSubscriptionMutation = async ({
  subscriptionState = AtrigamSubscriptionState.Pending,
  roleInvites: roleInvites_,
  ...subscription_
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));
  const updatedAt = getNowFirestoreTimestamp();

  const universeDB = createAtrigamDatabaseUniverse({ universe: subscription_.universe });
  const universeNodeName = createAtrigamUniverseNodeName(subscription_.universe);

  let roleInvites = roleInvites_;
  if (!roleInvites) {
    roleInvites = {};
    subscription_.roles.forEach((role) => {
      roleInvites![role] = {
        role,
        invitedAt: updatedAt,
        invitedBy: subscription_.invitedBy,
      };
    });
  }

  const subscription: AtrigamUserSubscription = {
    ...subscription_,

    roleInvites,
    subscriptionState,
    universeDB,
    updatedAt,
    invitedAt: updatedAt,
    universeNodeName,
  };

  // subscription
  const path = createAtrigamUserSubscriptionPath(subscription_);
  const reference = doc(database, path);
  const document = await getDoc(reference);

  if (document.exists()) {
    const documentData = document.data() as AtrigamUserSubscription;

    // update
    const update: AtrigamUserSubscription = {
      ...documentData,
      updatedAt,
      roles: [...new Set<AtrigamRole>([...documentData.roles, ...subscription.roles])].sort(),
      roleInvites: {
        ...documentData.roleInvites,
        ...subscription.roleInvites,
      },
    };

    // override subscription state if user has an archived subscription to allow
    // re subscriptions
    if (
      update.subscriptionState === AtrigamSubscriptionState.Archived &&
      subscriptionState !== update.subscriptionState
    ) {
      update.subscriptionState = subscriptionState;
    }

    await updateDoc(reference, flattenObject(mapUndefinedToNullValue(update)));

    return update;
  }

  // create

  await setDoc(reference, mapUndefinedToNullValue(subscription));

  return subscription;
};
