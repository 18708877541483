import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfigVersion } from '../../extension-config-base.types';
import { processPreviewCalculatedValue2 } from '../helpers/processPreviewCalculatedValue2';

import { AtrigamFlowExtensionCalculatedValue2_2_0 } from './calculatedValue2.2.0.extensions.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AtrigamFlowExtensionConfigCalculatedValue2_2_0: AtrigamFlowExtensionConfigVersion<AtrigamFlowExtensionCalculatedValue2_2_0> =
  {
    extensionType: AtrigamFlowExtensionTypes.CalculatedValue2,
    version: '2.0',
    description: 'Define a field and calculate the value based on other values of your work item.',
    example: 'Hours * Hourly Rate',
    settings: {
      field: {
        __fieldType: 'defaultField',
        required: true,
      },
      calculations: {
        __fieldType: 'conditionsObject',
        name: 'calculations',
        conditions: true,
        required: true,
        processValue: (value) => value.replaceAll(/\s/g, ''),
      },
      description: {
        __fieldType: 'description',
        required: false,
      },
      type: {
        __fieldType: 'dataTypeSelectField',
        name: 'type',
        unitTarget: 'unit',
        options: [AtrigamDataType.Money, AtrigamDataType.Number],
        required: true,
      },
    },
    processPreview: processPreviewCalculatedValue2,
    availableFields: {
      additionalValues: [],
      fieldTypes: [AtrigamDataType.Number, AtrigamDataType.Money],
    },
  };
