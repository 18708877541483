import humanizeDuration from 'humanize-duration';
import { Duration } from 'luxon';

interface Options {
  duration: Duration;
  options: humanizeDuration.Options;
}

export const formatDuration = ({ duration, options }: Options) => {
  const milliseconds = duration.toMillis();
  const humanize = humanizeDuration(duration.toMillis(), options);
  return `${milliseconds < 0 ? '- ' : ''}${humanize}`;
};
