import {
  Avatar,
  Box,
  Card,
  CardActions,
  Collapse,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { reactive } from '../../helpers/reactive';

import { BannerVariation } from './Banner.types';
import { CardContentWrapper } from './styles/CardContentWrapper.style';
import { StaticWrapper } from './styles/StaticWrapper.style';

const PREFIX = 'MuiBanner';

const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  buttons: `${PREFIX}-buttons`,
  label: `${PREFIX}-label`,
};

const StyledStaticWrapper = styled(StaticWrapper)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'block',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(5),
    width: theme.spacing(5),
  },

  [`& .${classes.buttons}`]: {
    whiteSpace: 'nowrap',
    alignSelf: 'flex-end',
    paddingLeft: '90px !important',
  },

  [`& .${classes.label}`]: {
    alignSelf: 'center',
  },
}));

interface Properties {
  actions: (properties: { onClose: () => void }) => React.ReactNode;
  appBar?: boolean;
  icon?: React.ReactElement;
  message: string | React.ReactNode[];
  variation?: BannerVariation;
  children?: React.ReactNode;
}

const BannerComponent: React.ForwardRefRenderFunction<HTMLDivElement, Properties> = (
  { message, icon, appBar = false, actions, variation = 'default', children },
  reference,
) => {
  const [open, setOpen] = React.useState(true);
  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const renderButtons = React.useMemo(
    () => (
      <>
        <Box component="span" flexGrow={1} />

        <Grid item className={classes.buttons}>
          {actions({ onClose })}
        </Grid>
      </>
    ),
    [actions, onClose],
  );

  let containerProperties = {};

  if (appBar) {
    containerProperties = {
      component: Container,
      maxWidth: 'lg',
    };
  }

  return (
    <StyledStaticWrapper $appBar={appBar} $variation={variation}>
      <Collapse in={open} ref={reference}>
        <Paper elevation={0} className={classes.root}>
          <Card elevation={0} {...containerProperties}>
            <CardContentWrapper>
              <Grid
                container
                wrap="nowrap"
                spacing={appBar ? 3 : 2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {icon && (
                  <Grid item>
                    <Avatar className={classes.avatar}>{icon}</Avatar>
                  </Grid>
                )}

                <Grid item className={classes.label}>
                  <Typography variant="body2">{message}</Typography>
                  {children}
                </Grid>

                <Hidden mdDown>{appBar && renderButtons}</Hidden>
              </Grid>
            </CardContentWrapper>

            {!appBar && (
              <Hidden mdDown>
                <CardActions>{renderButtons}</CardActions>
              </Hidden>
            )}

            <Hidden mdUp>
              <CardActions>{renderButtons}</CardActions>
            </Hidden>

            <Hidden mdDown>
              <div />
            </Hidden>
          </Card>

          <Divider />
        </Paper>
      </Collapse>
    </StyledStaticWrapper>
  );
};

export const Banner = reactive(React.forwardRef(BannerComponent));
