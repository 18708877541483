import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamSubscriptionState,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUserSubscription,
  UID,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  CollectionReference,
  DocumentData,
  collection,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamUserSubscriptionListPath } from '../paths/createAtrigamUserSubscriptionListPath';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const getUserSubscriptionWithWorkItemChangedListQueryReference = ({
  area,
  environment,
  taskFlow,
  uid,
  universe,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));
  const path = createAtrigamUserSubscriptionListPath({
    area,
    environment,
    taskFlow,
    uid,
    universe,
  });

  const reference = collection(database, path) as CollectionReference<
    AtrigamUserSubscription,
    DocumentData
  >;
  const query_ = query(
    reference,
    where('workItemChangedSinceLastViewed', '==', true),
    where('subscriptionState', '==', AtrigamSubscriptionState.Accepted),
  );

  return query_;
};
