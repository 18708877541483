import {
  AtrigamAreaName,
  AtrigamChatMessage,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUserSubscription,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamUserSubscriptionPath } from '../paths/createAtrigamUserSubscriptionPath';

interface Options {
  area: AtrigamAreaName;
  chatMessageSeen: number;
  environment: AtrigamEnvironment;
  message: AtrigamChatMessage;
  node: AtrigamWorkItemId;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const updateUserSubscriptionChatMetadataMutation = async ({
  area,
  chatMessageSeen,
  environment,
  message,
  node,
  taskFlow,
  uid,
  universe,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUserSubscriptionPath({
    area,
    environment,
    node,
    taskFlow,
    uid,
    universe,
  });
  const document = doc(database, path);
  const snapshot = await getDoc(document);
  if (!snapshot.exists()) {
    // logger.warn(`Cannot find user subscription at ${path}`);
    return;
  }

  const updatedAt = getNowFirestoreTimestamp();
  const update: Partial<AtrigamUserSubscription> = {
    chatMessageSeen,
    lastChatMessageSeen: message,
    updatedAt,
  };

  await updateDoc(document, mapUndefinedToNullValue(update));
};
