import { trackingConfiguration } from '@atrigam/atrigam-tracking';
import { AtrigamUniverseNodeName } from '@atrigam/atrigam-types';
import { getWorkItemSubscribersHttpOnCallFunction } from '@atrigam/server-functions-eu-sdk';

import { sentry } from '../../../../../services/Sentry/helpers/initializeSentry';
import { Registry } from '../../../../../services/Registry/Registry';

export const loadSubscribers = async () => {
  const store = Registry.get('workItemPageStore');

  const { environment, node, objectName, universe } = store.basicData;

  if (!store.objectSubscriptionList || !node) {
    return;
  }

  const payload = {
    environment,
    node,
    objectName,
    universeNodeName: universe.toLowerCase() as AtrigamUniverseNodeName,
  };

  const subscribers = await getWorkItemSubscribersHttpOnCallFunction({
    ...payload,
    onException: (error) => sentry.log({ error: error as Error, context: { payload } }),
    XAppEnvironment: trackingConfiguration?.appEnvironment,
    XTransactionID: sentry.transactionID,
  });

  store.teamStore.updateSubscribers(subscribers?.subscribers ?? []);
};
