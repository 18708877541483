import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import { Badge } from '@mui/material';
import React from 'react';

import { reactive } from '../../../../../../helpers/reactive';
import { NavigationItemIcon } from '../../../../../../primitive/NavigationItem/features/NavigationItemIcon/NavigationItemIcon.style';
import { NavigationItemText } from '../../../../../../primitive/NavigationItem/features/NavigationItemText/NavigationItemText.style';
import { NavigationItemWrapper } from '../../../../../../primitive/NavigationItem/styles/NavigationItemWrapper.style';
import { Registry } from '../../../../../../services/Registry/Registry';

import { UserNotificationsWindow } from './features/UserNotificationsWindow/UserNotificationsWindow';

const UserNotificationsComponent: React.FC = () => {
  const { isEnabled, unreadCount } = Registry.get('userNotificationsStore');
  const { permission } = Registry.get('pushNotifications');

  const [open, setOpen] = React.useState(false);
  const anchorReference = React.useRef(null);

  const closeMenu = React.useCallback(() => {
    setOpen(false);
  }, []);

  const openMenu = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setOpen(true);
      event.currentTarget.blur();
    },
    [setOpen],
  );

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <NavigationItemWrapper
        onClick={openMenu}
        $isActive={false}
        data-testid="navigation-item-user-notifications"
        ref={anchorReference}
      >
        <NavigationItemIcon $isActive={false}>
          {
            <Badge badgeContent={unreadCount} showZero={false} color="primary">
              {permission === 'default' && <NotificationsPausedIcon color="warning" />}
              {(!permission || permission === 'denied') && <NotificationsOffIcon />}
              {permission === 'granted' && unreadCount > 0 && <NotificationsActiveIcon />}
              {permission === 'granted' && unreadCount === 0 && <NotificationsIcon />}
            </Badge>
          }
        </NavigationItemIcon>
        <NavigationItemText $isActive={false}>
          {translate('layouts.DefaultLayout.Navigation.UserNotifications.Notifications')}
        </NavigationItemText>
      </NavigationItemWrapper>

      <UserNotificationsWindow open={open} onClose={closeMenu} anchorReference={anchorReference} />
    </>
  );
};

export const UserNotifications = reactive(UserNotificationsComponent);
