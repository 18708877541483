import { withReaction } from '../../../helpers/withReaction';
import { UserStore } from '../User.store';

import { syncUserClientRoles } from './syncUserClientRoles';

/**
 * This makes sure we update the models in the store if user roles are changing
 */
export const watchUserUpdatedAt = (userStore: UserStore) => {
  const disposer = withReaction({
    name: 'UserStore.watchUser',
    data: () => ({
      // sync if roles change
      updatedAt: userStore.user?.updatedAt,
    }),
    onChange: () => {
      // no need to run watcher when we already synched everything
      if (!userStore.isInitialized) {
        return;
      }

      void syncUserClientRoles(userStore);
    },
  });

  userStore.disposers.push(disposer);
};
