import { stringifySearchQuery } from '../../../helpers/stringifySearchQuery';
import { RouteParameters, RoutePath, RoutePathOptions, RouteQuery } from '../Router.types';

import { createPathBuilder } from './createPathBuilder';

interface CreateRoutePath {
  // no params, no query
  (options: RoutePathOptions<undefined>): RoutePath<undefined, undefined>;

  // only params
  <Parameters extends RouteParameters>(
    options: RoutePathOptions<Parameters>,
  ): RoutePath<Parameters, undefined>;

  // only query
  <Query extends RouteQuery>(options: RoutePathOptions<undefined>): RoutePath<undefined, Query>;

  // params + query
  <Parameters extends RouteParameters, Query extends RouteQuery>(
    options: RoutePathOptions<Parameters>,
  ): RoutePath<Parameters, Query>;
}

export const createRoutePath: CreateRoutePath = <
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
>(
  options: RoutePathOptions<Parameters>,
) => {
  let pathBuilder: ((buildPathOptions?: Parameters) => string) | undefined;
  const buildPath = (buildPathOptions?: Parameters) => {
    // create path builder lazily only upon execution
    if (!pathBuilder) {
      pathBuilder = createPathBuilder<Parameters>({
        allowNonSSL: options.allowNonSSL,
        pattern: typeof options.pattern === 'function' ? options.pattern() : options.pattern,
      });
    }

    const x = (
      buildPathOptions
        ? // eslint-disable-next-line unicorn/no-nested-ternary
          options.defaultParameters
          ? { ...options.defaultParameters, ...buildPathOptions }
          : buildPathOptions
        : // eslint-disable-next-line unicorn/no-nested-ternary
          options.defaultParameters ?? undefined
    ) as Parameters | undefined;

    return pathBuilder(x);
  };

  const getLink = (linkOptions?: { params?: Parameters; query?: Query; hash?: string }): string => {
    if (!linkOptions) {
      return buildPath();
    }

    const { params, query } = linkOptions;
    const pathname: string = buildPath(params);
    const search: string = query ? stringifySearchQuery(query) : '';
    const hash: string = linkOptions.hash ? `#${linkOptions.hash}` : '';

    return `${pathname}${hash}${search}`;
  };

  return {
    getLink,
    get pattern() {
      return typeof options.pattern === 'function' ? options.pattern() : options.pattern;
    },
  };
};
