import { AtrigamUniverseName, AtrigamUniverseRegistration } from '@atrigam/atrigam-types';

import { createAtrigamUniverseRegistrationPath } from '../firestore/paths/createAtrigamUniverseRegistrationPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  universe: AtrigamUniverseName;
}

export const watchUniverseRegistration: WatcherFunction<AtrigamUniverseRegistration, Options> = ({
  universe,
  onUpdate,
  onError,
}) => {
  const firestoreService = watcherServiceEu.get();

  const path = createAtrigamUniverseRegistrationPath({ universe });

  return firestoreService.subscribeToDocument<AtrigamUniverseRegistration>({
    path,
    onSnapshot: (snapshot, key) => {
      const universeRegistration = snapshot.data();
      void onUpdate(universeRegistration, key);
    },
    onError,
  });
};
