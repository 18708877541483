import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemListPath,
  createAtrigamDatabaseUniverseEnvironment,
} from '@atrigam/atrigam-types';

interface Options {
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const createAtrigamWorkItemListPath = ({
  environment,
  objectName,
  universe,
}: Options): AtrigamWorkItemListPath => {
  const databaseUniverseEnvironment = createAtrigamDatabaseUniverseEnvironment({
    universe,
    environment,
  });

  return `/universes/${databaseUniverseEnvironment}/objects/${objectName}/data`;
};
