import { AtrigamEnvironment, AtrigamUniverseAreaTaskFlow } from '@atrigam/atrigam-types';

import { RouteParameters } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export interface Parameters extends RouteParameters {
  environment: AtrigamEnvironment;
  universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow;
}

export const pattern = '/:environment/:universeAreaTaskFlow/create';

export const CreateWorkItemPath = createRoutePath<Parameters>({ pattern });
