import numbro from 'numbro';

import { UnhandledCaseError } from '../../../../utilities/UnhandledCaseError';

import numbroLanguageDE from './de-DE';

interface Options {
  language?: 'de' | 'en';
}

export const getNumbro = ({ language = 'en' }: Options) => {
  initializeNumbro(language);

  return numbro;
};

const initializeNumbro = (language: 'de' | 'en') => {
  switch (language) {
    case 'de': {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      numbro.registerLanguage(numbroLanguageDE);
      numbro.setLanguage('de-DE');
      break;
    }

    case 'en': {
      numbro.setLanguage('en-US');
      break;
    }

    default: {
      throw new UnhandledCaseError(language);
    }
  }
};
