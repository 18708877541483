import {
  AtrigamObjectSubscription,
  AtrigamSubscriptionRoleInvites,
  AtrigamSubscriptionState,
  DistributiveOmit,
  createAtrigamDatabaseUniverse,
  createAtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamObjectSubscriptionListPath } from '../paths/createAtrigamObjectSubscriptionListPath';

type Options = DistributiveOmit<
  AtrigamObjectSubscription,
  // will be created
  | 'id'
  | 'invitedAt'
  | 'updatedAt'
  | 'subscriptionState'
  | 'universeDB'
  | 'universeNodeName'
  | 'roleInvites'
> & {
  subscriptionState?: AtrigamSubscriptionState;
  roleInvites?: AtrigamSubscriptionRoleInvites;
};

export const createObjectSubscriptionForUserMutation = async ({
  subscriptionState = AtrigamSubscriptionState.Pending,
  roleInvites: roleInvites_,
  ...subscription_
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamObjectSubscriptionListPath(subscription_);
  const reference = doc(collection(database, path));

  const updatedAt = getNowFirestoreTimestamp();
  const universeDB = createAtrigamDatabaseUniverse({ universe: subscription_.universe });
  const universeNodeName = createAtrigamUniverseNodeName(subscription_.universe);

  let roleInvites = roleInvites_;
  if (!roleInvites) {
    roleInvites = {};
    subscription_.roles.forEach((role) => {
      roleInvites![role] = {
        role,
        invitedAt: updatedAt,
        invitedBy: subscription_.invitedBy,
      };
    });
  }

  const subscription: AtrigamObjectSubscription = {
    ...subscription_,

    id: reference.id,
    invitedAt: updatedAt,
    roleInvites,
    subscriptionState,
    universeDB,
    universeNodeName,
    updatedAt,
  };

  await setDoc(reference, mapUndefinedToNullValue(subscription));

  return subscription;
};
