import { AtrigamWorkItem } from '@atrigam/atrigam-types';

import { extractFieldsFromPattern } from './extractFieldsFromPattern';

interface Options {
  pattern: string;
  workItem: AtrigamWorkItem;
}

export const resolvePatternWithWorkItem = ({ pattern, workItem }: Options) => {
  const fields = extractFieldsFromPattern({ pattern });
  if (!fields) return;

  if (!pattern) return;
  let resolved = pattern;
  const missingValueFields = [];

  for (const field of fields) {
    const workField = workItem[field];
    const match = `[${field}]`;

    if (workField !== undefined && workField !== null) {
      resolved = resolved.replace(match, workField as string);
    } else {
      missingValueFields.push(field);
    }
  }
  return {
    resolved,
    missingValueFields,
  };
};
