import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItem,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { createAtrigamWorkItemPath } from '../paths/createAtrigamWorkItemPath';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  node: AtrigamWorkItemId;
  uid: UID;
}

export const updateWorkItemUpdatedAtMutation = async ({
  universe,
  environment,
  objectName,
  node,
  uid,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamWorkItemPath({ environment, node, objectName, universe });
  const document = doc(database, path);

  const snapshot = await getDoc(document);
  if (!snapshot.exists()) {
    return;
  }

  const updatedAt = getNowFirestoreTimestamp();
  const update: Partial<AtrigamWorkItem> = { updatedAt, updatedBy: uid };

  await updateDoc(document, update);

  return updatedAt;
};
