import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItem,
  ISODateTime,
  UnhandledCaseError,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  CollectionReference,
  DocumentData,
  collection,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamWorkItemListPath } from '../paths/createAtrigamWorkItemListPath';

export interface DateRangeFilter {
  type: 'dateRange';
  field: string;
  from: ISODateTime;
  to: ISODateTime;
}

export interface DefaultFilter {
  type: 'default';
  field: string;
  values: string[];
}

export type GetAllWorkItemsQueryFilter = DateRangeFilter | DefaultFilter;

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  filters?: GetAllWorkItemsQueryFilter[];
}

export const getAllWorkItemListQueryReference = ({
  universe,
  environment,
  objectName,
  filters,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamWorkItemListPath({ environment, objectName, universe });
  const reference = collection(database, path) as CollectionReference<
    AtrigamWorkItem,
    DocumentData
  >;
  let query_ = query(reference);

  if (filters) {
    filters.forEach((filter) => {
      switch (filter.type) {
        case 'dateRange': {
          query_ = query(query_, where(filter.field, '>', filter.from));
          query_ = query(query_, where(filter.field, '<', filter.to));
          break;
        }

        case 'default': {
          if (filter.values.length === 1) {
            query_ = query(query_, where(filter.field, '==', filter.values[0]));
            break;
          }

          query_ = query(query_, where(filter.field, 'in', filter.values));
          break;
        }

        default: {
          throw new UnhandledCaseError(filter);
        }
      }
    });
  }

  return query_;
};
