import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AtrigamDataType,
  AtrigamEnvironment,
  AtrigamWorkItem,
  type AtrigamUniverseAreaTaskFlow,
} from '@atrigam/atrigam-types';
import { getAllWorkItemListQuery } from '@atrigam/server-functions-eu-client';
import { action, computed, makeObservable, observable } from 'mobx';

import { InsightsRoleBlockEntity } from '../../../stores/ModelsStore/entities/taskFlow/insights/InsightsRoleBlock.entity';
import { Registry } from '../../../services/Registry/Registry';
import { getDataTypeForField } from '../helpers/getDataTypeForField';
import { getFieldLabel } from '../helpers/getFieldLabel';

import { convertActiveFiltersToQueryFilters } from './helpers/convertActiveFiltersToQueryFilters';
import { handleMissingIndexError } from './helpers/handleMissingIndexError';

interface Options {
  environment: AtrigamEnvironment;
  universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow;
}

export class InsightsPageStore extends DestructibleService {
  @observable
  activeFilters = observable.map<string, unknown[]>();

  @observable
  environment: AtrigamEnvironment;

  @observable
  error?: string;

  @observable
  isLoading = false;

  @observable
  universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow;

  @observable
  workItems: AtrigamWorkItem[] = [];

  @observable
  private _allWorkitems: AtrigamWorkItem[] = [];

  constructor(data: Options) {
    super();

    makeObservable(this);

    this.environment = data.environment;
    this.universeAreaTaskFlow = data.universeAreaTaskFlow;
  }

  @computed
  get allAvailableFilters() {
    const allFilters = new Map<
      string,
      { label: string; value: string; dataType: AtrigamDataType }
    >();

    const taskFlowModel = this.currentTaskFlowModel;
    if (!taskFlowModel?.insights) {
      return allFilters;
    }

    taskFlowModel.insights.roles.forEach((roleModel) => {
      roleModel.filters.forEach((filter) => {
        if (allFilters.has(filter)) {
          return;
        }

        const dataType = getDataTypeForField({ field: filter, model: this.currentWorkItemModel });
        if (!dataType) {
          return;
        }

        allFilters.set(filter, {
          dataType,
          label: getFieldLabel({ field: filter, model: this.currentWorkItemModel }),
          value: filter,
        });
      });
    });

    return allFilters;
  }

  @computed
  get activeFiltersAsArray() {
    return [...this.activeFilters.keys()];
  }

  @computed
  get availableFiltersForAdding() {
    return [...this.allAvailableFilters.values()]
      .filter(({ value }) => !this.activeFilters.has(value))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  @computed
  get blocks() {
    const taskFlowModel = this.currentTaskFlowModel;
    if (!taskFlowModel?.insights) {
      return [];
    }

    const allBlocks: InsightsRoleBlockEntity[] = [];
    const uniqueIds: string[] = [];

    taskFlowModel.insights.roles.forEach((roleModel) => {
      roleModel.blocks.forEach((blockModel) => {
        if (uniqueIds.includes(blockModel.uniqueId)) {
          return;
        }

        allBlocks.push(blockModel);
        uniqueIds.push(blockModel.uniqueId);
      });
    });

    return allBlocks;
  }

  @computed
  get currentTaskFlowModel() {
    const taskFlowModel = Registry.get('modelsStore').getTaskFlowModel({
      universeAreaTaskFlow: this.universeAreaTaskFlow,
    });
    return taskFlowModel;
  }

  @computed
  get currentWorkItemModel() {
    const model = Registry.get('modelsStore').getWorkItemModel({
      environment: this.environment,
      universeAreaTaskFlow: this.universeAreaTaskFlow,
    });
    return model;
  }

  @computed
  get hasFilters() {
    const taskFlowModel = this.currentTaskFlowModel;
    if (!taskFlowModel?.insights) {
      return false;
    }

    return taskFlowModel.insights.hasFilters;
  }

  @computed
  get hasNoWorkItems() {
    return this.workItems.length === 0;
  }

  @action
  addFilterName = (filter: string) => {
    this.activeFilters.set(filter, []);
  };

  @action
  clearAllFilters = () => {
    const filterNames = [...this.activeFilters.keys()];
    this.activeFilters.clear();
    void this.loadWorkItems();

    // update query navigation
    const router = Registry.get('router');

    const query = {};
    filterNames.forEach((key) => {
      (query as unknown as Record<string, undefined>)[key] = undefined;
    });

    router.updateQueryWithoutNavigation(query);
  };

  @action
  initialize = async () => {
    if (
      !this.currentTaskFlowModel ||
      !this.currentTaskFlowModel?.universe ||
      !this.currentTaskFlowModel?.objectName
    ) {
      return;
    }

    this.isLoading = true;

    try {
      const data = await getAllWorkItemListQuery({
        universe: this.currentTaskFlowModel.universe,
        environment: this.environment,
        objectName: this.currentTaskFlowModel.objectName,
      });

      this._allWorkitems = data;
    } catch {}

    await this.loadWorkItems();
  };

  @action
  loadWorkItems = async () => {
    if (
      !this.currentTaskFlowModel ||
      !this.currentTaskFlowModel?.universe ||
      !this.currentTaskFlowModel?.objectName
    ) {
      return;
    }

    this.isLoading = true;
    this.error = undefined;

    const filters = convertActiveFiltersToQueryFilters({
      activeFilters: this.activeFilters,
      allAvailableFilters: this.allAvailableFilters,
    });

    // no need to fetch data, we can use the backup we have
    if (filters.length === 0) {
      this.workItems = [...this._allWorkitems];
      this.isLoading = false;
      return;
    }

    try {
      const data = await getAllWorkItemListQuery({
        universe: this.currentTaskFlowModel.universe,
        environment: this.environment,
        objectName: this.currentTaskFlowModel.objectName,
        filters,
      });

      this.workItems = data;
    } catch (error) {
      if (typeof error === 'object' && (error as Error).message) {
        this.error = (error as Error).message;

        // ignore end2end tests
        if (
          this.currentTaskFlowModel.universeAreaTaskFlow !== 'Atrigam:IntegrationTests:InsightsTest'
        ) {
          await handleMissingIndexError({
            errorMessage: (error as Error).message,
            filters,
          });
        }
      }
    }

    this.isLoading = false;
  };

  @action
  removeFilter = (filter: string) => {
    this.activeFilters.delete(filter);
    void this.loadWorkItems();

    // update query navigation
    const router = Registry.get('router');
    router.updateQueryWithoutNavigation({
      [filter]: undefined,
    });
  };

  @action
  setFilterValues = (option: { filter: string; values: unknown[] }) => {
    this.activeFilters.set(option.filter, option.values);
    void this.loadWorkItems();

    // update query navigation
    const router = Registry.get('router');
    router.updateQueryWithoutNavigation({
      [option.filter]: JSON.stringify(option.values),
    });
  };

  @action
  syncFiltersFromUrl = (query: Record<string, string>) => {
    const availableFilterNames = new Set(
      [...this.allAvailableFilters.values()].map(({ value }) => value),
    );

    Object.entries(query).forEach(([filter, valueString]) => {
      if (!availableFilterNames.has(filter)) {
        return;
      }

      try {
        const values = JSON.parse(valueString) as unknown[];

        if (!Array.isArray(values) || values.length === 0) {
          return;
        }

        this.activeFilters.set(filter, values);
      } catch {}
    });
  };

  getOptionsForFilter = (filter: string) => {
    const availableValues = new Map<string, unknown>();

    this._allWorkitems.forEach((workItem) => {
      const fieldValue = workItem[filter];
      if (!fieldValue) {
        return;
      }

      availableValues.set(String(fieldValue), fieldValue);
    });

    return [...availableValues.values()].sort();
  };
}
