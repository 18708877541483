// @see https://gist.github.com/Fluidbyte/2973986#file-common-currency-json
import { Currency, CurrencySymbol } from '../../../../../domain/common.types';

import symbolData from './currencySymbolData.json';

export const getCurrencyForCurrencySymbol = (currencySymbol: CurrencySymbol) => {
  const entry = Object.values(symbolData).find((data) => data.symbol_native === currencySymbol);
  if (!entry) {
    return;
  }

  return entry.code as Currency;
};
