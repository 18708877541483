import {
  AtrigamUserSubscription,
  AtrigamWorkItem,
  AtrigamWorkItemId,
  UnhandledCaseError,
  extractAtrigamUniverseAreaTaskFlow,
} from '@atrigam/atrigam-types';
import {
  getWorkItemQuery,
  subscribeToUserSubscriptions,
  watcherServiceEu,
} from '@atrigam/server-functions-eu-client';

import { withReaction } from '../../../helpers/withReaction';
import { Registry } from '../../../services/Registry/Registry';
import { SubscriptionsStore } from '../Subscriptions.store';

let firstRun = true;

export const watchSubscriptions = (subscriptionsStore: SubscriptionsStore) => {
  const modelsStore = Registry.get('modelsStore');
  const userStore = Registry.get('userStore');

  withReaction({
    name: 'watchSubscriptions',
    data: () => ({
      environment: modelsStore.environment,
      limit: subscriptionsStore.limit,
      page: subscriptionsStore.page,
      subscriptionState: subscriptionsStore.subscriptionState,
      universeAreaTaskFlow: subscriptionsStore.universeAreaTaskFlow,
      uid: userStore.user?.uid,
    }),
    fireImmediately: true,
    onChange: ({ environment, limit, page, subscriptionState, universeAreaTaskFlow, uid }) => {
      // unsubscribe old subscription if present
      if (subscriptionsStore.watchSubscriptionsDisposer) {
        subscriptionsStore.watchSubscriptionsDisposer();
      }

      // make sure we have an uid
      if (!uid || !universeAreaTaskFlow) {
        return;
      }

      const { area, taskFlow, universe } = extractAtrigamUniverseAreaTaskFlow(universeAreaTaskFlow);

      // subscribe
      const disposer = subscribeToUserSubscriptions({
        environment,
        limit,
        startAfter: page > 0 ? subscriptionsStore.lastSubscription?.invitedAt : undefined,
        subscriptionState,
        universe,
        area,
        taskFlow,
        uid,
        onSnapshot: (snapshotCollection, key) => {
          // watcher runs although it should not, then unsubscribe everything
          if (!subscriptionsStore.isEnabled) {
            watcherServiceEu.get().unsubscribe(key);
            return;
          }

          if (firstRun) {
            firstRun = false;
            subscriptionsStore.setIsLoading(false);
            return;
          }

          interface SubscriptionWithWorkItem {
            subscription: AtrigamUserSubscription;
            workItem?: AtrigamWorkItem;
          }
          const subscriptionsToBeUpdated = new Map<AtrigamWorkItemId, SubscriptionWithWorkItem>();
          const workItemsToBeLoaded: AtrigamUserSubscription[] = [];
          snapshotCollection.docChanges().forEach((change) => {
            const subscription = change.doc.data();

            switch (change.type) {
              case 'added': {
                subscriptionsToBeUpdated.set(subscription.node, { subscription });
                workItemsToBeLoaded.push(subscription);
                break;
              }

              case 'modified': {
                subscriptionsToBeUpdated.set(subscription.node, { subscription });
                workItemsToBeLoaded.push(subscription);
                break;
              }

              case 'removed': {
                subscriptionsStore.removeSubscription(subscription);
                break;
              }

              default: {
                throw new UnhandledCaseError(change.type);
              }
            }
          });

          void Promise.all(
            workItemsToBeLoaded.map(async ({ objectName, node }) => {
              const workItem = await getWorkItemQuery({
                universe,
                environment,
                objectName,
                node,
              });

              const s = subscriptionsToBeUpdated.get(node);

              if (workItem && s) {
                s.workItem = workItem;
              }
            }),
          ).then(() => {
            // update subscriptions with workitem together to fix Subscriptions Broken error because we are still waiting for the workItems to be loaded
            subscriptionsToBeUpdated.forEach(({ subscription, workItem }, node) => {
              subscriptionsStore.updateSubscription(subscription);
              if (workItem) {
                subscriptionsStore.addWorkItem({ workItem, node });
              }
            });
            subscriptionsStore.setIsLoading(false);
          });
        },
      });

      subscriptionsStore.setWatchSubscriptionsDisposer(disposer);
    },
  });

  subscriptionsStore.disposers.push(() => {
    if (subscriptionsStore.watchSubscriptionsDisposer) {
      subscriptionsStore.watchSubscriptionsDisposer();
    }
  });
};
