import { AtrigamFirestoreUserNotificationsUser, UID } from '@atrigam/atrigam-types';

import { createAtrigamUserNotificationUserPath } from '../firestore/paths/createAtrigamUserNotificationUserPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  uid: UID;
}

export const watchUserNotifications: WatcherFunction<
  AtrigamFirestoreUserNotificationsUser,
  Options
> = ({ uid, onUpdate, onError }) => {
  const firestoreService = watcherServiceEu.get();

  const path = createAtrigamUserNotificationUserPath({ uid });

  return firestoreService.subscribeToDocument<AtrigamFirestoreUserNotificationsUser>({
    path,
    onSnapshot: (snapshot, key) => {
      const userNotifications = snapshot.data();
      void onUpdate(userNotifications, key);
    },
    onError,
  });
};
