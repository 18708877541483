import { AtrigamWorkItem } from '../domain/workItem.types';
import { AtrigamModelsModelObjectsObjectData } from '../firebase/atrigam-models';

import { AtrigamDataType } from './atrigam-datatypes';
import { formatDataType } from './formatDataType';
import { GeneralFormatDataOptions } from './formatDataType.types';
import { createHumanReadableFieldName } from './helpers/createHumanReadableFieldName';
import { determineAtrigamDataType } from './helpers/determineAtrigamDataType';
import { getMoneyField } from './helpers/getMoneyField';

interface Options {
  pattern: string;
  workItem?: Partial<AtrigamWorkItem>;
  workItemModelData?: AtrigamModelsModelObjectsObjectData;
  options?: GeneralFormatDataOptions['options'];
}

const reField = new RegExp(/\[(.*?)\]/g);

export const formatPattern = ({ workItemModelData, options, pattern, workItem }: Options) => {
  return pattern.replace(reField, (_fullMatch: string, fieldName: string) => {
    const defaultResult = `${createHumanReadableFieldName(fieldName)} (n/a)`;

    if (!workItem) {
      return defaultResult;
    }

    // 1. get field value and return default if no value is present
    let fieldValue = workItem[fieldName];
    if (fieldValue === undefined || fieldValue === null) {
      return defaultResult;
    }

    // 2. look for object type in workItemModel
    let dataType: AtrigamDataType | undefined;
    if (workItemModelData) {
      const modelField = workItemModelData[fieldName];
      if (modelField) {
        dataType = modelField.type;
      }
    }

    // 3. no type, try to determine the type
    if (!dataType) {
      const determinedType = determineAtrigamDataType(fieldValue);
      if (determinedType) {
        dataType = determinedType;
      }
    }

    // 4. still no type, return;
    if (!dataType) {
      return defaultResult;
    }

    // 5. check if we are talking about money fields
    if (dataType === AtrigamDataType.Money) {
      const moneyField = getMoneyField({ fieldName, workItem });
      if (moneyField) {
        fieldValue = moneyField;
      }
    }

    return formatDataType({
      dataType,
      value: fieldValue,
      options,
    });
  });
};
