import { AtrigamPushToken, UID } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamUserFcmTokenListPath } from '../paths/createAtrigamUserFcmTokenListPath';
import { createAtrigamUserFcmTokenPath } from '../paths/createAtrigamUserFcmTokenPath';

import { updateUserUpdatedAtMutation } from './updateUserUpdatedAt.mutation';

interface Options {
  fcmToken: AtrigamPushToken;
  uid: UID;
}

export const removeUserFcmTokenMutation = async ({ fcmToken, uid }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUserFcmTokenListPath({ uid });
  const collectionReference = collection(database, path);

  const query_ = query(collectionReference, where('token', '==', fcmToken));
  const collection_ = await getDocs(query_);

  await Promise.all(
    collection_.docs.map(async (snapshot) => {
      const deletePath = createAtrigamUserFcmTokenPath({ uid, id: snapshot.id });
      const reference = doc(database, deletePath);
      await deleteDoc(reference);
    }),
  );
  await updateUserUpdatedAtMutation({ uid });
};
