import { AtrigamUserNotification, UID } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  CollectionReference,
  DocumentData,
  collection,
  getFirestore,
  orderBy,
  query,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamUserNotificationListPath } from '../paths/createAtrigamUserNotificationListPath';

interface Options {
  uid: UID;
}

export const getUserNotificationListQueryReference = ({ uid }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUserNotificationListPath({ uid });
  const reference = collection(database, path) as CollectionReference<
    AtrigamUserNotification,
    DocumentData
  >;

  return query(reference, orderBy('timestamp', 'desc'));
};
