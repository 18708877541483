import { AtrigamUniverseName } from '../universe.types';

export const isAtrigamUniverseName = (data: unknown): data is AtrigamUniverseName => {
  if (typeof data !== 'string') {
    return false;
  }
  // check if starts with capital letter
  if (!data.startsWith(data.charAt(0).toUpperCase())) {
    return false;
  }
  return true;
};
