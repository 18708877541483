import {
  AtrigamFlowExtensionSettingNames,
  AtrigamFlowExtensionSettingsData,
} from '@atrigam/atrigam-extensions';
import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { DocumentData, DocumentReference, doc, getFirestore } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamFlowExtensionSettingsPath } from '../paths/createAtrigamFlowExtensionSettingsPath';

interface Options {
  environment: AtrigamEnvironment;
  extensionName: AtrigamFlowExtensionSettingNames;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getExtensionSettingsQueryReference = <T extends AtrigamFlowExtensionSettingsData>({
  environment,
  extensionName,
  objectName,
  universe,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamFlowExtensionSettingsPath({
    environment,
    extensionName,
    objectName,
    universe,
  });
  return doc(database, path) as DocumentReference<T, DocumentData>;
};
