import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../../../themes/Themes.types';

export const MainWrapper = styled.main<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => css`
    flex: 1;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: ${theme.spacing(3)};
    padding-top: 14px;
    padding-left: ${theme.spacing(3)};

    ${theme.breakpoints.up('sm')} {
      padding-top: ${theme.spacing(2)};
    }
  `,
);
