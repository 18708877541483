import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamUserSubscription,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { DocumentData, DocumentReference, doc, getFirestore } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamUserSubscriptionPath } from '../paths/createAtrigamUserSubscriptionPath';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const getUserSubscriptionQueryReference = ({
  area,
  environment,
  node,
  taskFlow,
  uid,
  universe,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUserSubscriptionPath({
    area,
    environment,
    node,
    taskFlow,
    uid,
    universe,
  });
  return doc(database, path) as DocumentReference<AtrigamUserSubscription, DocumentData>;
};
