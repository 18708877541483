import { AtrigamConditionsSet, AtrigamRestrictionActiveState, AtrigamWorkItem } from '../../domain';
import { AtrigamModelsModelFlowStepInteractionActiveState } from '../../firebase';
import { AtrigamUniverseKpi } from '../../firestore';
import { UnhandledCaseError } from '../../utilities';
import { validateConditionOrSet } from '../validateConditionOrSet';

import { getResolvedActiveState } from './getResolvedActiveState';

interface Options {
  restrictions?: AtrigamConditionsSet;
  activeState?: AtrigamModelsModelFlowStepInteractionActiveState;
  universeKpisMap: Record<AtrigamUniverseKpi['id'], AtrigamUniverseKpi['result']>;
  workItem?: Omit<AtrigamWorkItem, 'id'>;
  beforeWorkItem?: Omit<AtrigamWorkItem, 'id'>;
}

export const getActiveState = ({
  restrictions,
  activeState,
  universeKpisMap,
  workItem,
  beforeWorkItem,
}: Options) => {
  // no restrictions mean always show the field
  if (!restrictions || Object.values(restrictions).length === 0) {
    return AtrigamRestrictionActiveState.Active;
  }

  return Object.entries(restrictions).reduce((currentState, [key, orSet]) => {
    const isRestrictionResolved = validateConditionOrSet({
      orSet,
      universeKpisMap,
      workItem,
      beforeWorkItem,
    });

    const value = activeState ? activeState[key] : undefined;
    const restrictionState = getResolvedActiveState({
      restrictionState: value ?? false,
      isRestrictionResolved,
    });

    switch (restrictionState) {
      case AtrigamRestrictionActiveState.Hidden: {
        return currentState;
      }

      case AtrigamRestrictionActiveState.Inactive: {
        return currentState === AtrigamRestrictionActiveState.Active
          ? AtrigamRestrictionActiveState.Active
          : AtrigamRestrictionActiveState.Inactive;
      }

      case AtrigamRestrictionActiveState.Active: {
        return AtrigamRestrictionActiveState.Active;
      }

      default: {
        throw new UnhandledCaseError(restrictionState);
      }
    }
  }, AtrigamRestrictionActiveState.Hidden);
};
