import { AtrigamDataType } from '../../atrigam-datatypes';
import { DATATYPE_FORMAT_NOT_AVAILABLE } from '../../dataTypes.constants';
import { FormatDataTypeFunction } from '../../formatDataType.types';
import { createTranslate } from '../../helpers/createTranslate';

export const formatDataTypeBoolean: FormatDataTypeFunction<AtrigamDataType.Boolean> = ({
  value,
  options: { language = 'en' },
}) => {
  if (value === true) {
    return translate({ language, key: 'Yes' });
  }

  if (value === false) {
    return translate({ language, key: 'No' });
  }

  if (value === null || value === undefined) {
    return translate({ language, key: 'ValueNotYetSet' });
  }

  return DATATYPE_FORMAT_NOT_AVAILABLE;
};

const translations = {
  de: {
    Yes: 'Ja',
    No: 'Nein',
    ValueNotYetSet: 'Wert noch nicht gesetzt',
  },
  en: {
    Yes: 'Yes',
    No: 'No',
    ValueNotYetSet: 'Value not yet set',
  },
};

const translate = createTranslate(translations);
