import { AtrigamPushToken, AtrigamUserFcmToken, UID } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamUserFcmTokenListPath } from '../paths/createAtrigamUserFcmTokenListPath';
import { createAtrigamUserFcmTokenPath } from '../paths/createAtrigamUserFcmTokenPath';

import { updateUserUpdatedAtMutation } from './updateUserUpdatedAt.mutation';

interface Options {
  uid: UID;
  fcmToken: AtrigamPushToken;
  browser: AtrigamUserFcmToken['browser'];
}

export const updateUserFcmTokenMutation = async ({ uid, fcmToken, browser }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));
  const path = createAtrigamUserFcmTokenListPath({ uid });

  const collectionReference = collection(database, path);
  const query_ = query(collectionReference, where('token', '==', fcmToken));

  const collection_ = await getDocs(query_);

  const lastUsed = getNowFirestoreTimestamp();

  if (collection_.empty) {
    // create new token entry

    const reference = doc(collectionReference);
    const data: AtrigamUserFcmToken = {
      token: fcmToken,
      createdAt: lastUsed,
      updatedAt: lastUsed,
      browser,
    };

    await setDoc(reference, mapUndefinedToNullValue(data));
  } else {
    await Promise.all(
      collection_.docs.map(async (snapshot) => {
        const documentPath = createAtrigamUserFcmTokenPath({ uid, id: snapshot.id });
        const reference = doc(database, documentPath);

        // if token is already present, just update time and browser

        const data: Partial<AtrigamUserFcmToken> = {
          updatedAt: lastUsed,
          browser,
        };

        await updateDoc(reference, mapUndefinedToNullValue(data));
      }),
    );
  }

  await updateUserUpdatedAtMutation({ uid });
};
