import {
  AtrigamDataType,
  formatPattern,
  ChangeSet,
  createMoneyFxFieldName,
  validateConditionSet,
} from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import {
  AtrigamFlowExtensionProcessor,
  AtrigamFlowExtensionProcessorType,
  ExtensionProcessFunction,
} from '../../../extensionsRunner/atrigam-processor.types';

import { AtrigamFlowExtensionCalculatedValue2_2_0 } from './calculatedValue2.2.0.extensions.types';
import { CalculatedValue2_2_0_ProcessorOptions } from './calculatedValue2.2.0.processor.types';
import { evaluateMathExpression } from './helpers/evaluateMathExpression';

// eslint-disable-next-line @typescript-eslint/naming-convention
export class CalculatedValue2_2_0_Processor
  implements AtrigamFlowExtensionProcessor<AtrigamFlowExtensionCalculatedValue2_2_0>
{
  type: AtrigamFlowExtensionTypes = AtrigamFlowExtensionTypes.CalculatedValue2;
  version = '2.0';
  processorType = AtrigamFlowExtensionProcessorType.All;

  private _dateFormat: CalculatedValue2_2_0_ProcessorOptions['dateFormat'];
  private _dateTimeFormat: CalculatedValue2_2_0_ProcessorOptions['dateTimeFormat'];
  private _language: CalculatedValue2_2_0_ProcessorOptions['language'];
  private _getFxRates: CalculatedValue2_2_0_ProcessorOptions['getFxRates'];

  constructor(options: CalculatedValue2_2_0_ProcessorOptions) {
    this._dateFormat = options.dateFormat;
    this._dateTimeFormat = options.dateTimeFormat;
    this._language = options.language;
    this._getFxRates = options.getFxRates;
  }

  supportsVersion: (version: string) => boolean = (version) => version === this.version;

  process: ExtensionProcessFunction<AtrigamFlowExtensionCalculatedValue2_2_0> = ({
    extension,
    universeKpisMap,
    workItem,
    beforeWorkItem,
  }) => {
    //
    if (!extension.config?.calculations) {
      return [];
    }

    const calculations = extension.config.calculations;

    const changes: ChangeSet[] = [];

    let calculatedResult: number | undefined | null;

    Object.entries(calculations).forEach(([key, pattern]) => {
      let resolved = true;

      if (extension.config.conditions?.[key]) {
        resolved = validateConditionSet({
          conditions: extension.config.conditions[key].conditions,
          universeKpisMap,
          workItem,
          beforeWorkItem,
        });
      }

      if (!resolved) {
        if (!calculatedResult) {
          calculatedResult = null;
        }
        return;
      }

      let calculated: number | undefined | null;
      // resolve pattern
      const resolvedPattern = formatPattern({
        pattern,
        workItem,
        options: {
          dateFormat: this._dateFormat,
          dateTimeFormat: this._dateTimeFormat,
          fxRates: this._getFxRates(),
          language: this._language,
          numberFormat: 'rawNumbers',
          numberLanguage: 'en',
        },
      });

      // calculate math expression
      if (!resolvedPattern.includes('n/a')) {
        calculated = evaluateMathExpression(resolvedPattern);
      }

      if (calculated === undefined) {
        calculated = null;
      }

      calculatedResult = calculated;
    });

    // don't do anything if the calculations never run
    if (calculatedResult === undefined) {
      return changes;
    }

    // if we can't resolve the conditions, set the value to null
    if (calculatedResult === null) {
      // no changes, nothing to do
      if (workItem[extension.config.field] === null) {
        return changes;
      }

      const unresolvedSet = {
        fieldName: extension.config.field,
        fieldValue: null,
      };
      workItem[unresolvedSet.fieldName] = unresolvedSet.fieldValue;
      changes.push(unresolvedSet);

      return changes;
    }

    const changeSet = {
      fieldName: extension.config.field,
      fieldValue: calculatedResult,
    };
    // did we change something?
    if (workItem[changeSet.fieldName] !== changeSet.fieldValue) {
      workItem[changeSet.fieldName] = changeSet.fieldValue;
      changes.push(changeSet);
    }

    // handle moneyField
    if (extension.config.type === AtrigamDataType.Money) {
      const fxField = createMoneyFxFieldName(extension.config.field);
      if (calculatedResult === null) {
        if (workItem[fxField] !== null) {
          const moneyChange = {
            fieldName: fxField,
            fieldValue: null,
          };
          workItem[fxField] = null;
          changes.push(moneyChange);
        }
      } else {
        let fxValue = {};
        fxValue = {
          currency: 'EUR',
          systemCurrency: 'EUR',
          rawValue: String(calculatedResult),
          value: `EUR ${calculatedResult}`,
          valueInSystemCurrency: calculatedResult,
        };

        // todo check if fxValue has changed
        const moneyChange = {
          fieldName: fxField,
          fieldValue: fxValue,
        };
        workItem[fxField] = fxValue;
        changes.push(moneyChange);
      }
    }

    return changes;
  };
}
