import { AtrigamDataType } from '../../atrigam-datatypes';
import { getObjectAsStringOrValue } from '../../helpers/getObjectAsStringOrValue';
import { ParseDataTypeFunction } from '../../parseDataType.types';

export const parseDataTypeNumber: ParseDataTypeFunction<
  AtrigamDataType.Integer | AtrigamDataType.Number
> = ({ value: value_ }) => {
  const value = getObjectAsStringOrValue(value_);

  if (typeof value === 'number') {
    return { value };
  }

  if (typeof value === 'string' && /^(\d*[,.]?\d*)$/.test(value)) {
    return { value: Number.parseFloat(value) };
  }

  return;
};
