import { AtrigamDataType } from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import { AtrigamFlowExtensionConfigVersion } from '../../extension-config-base.types';
import { processPreviewMeasureTime } from '../helpers/processPreviewMeasureTime';
import { processValueMeasureTime } from '../helpers/processValueMeasureTime';
import { ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW } from '../measureTime.extensions.types';

import { AtrigamFlowExtensionMeasureTime_1_0 } from './measureTime.1.0.extensions.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AtrigamFlowExtensionConfigMeasureTime_1_0: AtrigamFlowExtensionConfigVersion<AtrigamFlowExtensionMeasureTime_1_0> =
  {
    extensionType: AtrigamFlowExtensionTypes.MeasureTime,
    version: '1.0',
    description: 'Measure time between two points in time.',
    example: 'time since started, expected delivery time',
    settings: {
      field: {
        __fieldType: 'defaultField',
        required: true,
      },
      description: {
        __fieldType: 'description',
        required: false,
      },
      fromField: {
        __fieldType: 'workItemSelectField',
        name: 'fromField',
        label: 'From',
        workItemDataTypes: [AtrigamDataType.Date, AtrigamDataType.DateTime],
        additionalValues: [
          {
            label: ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW,
            value: ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW,
          },
          {
            label: 'created at',
            value: 'createdAt',
          },
          {
            label: 'updated at',
            value: 'updatedAt',
          },
        ],
        required: true,
        processValue: processValueMeasureTime('fromField'),
      },
      toField: {
        __fieldType: 'workItemSelectField',
        name: 'toField',
        label: 'Until',
        workItemDataTypes: [AtrigamDataType.Date, AtrigamDataType.DateTime],
        additionalValues: [
          {
            label: ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW,
            value: ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW,
          },
          {
            label: 'created at',
            value: 'createdAt',
          },
          {
            label: 'updated at',
            value: 'updatedAt',
          },
        ],
        required: true,
        processValue: processValueMeasureTime('toField'),
      },
    },
    processPreview: processPreviewMeasureTime,
  };
