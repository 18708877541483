import { ArrowBack as ArrowBackIcon, Menu as MenuIcon } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import React from 'react';

import { reactive } from '../../../../../../helpers/reactive';
import { AtrigamTheme } from '../../../../../../typings/atrigamTheme';
import { NavigationContext } from '../../../../contexts/NavigationContext';

const NavigationToggleComponent: React.FC = () => {
  const theme = useTheme<AtrigamTheme>();
  const { isOpen, toggleNavigation } = React.useContext(NavigationContext);
  return (
    <Box position="fixed" top={0} left={0}>
      <IconButton
        onClick={toggleNavigation}
        size="large"
        sx={{ backgroundColor: `${theme.palette.background.default}D0` }}
      >
        {isOpen ? <ArrowBackIcon /> : <MenuIcon />}
      </IconButton>
    </Box>
  );
};

export const NavigationToggle = reactive(NavigationToggleComponent);
