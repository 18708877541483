import createRouter5 from 'router5';
import browserPlugin from 'router5-plugin-browser';

import { AnyRedirectRoute, AnyRoute, Router, RouterOptions } from '../Router.types';

import { createRouterMiddleware } from './createRouterMiddleware';

// router5 treats routing as state changes.
// We can listen to those state changes and emit and prevent state changes.
// It's a two way street. This core principle made it incredible easy
// for us to come up with patterns that were hardly possible with react-router
export const createRouter = <
  Routes extends Record<string, AnyRoute | AnyRedirectRoute>,
  RouteName extends Extract<keyof Routes, string>,
>(
  options: RouterOptions<Routes, RouteName>,
): Router => {
  const { routes, fallbackRoute, getTryNavigationWhenPausedCallback, onFinishRouting } = options;
  const routeList: (AnyRoute | AnyRedirectRoute)[] = Object.entries(routes).map(([name, route]) => {
    // trick to get easily named routes
    // set route name to be the object entry key
    route.setName(name);

    return route;
  });

  const router = createRouter5(routeList, {
    allowNotFound: true,
    defaultRoute: String(fallbackRoute),
    queryParamsMode: 'loose',
    queryParams: {
      arrayFormat: 'brackets',
      nullFormat: 'default',
      booleanFormat: 'none',
    },
    trailingSlashMode: 'never',
    strictTrailingSlash: true,
    caseSensitive: false,
    autoCleanUp: true,
  });

  // use browser plugin
  router.usePlugin(browserPlugin());

  // use middleware
  router.useMiddleware(
    createRouterMiddleware({
      routes,
      fallbackRoute,
      getTryNavigationWhenPausedCallback,
      onFinishRouting,
    }),
  );

  // DO NOT start router immediately!! We need to call it through the registry!
  // -- router.start();

  return router;
};
