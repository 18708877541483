import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  UID,
} from '@atrigam/atrigam-types';
import { getDocs } from 'firebase/firestore';

import { getUserSubscriptionWithWorkItemChangedListQueryReference } from '../queryReferences/getUserSubscriptionWithWorkItemChanged.list.query.reference';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  uid: UID;
  universe: AtrigamUniverseName;
}

export const countUserSubscriptionWithWorkItemChangedQuery = async (options: Options) => {
  const query_ = getUserSubscriptionWithWorkItemChangedListQueryReference(options);

  const collection_ = await getDocs(query_);
  return collection_.size;
};
