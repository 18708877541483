import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamWorkItem,
  AtrigamWorkItemId,
} from '@atrigam/atrigam-types';

import { createAtrigamWorkItemPath } from '../firestore/paths/createAtrigamWorkItemPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
}

export const watchWorkItem: WatcherFunction<AtrigamWorkItem, Options> = ({
  environment,
  node,
  objectName,
  onError,
  onUpdate,
  universe,
}) => {
  const firestoreService = watcherServiceEu.get();

  const path = createAtrigamWorkItemPath({ environment, node, objectName, universe });

  return firestoreService.subscribeToDocument<AtrigamWorkItem>({
    path,
    onSnapshot: (snapshot, key) => {
      const workItem = snapshot.data();
      void onUpdate(workItem, key);
    },
    onError,
  });
};
