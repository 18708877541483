import { AtrigamEnvironment, AtrigamUniverseAreaTaskFlow } from '@atrigam/atrigam-types';

import { RouteParameters, RouteQuery } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export interface Parameters extends RouteParameters {
  environment: AtrigamEnvironment;
  universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow;
}

export const pattern = '/insights/:environment/:universeAreaTaskFlow';

export const InsightsPath = createRoutePath<Parameters, RouteQuery>({ pattern });
