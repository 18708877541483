import { PlayArrowOutlined } from '@mui/icons-material';
import React from 'react';

import { reactive } from '../../../../../../helpers/reactive';
import { NavigationItem } from '../../../../../../primitive/NavigationItem/NavigationItem';
import { PlaygroundPath } from '../../../../../../routes/playground/playground.path';

const PlaygroundNavigationItemComponent: React.FC = () => {
  return (
    <NavigationItem
      to={PlaygroundPath.getLink()}
      activeRoutes={[PlaygroundPath]}
      icon={<PlayArrowOutlined color="info" />}
      label="Playground"
      testId="navigation-item-playground"
    />
  );
};

export const PlaygroundNavigationItem = reactive(PlaygroundNavigationItemComponent);
