import { AtrigamUserNotification, createAtrigamUniverseAreaTaskFlow } from '@atrigam/atrigam-types';
import React from 'react';

import { Registry } from '../../../../../../../../../../../services/Registry/Registry';

interface Options {
  notification: AtrigamUserNotification;
}

export const useLoadModels = ({ notification }: Options) => {
  const modelsStore = Registry.get('modelsStore');

  const universeAreaTaskFlow = React.useMemo(() => {
    if (!notification.universe || !notification.area || !notification.flow) {
      return;
    }

    return createAtrigamUniverseAreaTaskFlow({
      universe: notification.universe,
      area: notification.area,
      taskFlow: notification.flow,
    });
  }, [notification.area, notification.flow, notification.universe]);

  const taskFlowModel = React.useMemo(() => {
    if (!universeAreaTaskFlow) {
      return;
    }

    return modelsStore.getTaskFlowModel({
      environment: notification.environment,
      universeAreaTaskFlow,
    });
  }, [modelsStore, notification.environment, universeAreaTaskFlow]);

  const workItemModel = React.useMemo(() => {
    if (!universeAreaTaskFlow) {
      return;
    }

    return modelsStore.getWorkItemModel({
      environment: notification.environment,
      universeAreaTaskFlow,
    });
  }, [modelsStore, notification.environment, universeAreaTaskFlow]);

  return { taskFlowModel, workItemModel };
};
