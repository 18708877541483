import { AtrigamObjectSubscription } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { createAtrigamObjectSubscriptionPath } from '../paths/createAtrigamObjectSubscriptionPath';

type Options = Required<
  Pick<AtrigamObjectSubscription, 'id' | 'universe' | 'environment' | 'objectName'>
>;

export const updateObjectSubscriptionLastViewedMutation = async ({
  id,
  universe,
  environment,
  objectName,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamObjectSubscriptionPath({ environment, id, objectName, universe });
  const reference = doc(database, path);

  const snapshot = await getDoc(reference);
  if (!snapshot.exists) {
    // TODO throw error?
    return;
  }

  const updatedAt = getNowFirestoreTimestamp();

  // TODO: old implementation would not update updatedAt, just lastViewed. Not sure if it makes a difference
  const update: Partial<AtrigamObjectSubscription> = {
    lastViewed: updatedAt,
    updatedAt,
  };

  await updateDoc(reference, mapUndefinedToNullValue(update));
  return update;
};
