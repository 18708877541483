import React from 'react';

import { MAIN_ID, getMainElement } from '../../../../helpers/getMainElement';
import { reactive } from '../../../../helpers/reactive';
import { Registry } from '../../../../services/Registry/Registry';

import { MainWrapper } from './styles/MainWrapper.style';

const MainComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = Registry.get('router');

  // scroll to top when changing the url
  React.useEffect(() => {
    const $main = getMainElement();
    $main.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.currentURL]);

  return <MainWrapper id={MAIN_ID}>{children}</MainWrapper>;
};

export const Main = reactive(MainComponent);
