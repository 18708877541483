import { AtrigamConditionsSetOrSet } from '@atrigam/atrigam-types';
import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { isAtrigamConditionsSetOrEmptySet } from '../../helpers/isAtrigamConditionsSetOrEmptySet';

import { ConditionsSetOrAndSetEntity } from './ConditionsSetOrAndSet.entity';

export class ConditionsSetOrSetEntity {
  @persist('map', ConditionsSetOrAndSetEntity)
  @observable
  andSet = observable.map<string, ConditionsSetOrAndSetEntity>();

  constructor(data?: AtrigamConditionsSetOrSet) {
    makeObservable(this);

    if (data) {
      this.andSet.clear();
      Object.entries(data).forEach(([key, group]) => {
        if (isAtrigamConditionsSetOrEmptySet(group)) {
          return;
        }
        this.andSet.set(key, new ConditionsSetOrAndSetEntity(group));
      });
    }
  }

  @action
  toJS = () => {
    const andSet: AtrigamConditionsSetOrSet = {};

    this.andSet.forEach((value, key) => {
      andSet[key] = value.toJS();
    });

    return andSet;
  };
}
