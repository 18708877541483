import { VerifyEmailPageAsync } from '../../pages/VerifyEmail/VerifyEmail.page.async';
import { RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './verifyEmail.path';

export const VerifyEmailRoute = createRoute({
  title: () => translate('routes.verifyEmail.title'),
  components: {
    main: VerifyEmailPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
  onBeforeEnter: async () => {
    //
  },
});
