import {
  AtrigamChatMessage,
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
  ISODateTime,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  CollectionReference,
  DocumentData,
  collection,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamChatMessageListPath } from '../paths/createAtrigamChatMessageListPath';

interface Options {
  environment: AtrigamEnvironment;
  limit: number;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  startAfter?: ISODateTime;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getChatMessagesListQueryReference = ({
  environment,
  limit: limit_,
  node,
  objectName,
  startAfter: startAfter_,
  universe,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamChatMessageListPath({ environment, node, objectName, universe });
  const reference = collection(database, path) as CollectionReference<
    AtrigamChatMessage,
    DocumentData
  >;

  let query_ = query(reference, orderBy('createdAt', 'desc'), limit(limit_));
  if (startAfter_) {
    query_ = query(query_, startAfter(startAfter_));
  }

  return query_;
};
