import {
  AtrigamDataType,
  type AtrigamObjectName,
  type FirestoreTimestamp,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import { action, makeObservable, observable } from 'mobx';

import { WorkItemModelFieldEntity } from '../../../../../stores/ModelsStore/entities/WorkItemModelField.entity';
import { RecordDetailRoleInteractionEntity } from '../../../../../stores/ModelsStore/entities/taskFlow/recordDetail/RecordDetailRoleInteraction.entity';

interface Options {
  recordDetailInteraction: RecordDetailRoleInteractionEntity;
  workItemModelField: WorkItemModelFieldEntity;
  isNotifyEnabled?: boolean;
  fieldNotUpdatedNotification?: FirestoreTimestamp;
  hasFieldChanged: boolean;
}

export class UniverseRecordDetailEntity {
  @observable
  fieldName: string; // field name

  @observable
  label: string;

  @observable
  object: AtrigamObjectName;

  @observable
  sequence: number;

  @observable
  type: AtrigamDataType;

  @observable
  isLast = false;

  @observable
  isNotifyEnabled = false;

  @observable
  fieldNotUpdatedNotification?: FirestoreTimestamp;

  @observable
  hasFieldChanged = false;

  constructor({
    recordDetailInteraction,
    workItemModelField,
    isNotifyEnabled = false,
    fieldNotUpdatedNotification,
    hasFieldChanged,
  }: Options) {
    makeObservable(this);

    throwIfNullable('fieldName cannot be undefined', recordDetailInteraction.fieldName);
    throwIfNullable('label cannot be undefined', recordDetailInteraction.label);
    throwIfNullable('object cannot be undefined', recordDetailInteraction.object);
    throwIfNullable('type cannot be undefined', workItemModelField.type);

    this.fieldName = recordDetailInteraction.fieldName;
    this.label = recordDetailInteraction.label;
    this.object = recordDetailInteraction.object;
    this.sequence = recordDetailInteraction.sequence ?? 0;

    this.type = workItemModelField.type;
    this.isNotifyEnabled = isNotifyEnabled;
    this.fieldNotUpdatedNotification = fieldNotUpdatedNotification;
    this.hasFieldChanged = hasFieldChanged;
  }

  @action
  setIsLastItem = () => {
    this.isLast = true;
  };
}
