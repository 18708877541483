import { AggregationMethod } from '../aggregatedValue.1.0.processor.types';

interface Options {
  pattern: string;
}

export const getAggregationMethod = ({ pattern }: Options) => {
  if (!pattern) return;

  if (pattern.includes('SUM')) {
    return AggregationMethod.Sum;
  }

  if (pattern.includes('AVG')) {
    return AggregationMethod.Mean;
  }

  if (pattern.includes('MIN')) {
    return AggregationMethod.Min;
  }

  if (pattern.includes('MAX')) {
    return AggregationMethod.Max;
  }

  if (pattern.includes('COUNT')) {
    return AggregationMethod.Count;
  }

  return AggregationMethod.Evaluate;
};
