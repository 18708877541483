import { WorkItemModelEntity } from '../../../stores/ModelsStore/entities/WorkItemModel.entity';

interface Options {
  field: string;
  model?: WorkItemModelEntity;
}

export const getFieldLabel = ({ field, model }: Options) => {
  if (field === 'createdAt') {
    return 'created at';
  }

  if (field === 'updatedAt') {
    return 'updated at';
  }

  if (!model) {
    return field;
  }

  const f = model.fields.get(field);
  if (!f?.label) {
    return field;
  }

  return f.label;
};
