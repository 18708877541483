import { CurrencySymbol } from '../common.types';
import { findCurrencySymbolInString } from '../helpers/findCurrencySymbolInString';

export const isCurrencySymbol = (value: string | CurrencySymbol): value is CurrencySymbol => {
  const currencySymbol = findCurrencySymbolInString(value);
  if (!currencySymbol) {
    return false;
  }

  return value.length === currencySymbol.length;
};
