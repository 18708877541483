import { URI } from '@atrigam/atrigam-types';
import { getUniverseDataDownloadURL } from '@atrigam/server-functions-eu-client';
import React from 'react';

interface Options {
  location?: string;
}

export const useUniverseDataStorageUrl = ({ location }: Options) => {
  const [imageUri, setImageUri] = React.useState<URI>();

  React.useEffect(() => {
    if (!location) {
      return;
    }

    void getUniverseDataDownloadURL(location)
      .then((uri) => {
        setImageUri(uri);
      })
      .catch(() => {
        // ignore errors
      });
  }, [location]);

  return { imageUri };
};
