import { Mode } from '../../pages/Login/Login.types';
import { RouteParameters, RouteQuery } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export const pattern = '/login';

export interface Query extends RouteQuery {
  tab?: Mode;
  origin?: URL;
}

export const LoginPath = createRoutePath<RouteParameters, Query>({ pattern });
