import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../themes/Themes.types';

interface Properties extends ThemedStyledComponent {
  $fullWidth: boolean;
}

export const InnerLayoutWrapper = styled.div<Properties>(
  ({ $fullWidth: fullWidth, theme }: Properties) => css`
    ${theme.breakpoints.up('sm')} {
      margin: auto;
      padding: ${fullWidth ? `${theme.spacing(20)}` : theme.spacing(1)};
      width: ${fullWidth ? '100%' : '416px'};
    }

    ${theme.breakpoints.down('sm')} {
      & > .MuiPaper-root {
        box-shadow: none;
        display: flex;
        flex-direction: column;
      }
    }
  `,
);
