import { AtrigamDataType } from '../../atrigam-datatypes';
import { ParseDataTypeFunction } from '../../parseDataType.types';

export const parseDataTypeBoolean: ParseDataTypeFunction<AtrigamDataType.Boolean> = ({ value }) => {
  if (typeof value === 'boolean') {
    return { value };
  }

  if (typeof value === 'string') {
    if (value === 'true') {
      return { value: true };
    }

    if (value === 'false') {
      return { value: false };
    }
  }

  return;
};
