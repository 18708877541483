import { impersonateUser } from '../../helpers/impersonateUser';
import { Registry } from '../../services/Registry/Registry';
import { RouteScope } from '../../services/Router/Router.types';
import { createRedirect } from '../../services/Router/helpers/createRedirect';
import { createRedirectRoute } from '../../services/Router/helpers/createRedirectRoute';

import { Parameters, pattern } from './impersonate.path';

export const ImpersonateRoute = createRedirectRoute<Parameters>({
  pattern,
  scope: RouteScope.Internal,
  onRedirect: async ({ parameters: { uid } }) => {
    const userStore = Registry.get('userStore');
    const redirectToHome = createRedirect<undefined, undefined>({
      name: 'HomeRoute',
      params: undefined,
    });

    // already impersonating
    if (userStore.isImpersonating) {
      Registry.get('snackbar').addNotification({
        message: 'Already impersonating a user',
        options: {
          variant: 'error',
        },
      });

      return redirectToHome;
    }

    // impersonate
    await impersonateUser({ uid });

    return redirectToHome;
  },
});
