import {
  AtrigamEnvironment,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamWorkItemId,
  UID,
} from '@atrigam/atrigam-types';
import { limit, query, where } from 'firebase/firestore';

import { getObjectSubscriptionListQueryReference } from './getObjectSubscription.list.query.reference';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  node: AtrigamWorkItemId;
  uid: UID;
}

export const getObjectSubscriptionForUserQueryReference = ({
  universe,
  environment,
  objectName,
  node,
  uid,
}: Options) => {
  const query_ = getObjectSubscriptionListQueryReference({
    universe,
    environment,
    objectName,
    node,
  });
  return query(query_, where('uid', '==', uid), limit(1));
};
