/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
// defaults
export interface DefaultHeaders {
  // overridable for client tests
  XAppEnvironment?: string;
  // sentry
  XTransactionID?: string;
}

// http functions

export interface StatusNotOkResponse {
  status: number;
  statusText: string;
  text: string | undefined;
  json: unknown | undefined;
}

export type ServerFunctionHttpOptions<P = Record<string, unknown>> = P &
  DefaultHeaders & {
    onException?: (error: unknown) => void;
    onStatusNotOk?: (context: {
      payload: P;
      headers: Record<string, string | undefined>;
      response?: StatusNotOkResponse;
    }) => void;
    headers?: Record<string, string>;
    credentials?: RequestCredentials;
  };

// NOOP

export const NOOP = () => {
  /** Nothing happens */
};

// onCall functions

export type ServerFunctionOnCallOptions<P = Record<string, unknown>, E = unknown> = P &
  DefaultHeaders & {
    onException?: (error: E | unknown) => void;
  };
