import { UnhandledCaseError } from '../../../../../utilities/UnhandledCaseError';

const DecimalSource = '(?:\\%DECIMAL_SEPARATOR%\\d*)?';

const NumberSourceStrict =
  '^(-?\\d{1,3}(?:\\%NUMBER_SEPARATOR%\\d{3})*\\d*(?:\\%DECIMAL_SEPARATOR%\\d+)?)$';
const NumberSourceLax = `^(-?(?:\\d{0,3}|\\d{1,3}(?:\\d*${DecimalSource}|\\%NUMBER_SEPARATOR%\\d{0,3}|\\%NUMBER_SEPARATOR%\\d{3}\\%NUMBER_SEPARATOR%|(?:\\%NUMBER_SEPARATOR%\\d{3})+${DecimalSource}|(?:\\%NUMBER_SEPARATOR%\\d{3})*\\%NUMBER_SEPARATOR%\\d{0,3})))$`;

export const NUMBER_SEPARATOR = '.';
export const DECIMAL_SEPARATOR = ',';

export enum NumberSeparator {
  DE = 'de',
  EN = 'en',
}

interface Options {
  strict: boolean;
  numberSeparator: NumberSeparator;
}

export const getMoneyNumberRegex = ({ strict, numberSeparator }: Options) => {
  const source = strict ? NumberSourceStrict : NumberSourceLax;

  const regexSource = source
    .replace(/%NUMBER_SEPARATOR%/g, getNumberSeparator(numberSeparator))
    .replace(/%DECIMAL_SEPARATOR%/g, getDecimalSeparator(numberSeparator));

  return new RegExp(regexSource);
};

const getNumberSeparator = (numberSeparator: NumberSeparator) => {
  switch (numberSeparator) {
    case NumberSeparator.DE: {
      return NUMBER_SEPARATOR;
    }

    case NumberSeparator.EN: {
      return DECIMAL_SEPARATOR;
    }

    default: {
      throw new UnhandledCaseError(numberSeparator);
    }
  }
};

const getDecimalSeparator = (numberSeparator: NumberSeparator) => {
  switch (numberSeparator) {
    case NumberSeparator.DE: {
      return DECIMAL_SEPARATOR;
    }

    case NumberSeparator.EN: {
      return NUMBER_SEPARATOR;
    }

    default: {
      throw new UnhandledCaseError(numberSeparator);
    }
  }
};
