import {
  AtrigamFlowExtension,
  createAtrigamExtensionsRunnerForClients,
} from '@atrigam/atrigam-extensions';
import {
  AtrigamEnvironment,
  AtrigamFirestoreUniverseKpi,
  AtrigamModelsModelFlowExtensions,
  AtrigamObjectName,
  AtrigamUniverseName,
  AtrigamWorkItem,
} from '@atrigam/atrigam-types';
import {
  getExtensionSettingsQuery,
  getWorkItemQuery,
  updateExtensionSettingsMutation,
} from '@atrigam/server-functions-eu-client';

import { IS_DEV } from '../../../../../mode';
import { Registry } from '../../../../../services/Registry/Registry';
import { sentry } from '../../../../../services/Sentry/helpers/initializeSentry';

interface Options {
  beforeWorkItem?: Omit<AtrigamWorkItem, 'id'>;
  environment: AtrigamEnvironment;
  extensions: AtrigamModelsModelFlowExtensions;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
  universeKpisMap: Record<AtrigamFirestoreUniverseKpi['id'], AtrigamFirestoreUniverseKpi['result']>;
  workItem: AtrigamWorkItem;
}

export const processExtensions = async ({
  beforeWorkItem,
  environment,
  extensions,
  objectName,
  universe,
  universeKpisMap,
  workItem,
}: Options) => {
  const { dateFormat, dateTimeFormat, language } = Registry.get('translations');
  const { getFxRates } = Registry.get('exchangeRate');

  const extensionsRunner = createAtrigamExtensionsRunnerForClients({
    extensionProcessor: { unsupportedExtension },
    //
    extensions: {
      aggregatedValue: { getWorkItemQuery },
      autoId: {
        getExtensionSettingsQuery,
        updateExtensionSettingsMutation,
      },
      calculatedValue2: {
        dateFormat,
        dateTimeFormat,
        getFxRates,
        language,
      },
      setValue: {
        getFxRates,
      },
    },
  });

  const changes = await extensionsRunner.processExtensions({
    beforeWorkItem,
    environment,
    extensions,
    objectName,
    universe,
    universeKpisMap,
    workItem,
  });

  return changes;
};

const unsupportedExtension = (extension: AtrigamFlowExtension) => {
  if (IS_DEV) {
    throw new Error(String(extension));
  }

  sentry.log({
    error: new Error(`Extension ${extension.ext} version ${extension.version} is not supported!`),
  });
  return [];
};
