import { AtrigamDataType } from '../../atrigam-datatypes';
import { DATATYPE_FORMAT_NOT_AVAILABLE } from '../../dataTypes.constants';
import { FormatDataTypeFunction } from '../../formatDataType.types';
import { createTranslate } from '../../helpers/createTranslate';
import { formatDataTypeFixedDuration } from '../FixedDuration/formatDataType.fixedDuration';

export const formatDataTypeOpenDuration: FormatDataTypeFunction<AtrigamDataType.OpenDuration> = ({
  value,
  options: { language = 'en' },
}) => {
  const formatted = formatDataTypeFixedDuration({
    value,
    dataType: AtrigamDataType.FixedDuration,
    options: { language },
  });
  if (formatted === DATATYPE_FORMAT_NOT_AVAILABLE) {
    return DATATYPE_FORMAT_NOT_AVAILABLE;
  }

  return translate({ language, key: 'counting' }).replace('{duration}', formatted);
};

const translations = {
  de: {
    counting: '{duration} und weiter zählend',
  },
  en: {
    counting: '{duration} and counting',
  },
};

const translate = createTranslate(translations);
