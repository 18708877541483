import {
  AtrigamModelsModelObjectsObject,
  AtrigamModelsModelObjectsObjectData,
  AtrigamModelsModelObjectsObjectKpi,
  type AtrigamObjectName,
  type ISODateTime,
} from '@atrigam/atrigam-types';
import { computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { WorkItemModelFieldEntity } from './WorkItemModelField.entity';
import { WorkItemModelKpiEntity } from './WorkItemModelKpi.entity';

export class WorkItemModelEntity {
  @persist('map', WorkItemModelFieldEntity)
  @observable
  fields = observable.map<string, WorkItemModelFieldEntity>();

  @persist('map', WorkItemModelKpiEntity)
  @observable
  kpis = observable.map<string, WorkItemModelKpiEntity>();

  @persist
  @observable
  name?: AtrigamObjectName;

  @persist
  @observable
  path?: string;

  @persist
  @observable
  updatedAt?: ISODateTime;

  constructor(data?: AtrigamModelsModelObjectsObject) {
    makeObservable(this);

    if (data) {
      this.fields.clear();
      if (data.data) {
        Object.values(data.data).forEach((field) => {
          this.fields.set(field.name, new WorkItemModelFieldEntity(field));
        });
      }

      this.kpis.clear();
      if (data.kpi) {
        Object.values(data.kpi).forEach((kpi) =>
          this.kpis.set(kpi.name, new WorkItemModelKpiEntity(kpi)),
        );
      }

      this.name = data.name;
      this.path = data.path;
      this.updatedAt = data.updatedAt;
    }
  }

  @computed
  get asObjectModel() {
    if (!this.name || !this.path) {
      return;
    }

    const data: AtrigamModelsModelObjectsObjectData = {};
    this.fields.forEach((value, key) => {
      if (!value.asDataItem) {
        return;
      }
      data[key] = value.asDataItem;
    });

    const kpi: AtrigamModelsModelObjectsObjectKpi = {};
    this.kpis.forEach((value, key) => {
      if (!value.asKpiModel) {
        return;
      }

      kpi[key] = value.asKpiModel;
    });

    const objectModel: AtrigamModelsModelObjectsObject = {
      name: this.name,
      path: this.path,
      data,
      kpi,
      updatedAt: this.updatedAt,
    };

    return JSON.parse(JSON.stringify(objectModel)) as AtrigamModelsModelObjectsObject;
  }
}
