import {
  AtrigamUniverseName,
  AtrigamUniverseRegistrationClientRole,
  UID,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import {
  CollectionReference,
  DocumentData,
  collection,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamUniverseRegistrationClientRoleListPath } from '../paths/createAtrigamUniverseRegistrationClientRoleListPath';

interface Options {
  uid: UID;
  universe: AtrigamUniverseName;
}

export const getAllUniverseRegistrationClientRolesForUserListQueryReference = ({
  universe,
  uid,
}: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUniverseRegistrationClientRoleListPath({ universe });
  const reference = collection(database, path) as CollectionReference<
    AtrigamUniverseRegistrationClientRole,
    DocumentData
  >;

  return query(reference, where('universe', '==', universe), where('uid', '==', uid));
};
