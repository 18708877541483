import { reactive } from '../../helpers/reactive';
import { Registry } from '../../services/Registry/Registry';
import { toggleLanguage } from '../../services/Translation/helpers/toggleLanguage';
import { TertiaryButton } from '../Button/TertiaryButton';

const LanguageSwitcherComponent: React.FC = () => {
  const { language } = Registry.get('translations');

  return (
    <TertiaryButton onClick={toggleLanguage} aria-label="switch-language" size="small">
      {language}
    </TertiaryButton>
  );
};

export const LanguageSwitcher = reactive(LanguageSwitcherComponent);
