import { List } from '@mui/material';

import { reactive } from '../../../../../../../../../../helpers/reactive';
import { Registry } from '../../../../../../../../../../services/Registry/Registry';
import { UserNotificationsListItem } from '../UserNotificationsListItem/UserNotificationsListItem';

import { ListWrapper } from './styles/ListWrapper.style';

interface Properties {
  onClose: () => void;
}

const UserNotificationsListComponent: React.FC<Properties> = ({ onClose }) => {
  const { notifications } = Registry.get('userNotificationsStore');
  const last = notifications.length - 1;

  return (
    <ListWrapper>
      <List dense={true} data-testid="user-notifications-list">
        {notifications.map((notification, index) => (
          <UserNotificationsListItem
            key={notification.id}
            notification={notification}
            isLastItem={last === index}
            onClose={onClose}
          />
        ))}
      </List>
    </ListWrapper>
  );
};

export const UserNotificationsList = reactive(UserNotificationsListComponent);
