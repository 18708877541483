import {
  ChangeSet,
  getNowDateTime,
  AnyDate,
  getDateTime,
  isDateTime,
} from '@atrigam/atrigam-types';

import { AtrigamFlowExtensionTypes } from '../../../atrigam-extensions.types';
import {
  AtrigamFlowExtensionProcessor,
  AtrigamFlowExtensionProcessorType,
  ExtensionProcessFunction,
} from '../../../extensionsRunner/atrigam-processor.types';
import { ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW } from '../measureTime.extensions.types';

import { AtrigamFlowExtensionMeasureTime_1_0 } from './measureTime.1.0.extensions.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export class MeasureTime_1_0_ClientProcessor
  implements AtrigamFlowExtensionProcessor<AtrigamFlowExtensionMeasureTime_1_0>
{
  type = AtrigamFlowExtensionTypes.MeasureTime;
  version = '1.0';
  processorType = AtrigamFlowExtensionProcessorType.ClientOnly;

  supportsVersion: (version: string) => boolean = (version) => version === this.version;

  process: ExtensionProcessFunction<AtrigamFlowExtensionMeasureTime_1_0> = ({
    extension,
    workItem,
  }) => {
    if (!extension.config?.fromField || !extension.config?.toField) {
      return [];
    }

    const config = extension.config;
    const changes: ChangeSet[] = [];

    // calculate diff
    const nowDateTime = getNowDateTime();

    // handle 'NOW' in fromField
    const fromValue =
      config.fromField === ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW
        ? nowDateTime
        : config.fromField === null
        ? undefined
        : (workItem[config.fromField] as AnyDate | undefined);

    // handle 'NOW' in toField
    const toValue =
      config.toField === ATRIGAM_FLOWEXTENSION_MEASURETIME_CONFIG_NOW
        ? nowDateTime
        : config.toField === null
        ? undefined
        : (workItem[config.toField] as AnyDate | undefined);

    let calculated: number | null = null;

    // calculate if we have values
    if (fromValue && toValue) {
      const fromDate = getDateTime(fromValue);
      const toDate = getDateTime(toValue);

      if (isDateTime(fromDate) && isDateTime(toDate)) {
        const duration = toDate.diff(fromDate);
        if (duration.isValid) {
          calculated = duration.as('milliseconds');
        }
      }
    }

    // create changeSet
    const changeSet = {
      fieldName: extension.config.field,
      fieldValue: calculated,
    };

    if (workItem[changeSet.fieldName] !== changeSet.fieldValue) {
      // update workItem directly so other extensions can handle it
      workItem[changeSet.fieldName] = changeSet.fieldValue;
      // add change set for update mutation
      changes.push(changeSet);
    }

    return changes;
  };
}
