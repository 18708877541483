// @see atrigam-app/RecordDetail:renderItem
export enum AtrigamDataType {
  Array = 'array',
  Boolean = 'bool',
  Date = 'date',
  DateTime = 'datetime',
  Email = 'email',
  FileList = 'filelist',
  FixedDuration = 'fixedDuration',
  Image = 'image',
  ImageList = 'imagelist',
  Integer = 'integer',
  Location = 'location',
  Money = 'money',
  Number = 'number',
  Object = 'object',
  OpenDuration = 'openDuration',
  Phone = 'phone',
  Signature = 'signature',
  String = 'string',
  Text = 'text', // found in AndreasUniverse:ProjectManagement:CostEstimate
}
